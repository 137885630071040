import { Radio, Space } from 'antd';
import React from 'react';
import { isRequiredField } from '../../../../app/job-creation/job-creation.util';
import './style.scss';

export const DeveloperResponsibility = ({
  owns,
  showError,
  handleDeveloperOwnsChange,
}) => {
  return (
    <div className="developer-responsibility">
      <div className="selection-container">
        <div className="label">
          What will this developer be owning on a daily basis?
        </div>
        <Radio.Group
          onChange={ev => handleDeveloperOwnsChange(ev.target.value)}
          value={owns}
        >
          <Space direction="vertical">
            <Radio value="tasks">Tasks (Mid-level engineer)</Radio>
            <Radio value="features">Features (Senior engineer)</Radio>
            <Radio value="projects">Projects (Lead engineer)</Radio>
          </Space>
        </Radio.Group>
        {showError && !isRequiredField(owns) && (
          <div className="error-label">Please select an option</div>
        )}
      </div>
    </div>
  );
};
