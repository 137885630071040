import { TuringClientLogger } from '@turingenterprises/turing-client';
import Environment from '../Enviroment';

export const turingLogger = new TuringClientLogger({
  baseApiUrl: Environment.loggingServerURL,
  logName: 'SELF-SERVE',
});

let botInfo;
export const isBot = async () => {
  if (typeof botInfo !== 'undefined') return botInfo;
  try {
    const result = await turingLogger.isBot();
    botInfo = result;
  } catch (e) {
    console.error(e);
  }
  return botInfo || {};
};
