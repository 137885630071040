import { connect } from 'react-redux';
import { getContinents } from '../../../../app/continents/continents.actions';
import {
  syncLocationValue,
  syncStrictLocation,
} from '../../../../app/job-creation/job-creation.actions';
import { LocationAndStartDate } from './location-start-date';

const mapStateToProps = state => {
  return {
    isStrictLocation: state.jobCreation.location.isStrict,
    location: state.jobCreation.location.value,
    continents: state.continents.continents,
    showError: state.jobCreation.showValidationErrors,
    jobCreationId: state.jobCreation.id,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleLocationChange: location => {
      dispatch(syncLocationValue(location));
    },
    handleLocationToggle: isStrict => {
      dispatch(syncStrictLocation(isStrict));
    },
    getContinents: () => {
      dispatch(getContinents());
    },
  };
};

const connected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LocationAndStartDate);
export { connected as LocationAndStartDateContainer };
