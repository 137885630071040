export const Environment = {
  basename: '',
  remoteOrigin: process.env.REACT_APP_ORIGIN,
  localOrigin: process.env.REACT_APP_REMOTE_ORIGIN,
  environment: process.env.REACT_APP_ENVIRONMENT,
  version: +process.env.REACT_APP_MATCHING_VERSION || 0,
  matchingOrigin: process.env.REACT_APP_MATCHING_ORIGIN,
  sentryDSN: process.env.REACT_APP_SENTRY_DSN,
  googleClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  googleClientSecret: process.env.REACT_APP_GOOGLE_CLIENT_SECRET,
  calendarOrigin: process.env.REACT_APP_CALENDAR_ORIGIN,
  growthbookApiKey: process.env.REACT_APP_GROWTHBOOK_API_KEY,
  growthbookProjectId: process.env.REACT_APP_GROWTHBOOK_PROJECT_ID,
  growthbookEnvironment: process.env.REACT_APP_GROWTHBOOK_ENV,
  growthbookEnabled: process.env.REACT_APP_ENABLE_GROWTHBOOK === 'true',
  copyrightYear: process.env.REACT_APP_COPYRIGHT_YEAR,
  growthbookURL:
    process.env.REACT_APP_GROWTHBOOK_URL ||
    'https://experimentation-dev-api.turing.com',
  loggingServerURL:
    process.env.REACT_APP_LOGGING_SERVER_URL ||
    'https://logging-server-dev.turing.com',
  developerServiceOrigin: process.env.REACT_APP_DEVELOPER_SERVICE_ORIGIN,
  customerFlowOrigin: process.env.REACT_APP_CUSTOMER_FLOW_ORIGIN,
  appVersion: process.env.REACT_APP_VERSION,
  feOrigin: new URL(
    process.env.REACT_APP_ORIGIN ?? 'https://self-serve.turing.com',
  ).origin,
  calendlyUsername: process.env.REACT_APP_CALENDLY_USERNAME ?? 'turing-staging',
  calendlyEventName:
    process.env.REACT_APP_CALENDLY_EVENTTYPE_FOR_LIMITED_SS_ACCESS ??
    'staging-self-serve-lsa',
};

export default Environment;
