import produce from 'immer';
import {
  ActionType,
  DeveloperProfile,
  RequestType,
} from './developer-profile.types';

const initialState = {
  developerProfiles: {
    '': new DeveloperProfile(),
  },
  profileToOpen: null,
  currentDeveloperHash: null,
  logoLocation: null,
  isFetching: {
    [RequestType.AVAILABILITY]: false,
    [RequestType.SIDE_PROJECTS]: false,
    [RequestType.WORK_HISTORY]: false,
    [RequestType.EDUCATION]: false,
    [RequestType.PUBLICATIONS]: false,
    [RequestType.CERTIFICATIONS]: false,
    [RequestType.SKILLS]: false,
    [RequestType.HEADER]: false,
    [RequestType.ENGAGEMENTS]: false,
    [RequestType.SENIORITY]: false,
    [RequestType.INTERVIEW]: false,
    [RequestType.LIVE_CODING_VIDEO]: false,
    [RequestType.YEARS_OF_EXPERIENCE]: false,
  },
  cancelDDPOpenRequest: false,
};

export const developerProfileReducer = (state = initialState, action) => {
  return produce(state, draftState => {
    if (action.type === ActionType.SET_CURRENT_DEVELOPER_HASH) {
      draftState.currentDeveloperHash = action.payload;
    }
    if (action.type === ActionType.PROFILE_ACTION_REQUEST) {
      draftState.isFetching[action.payload] = true;
    }
    if (action.type === ActionType.PROFILE_ACTION_COMPLETED) {
      draftState.isFetching[action.payload] = false;
    }
    if (action.type === ActionType.GET_PROFILE_HEADER) {
      const { header, developerIdHash } = action.payload;
      draftState.developerProfiles[developerIdHash] = {
        ...draftState.developerProfiles[developerIdHash],
        header,
      };
    }
    if (action.type === ActionType.GET_PROFILE_HEADER_V2) {
      const { header, developerIdHash } = action.payload;
      draftState.developerProfiles[developerIdHash] = {
        ...draftState.developerProfiles[developerIdHash],
        header_v2: header,
      };
    }
    if (action.type === ActionType.GET_PROFILE_SIDE_PROJECTS) {
      const { sideProjects, developerIdHash } = action.payload;
      draftState.developerProfiles[developerIdHash] = {
        ...draftState.developerProfiles[developerIdHash],
        sideProjects,
      };
    }
    if (action.type === ActionType.GET_PROFILE_WORK_HISTORY) {
      const { workHistory, developerIdHash } = action.payload;
      draftState.developerProfiles[developerIdHash] = {
        ...draftState.developerProfiles[developerIdHash],
        workHistory,
      };
    }

    if (action.type === ActionType.GET_PROFILE_AVAILABILITY) {
      const { developerUserIdHash, availability } = action.payload;
      draftState.developerProfiles[developerUserIdHash] = {
        ...draftState.developerProfiles[developerUserIdHash],
        availability,
      };
    }

    if (action.type === ActionType.GET_PROFILE_EDUCATION) {
      const { developerUserIdHash, education } = action.payload;
      draftState.developerProfiles[developerUserIdHash] = {
        ...draftState.developerProfiles[developerUserIdHash],
        education,
      };
    }

    if (action.type === ActionType.GET_PROFILE_PUBLICATIONS) {
      const { developerUserIdHash, publications } = action.payload;
      draftState.developerProfiles[developerUserIdHash] = {
        ...draftState.developerProfiles[developerUserIdHash],
        publications,
      };
    }

    if (action.type === ActionType.GET_PROFILE_CERTIFICATIONS) {
      const { developerUserIdHash, certifications } = action.payload;
      draftState.developerProfiles[developerUserIdHash] = {
        ...draftState.developerProfiles[developerUserIdHash],
        certifications,
      };
    }

    if (action.type === ActionType.GET_PROFILE_SKILLS) {
      const { developerUserIdHash, skills } = action.payload;
      //TODO: `developerProfiles` is for developer profile(s) in modal & not preview card, currently its not persisted & vanishes on refresh as we persist main store only
      draftState.developerProfiles[developerUserIdHash] = {
        ...draftState.developerProfiles[developerUserIdHash],
        skills,
      };
    }

    if (action.type === ActionType.GET_PROFILE_LOGO) {
      draftState.logoLocation = action.payload;
    }

    if (action.type === ActionType.GET_PROFILE_ENGAGEMENTS) {
      const { developerUserIdHash, experienceOnTuring } = action.payload;
      draftState.developerProfiles[developerUserIdHash] = {
        ...draftState.developerProfiles[developerUserIdHash],
        experienceOnTuring,
      };
    }
    if (action.type === ActionType.GET_SELF_SERVE_PROFILE_DETAILS) {
      const { developerUserIdHash, selfServeDeveloperDetails } = action.payload;
      draftState.developerProfiles[developerUserIdHash] = {
        ...draftState.developerProfiles[developerUserIdHash],
        selfServeDeveloperDetails,
      };
    }
    if (action.type === ActionType.GET_SELF_SERVE_OPEN_DEVELOPER_PROFILE) {
      if (!draftState.cancelDDPOpenRequest) {
        const { profileToOpen } = action.payload;
        draftState.profileToOpen = profileToOpen;
      }
    }
    if (action.type === ActionType.CLEAR_SELF_SERVE_OPEN_DEVELOPER_PROFILE) {
      draftState.profileToOpen = null;
    }
    if (action.type === ActionType.GET_PROFILE_SENIORITY) {
      const { developerUserIdHash, workExperience } = action.payload;
      draftState.developerProfiles[developerUserIdHash] = {
        ...draftState.developerProfiles[developerUserIdHash],
        workExperience,
      };
    }
    if (action.type === ActionType.GET_PROFILE_INTERVIEW) {
      const { developerUserIdHash, interviewDetail } = action.payload;
      draftState.developerProfiles[developerUserIdHash] = {
        ...draftState.developerProfiles[developerUserIdHash],
        interviewDetail,
      };
    }
    if (action.type === ActionType.GET_LIVE_CODING_INTERVIEW) {
      const { developerUserIdHash, liveCodingVideoDetail } = action.payload;
      draftState.developerProfiles[developerUserIdHash] = {
        ...draftState.developerProfiles[developerUserIdHash],
        liveCodingVideoDetail,
      };
    }

    if (action.type === ActionType.GET_YEARS_OF_EXPERIENCE) {
      const { developerUserIdHash, yearsOfExperience } = action.payload;
      draftState.developerProfiles[developerUserIdHash] = {
        ...draftState.developerProfiles[developerUserIdHash],
        yearsOfExperience,
      };
    }

    if (action.type === ActionType.CANCEL_DDP_OPEN_REQUEST) {
      draftState.cancelDDPOpenRequest = true;
    }

    if (action.type === ActionType.ENABLE_DDP_OPEN_REQUEST) {
      draftState.cancelDDPOpenRequest = false;
    }

    if (action.type === ActionType.GET_PROFILE_CLOUD_CERTIFICATIONS) {
      const { developerUserIdHash, cloudCertifications } = action.payload;
      draftState.developerProfiles[developerUserIdHash] = {
        ...draftState.developerProfiles[developerUserIdHash],
        cloudCertifications,
      };
    }

    if (action.type === ActionType.GET_PROFILE_TURING_ENGAGEMENTS) {
      const { developerUserIdHash, turingEngagements } = action.payload;
      draftState.developerProfiles[developerUserIdHash] = {
        ...draftState.developerProfiles[developerUserIdHash],
        turingEngagements,
      };
    }
  });
};
