const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

const locateService = (
  name,
  { fallback, interval = 50, timeout = 100 * interval },
) => {
  let result = null;
  const fallbackTimeout = async () => {
    await delay(timeout);

    if (!result) {
      result = fallback;
    }

    return result;
  };

  const monitorService = async () => {
    while (!result) {
      const service = window[name];
      if (service) {
        result = service;
      } else {
        await delay(interval);
      }
    }

    return result;
  };

  return Promise.race([fallbackTimeout(), monitorService()]);
};

export { locateService };
