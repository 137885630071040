import { action } from 'typesafe-actions';
import { RequestService, ToastService } from '../../services';
import { getJobList } from '../job-selection/job-selection.actions';
import { logActivity } from '../user-activity-trail/user-activity-trail.actions';
import { ActivityTypes } from '../user-activity-trail/user-activity-trail.types';
import { ActionType } from './job-creation.types';
import { ActionType as MainTypes } from '../main/main.types';
import {
  getAdditionalPreferencePayload,
  getJobCreationPayload,
  isNewJobRatesEnabled,
  getJobFlowV2Payload,
} from './job-creation.util';
import { history } from '../../store/history';
import {
  switchCurrentJob,
  setNewJob,
} from '../job-selection/job-selection.actions';
import { WORKING_HOURS_PER_MONTH } from '../../shared/constants';

export const setJobCreationMode = (
  source,
  isLimitedAccount = false,
  enableJobFlowV2 = false,
) => {
  return async dispatch => {
    dispatch(
      action(ActionType.ENABLE_JOB_CREATION_MODAL, {
        source,
        isLimitedAccount,
        enableJobFlowV2,
      }),
    );

    const analyticsPayload = {
      source,
      startedAt: Date.now(),
    };
    dispatch(
      logActivity(
        ActivityTypes.JOB.KEY,
        ActivityTypes.JOB.ACTIONS.POST_JOB_STARTED,
        analyticsPayload,
      ),
    );
  };
};

export const unSetJobCreationMode = (force = false) => {
  return async (dispatch, getState) => {
    const { jobCreation } = getState();
    const { currentPage, jobId } = jobCreation;
    dispatch(redirectToJobSearch(currentPage, jobId, force));
    dispatch(action(ActionType.DISABLE_JOB_CREATION_MODAL));
  };
};

export const navigateNext = (currentPage, jobConfig) => {
  return async dispatch => {
    dispatch(
      logActivity(
        ActivityTypes.JOB.KEY,
        ActivityTypes.JOB.ACTIONS[`POST_JOB_STEP${currentPage}`],
        { jobConfig: getJobCreationPayload(jobConfig) },
      ),
    );

    dispatch(action(ActionType.NAVIGATE_NEXT));
  };
};

export const navigateBack = () => {
  return async dispatch => {
    dispatch(action(ActionType.NAVIGATE_BACK));
  };
};

export const setShowErrors = () => {
  return async dispatch => {
    dispatch(action(ActionType.SET_SHOW_ERRORS));
  };
};

export const unSetShowErrors = () => {
  return async dispatch => {
    dispatch(action(ActionType.UNSET_SHOW_ERRORS));
  };
};

export const syncJobTitle = (title, isUserTitle) => {
  return async dispatch => {
    dispatch(action(ActionType.SET_JOB_TITLE, { title, isUserTitle }));
  };
};

export const syncDeveloperOwns = own => {
  return async dispatch => {
    dispatch(action(ActionType.SET_DEVELOPER_OWNS, own));
  };
};

export const syncMinExperience = minExperience => {
  return async dispatch => {
    dispatch(action(ActionType.SET_MIN_EXPERIENCE, minExperience));
  };
};

export const syncStrictBudget = isStrict => {
  return async dispatch => {
    dispatch(action(ActionType.SET_STRICT_BUDGET, isStrict));
  };
};

export const syncBudgetValue = budgetValue => {
  return async dispatch => {
    dispatch(action(ActionType.SET_BUDGET_VALUE, budgetValue));
  };
};

export const syncStrictLocation = isStrict => {
  return async dispatch => {
    dispatch(action(ActionType.SET_STRICT_LOCATION, isStrict));
  };
};

export const syncLocationValue = locationValue => {
  return async dispatch => {
    dispatch(action(ActionType.SET_LOCATION_VALUE, locationValue));
  };
};

export const getAllJobRoleTypes = () => {
  return async dispatch => {
    try {
      const response = await RequestService.get('job-role-type');
      dispatch(action(ActionType.SET_JOB_ROLE_TYPES, response));
    } catch (e) {
      console.log(e);
    }
  };
};

export const syncRoleType = roleType => {
  return async dispatch => {
    dispatch(action(ActionType.SET_ROLE_TYPE, roleType));
  };
};

export const syncOtherRoleType = role => {
  return async dispatch => {
    dispatch(action(ActionType.SET_OTHER_ROLE_TYPE, role));
  };
};

export const syncRequiredSkills = requiredSkills => {
  return async dispatch => {
    dispatch(action(ActionType.SET_REQUIRED_SKILLS, requiredSkills));
  };
};

export const syncHoursOverlap = hoursOverlap => {
  return async dispatch => {
    dispatch(action(ActionType.SET_HOURS_OVERLAP, hoursOverlap));
  };
};

export const syncOptionalSkills = optionalSkills => {
  return async dispatch => {
    dispatch(action(ActionType.SET_OPTIONAL_SKILLS, optionalSkills));
  };
};

export const syncAdditionalPreferenceSkills = optionalSkills => {
  return async dispatch => {
    dispatch(
      action(ActionType.SET_ADDITIONAL_PREFERENCE_SKILLS, optionalSkills),
    );
  };
};

export const syncAdditionalPreferencePositions = numPositions => {
  return async dispatch => {
    dispatch(
      action(ActionType.SET_ADDITIONAL_PREFERENCE_POSITIONS, numPositions),
    );
  };
};

export const syncAdditionalPreferenceText = preferenceText => {
  return async dispatch => {
    dispatch(action(ActionType.SET_ADDITIONAL_PREFERENCE_TEXT, preferenceText));
  };
};

export const createANewJob = () => {
  return async (dispatch, getState) => {
    const {
      jobCreation,
      flags,
      auth,
      jobSelection: { jobs = [] } = {},
    } = getState();
    const currentUser = auth?.currentUser;
    const isLimitedAccount = currentUser?.limitedAccount;
    const selfServeJobPostOptions = flags?.flagMap?.selfServeJobPostOptions;
    const enableJobFlowV2 = flags?.flagMap?.enableJobFlowV2;
    const isNewJobRatesEnable = isNewJobRatesEnabled(selfServeJobPostOptions);
    let payload = getJobCreationPayload(
      jobCreation,
      isNewJobRatesEnable,
      isLimitedAccount,
      enableJobFlowV2,
      jobs.length,
    );

    dispatch(action(ActionType.CREATE_NEW_JOB));
    try {
      let jobLimit, jobDetails;
      if (enableJobFlowV2) {
        payload = getJobFlowV2Payload(
          payload,
          jobCreation,
          isLimitedAccount,
          enableJobFlowV2,
          jobs.length,
        );
        const ret = await RequestService.postV2('self-serve-jobs', payload);
        (jobLimit = ret.jobLimit), (jobDetails = ret.jobDetails);
      } else {
        const ret = await RequestService.post('self-serve-jobs', payload);
        (jobLimit = ret.jobLimit), (jobDetails = ret.jobDetails);
      }
      const analyticsPayload = {
        id: jobDetails.id,
        jobId: jobDetails.job.id,
        source: getState().jobCreation.postJobButtonSource,
        startedAt: Date.now(),
      };

      dispatch(action(ActionType.CREATE_NEW_JOB_SUCCESS, jobDetails));

      dispatch(action(MainTypes.GET_JOB_LIMIT, { jobLimit }));
      dispatch(
        logActivity(
          ActivityTypes.JOB.KEY,
          ActivityTypes.JOB.ACTIONS.POST_JOB,
          analyticsPayload,
        ),
      );

      const newJob = mapNewJob(jobDetails);
      dispatch(setNewJob(newJob));
      dispatch(
        getJobList(() => {
          if (enableJobFlowV2 || isLimitedAccount) {
            dispatch(unSetJobCreationMode(true));
          }
        }),
      );
    } catch (err) {
      ToastService.error('Error occurred while Requesting.', err);
      dispatch(action(ActionType.CREATE_NEW_JOB_FAILURE));
    }
  };
};

export const editJob = id => {
  return async (dispatch, getState) => {
    const {
      jobCreation,
      flags,
      auth,
      jobSelection: { jobs = [] } = {},
    } = getState();
    const {
      selfServeJobPostOptions,
      enableAfterJobEditRedirect,
      sfsv2246EnableEditJobV2,
    } = flags?.flagMap || {};
    const isNewJobRatesEnable = isNewJobRatesEnabled(
      selfServeJobPostOptions,
      jobCreation.createdDate,
    );
    const currentUser = auth?.currentUser;
    const isLimitedAccount = currentUser?.limitedAccount;
    let payload = getJobCreationPayload(
      jobCreation,
      isNewJobRatesEnable,
      isLimitedAccount,
      sfsv2246EnableEditJobV2,
      jobs.length,
      true,
    );
    dispatch(action(ActionType.EDIT_JOB));
    try {
      let response;
      if (sfsv2246EnableEditJobV2) {
        payload = getJobFlowV2Payload(
          payload,
          jobCreation,
          isLimitedAccount,
          sfsv2246EnableEditJobV2,
          jobs.length,
          true,
        );
        response = await RequestService.patchV2(
          `self-serve-jobs/${id}`,
          payload,
        );
      } else {
        response = await RequestService.patch(`self-serve-jobs/${id}`, payload);
      }
      const analyticsPayload = {
        id: response.id,
        jobId: response.job.id,
      };

      dispatch(action(ActionType.EDIT_JOB_SUCCESS));
      dispatch(
        logActivity(
          ActivityTypes.JOB.KEY,
          ActivityTypes.JOB.ACTIONS.EDIT_JOB,
          analyticsPayload,
        ),
      );
      dispatch(
        getJobList(() => {
          if (enableAfterJobEditRedirect) {
            const jobId = response.job.id;
            dispatch(unSetJobEditMode());
            dispatch(redirectToJobSearchAfterJobEdit(jobId));
          }
        }),
      );
    } catch (err) {
      ToastService.error('Error occurred while Requesting.', err);
      dispatch(action(ActionType.EDIT_JOB_FAILURE));
    }
  };
};

export const unSetJobEditMode = () => {
  return async dispatch => {
    dispatch(action(ActionType.DISABLE_JOB_EDIT_MODE));
  };
};

export const patchAdditionalPreferences = id => {
  return async (dispatch, getState) => {
    const { jobCreation, flags } = getState();
    const enableAfterJobEditRedirect =
      flags?.flagMap?.enableAfterJobEditRedirect;
    const payload = getAdditionalPreferencePayload(jobCreation);

    dispatch(action(ActionType.SAVE_ADDITIONAL_PREFERENCE_STARTED));
    try {
      const response = await RequestService.patch(
        `self-serve-jobs/${id}/additional-details`,
        payload,
      );
      const analyticsPayload = {
        id: response.id,
        jobId: response.job.id,
      };

      dispatch(
        logActivity(
          ActivityTypes.JOB.KEY,
          ActivityTypes.JOB.ACTIONS.EDIT_JOB_ADDITIONAL_DETAILS,
          analyticsPayload,
        ),
      );
      dispatch(
        getJobList(() => {
          if (enableAfterJobEditRedirect) {
            const jobId = response.job.id;
            dispatch(unSetJobEditMode());
            dispatch(redirectToJobSearchAfterJobEdit(jobId));
          }
        }),
      );
    } catch (err) {
      ToastService.error('Error occurred while Requesting.', err);
    }
    dispatch(action(ActionType.SAVE_ADDITIONAL_PREFERENCE_COMPLETED));
  };
};

export const postAdditionalPreferences = id => {
  return async (dispatch, getState) => {
    const payload = getAdditionalPreferencePayload(getState().jobCreation);

    dispatch(action(ActionType.SAVE_ADDITIONAL_PREFERENCE_STARTED));
    try {
      const response = await RequestService.patch(
        `self-serve-jobs/${id}/additional-details`,
        payload,
      );
      const analyticsPayload = {
        id: response.id,
        jobId: response.job.id,
        source: getState().jobCreation.postJobButtonSource,
      };
      dispatch(
        logActivity(
          ActivityTypes.JOB.KEY,
          ActivityTypes.JOB.ACTIONS.POST_JOB_ADDITIONAL_DETAILS,
          analyticsPayload,
        ),
      );
    } catch (err) {
      ToastService.error('Error occurred while Requesting.', err);
    }

    const { jobCreation } = getState();
    const { currentPage, jobId } = jobCreation;
    dispatch(redirectToJobSearch(currentPage, jobId));

    dispatch(action(ActionType.SAVE_ADDITIONAL_PREFERENCE_COMPLETED));
  };
};

export const getJobById = selectedJob => {
  return async (dispatch, getState) => {
    try {
      const jobToEdit = await RequestService.get(
        `self-serve-jobs/${selectedJob.selfServeJobDetails.id}`,
      );
      const { flags } = getState();
      const selfServeJobPostOptions = flags?.flagMap?.selfServeJobPostOptions;
      const isNewJobRatesEnable = isNewJobRatesEnabled(
        selfServeJobPostOptions,
        jobToEdit.job.createdDate,
      );
      const sfsv2246EnableEditJobV2 = flags?.flagMap?.sfsv2246EnableEditJobV2;
      dispatch(
        action(ActionType.ENABLE_JOB_EDIT_MODE, {
          selectedJob: selectedJob.selfServeJobDetails,
          jobToEdit,
          isNewJobRatesEnable,
          sfsv2246EnableEditJobV2,
        }),
      );
    } catch (err) {
      console.error('getJobById', err);
      ToastService.error('Error occurred while Requesting.', err);
    }
  };
};

export const redirectToJobSearch = (currentPage, jobId, force = false) => {
  return async dispatch => {
    if (currentPage === -1 || force) {
      dispatch(switchCurrentJob(jobId));
      const jobSearchRoute = `/jobs/${jobId}/search`;
      history.push(jobSearchRoute);
    }
  };
};

export const duplicateJob = selectedJob => {
  return async dispatch => {
    try {
      let jobToDuplicate = {};
      if (selectedJob.selfServeJobDetails) {
        jobToDuplicate = await RequestService.get(
          `self-serve-jobs/${selectedJob.selfServeJobDetails.id}`,
        );
        jobToDuplicate.job.maxAcceptableRate = jobToDuplicate.job
          .maxAcceptableRate
          ? jobToDuplicate.job.maxAcceptableRate * WORKING_HOURS_PER_MONTH
          : '';
      } else {
        jobToDuplicate = {
          job: {
            role: selectedJob.name,
            roleTypeId: '',
            requiredSkills: selectedJob.skills || [],
            mustHaveTotalYearsOfExperience:
              selectedJob.mustHaveTotalYearsOfExperience,
            maxAcceptableRate: selectedJob.maxAcceptableRate
              ? selectedJob.maxAcceptableRate * WORKING_HOURS_PER_MONTH
              : '',
            optionalSkills: [],
            numberOfOpenRoles: '',
            notes: '',
          },
          otherRole: '',
          seniorityLevel: '',
          locationRequired: false,
          continentIds: [],
        };
      }
      dispatch(
        action(ActionType.DUPLICATE_JOB, {
          selectedJob: selectedJob.selfServeJobDetails,
          jobToDuplicate,
        }),
      );
    } catch (err) {
      console.error('[duplicateJob]', err.toString());
      ToastService.error('Error occurred while Requesting.', err);
    }
  };
};

const mapNewJob = jobDetails => ({
  jobLinkId: null,
  id: jobDetails.job.id,
  name: jobDetails.job.role,
  status: jobDetails.job.jobStatus.status,
  selfServeJobDetails: {
    id: jobDetails.id,
    otherRole: jobDetails.otherRole,
    status: jobDetails.status,
    seniorityLevel: jobDetails.seniorityLevel,
    locationRequired: jobDetails.locationRequired,
    continents: jobDetails.continents,
    continentIds: jobDetails.continentIds,
  },
  skills: jobDetails.job.requiredSkills,
  niceHaveTotalYearsOfExperience: jobDetails.job.niceHaveTotalYearsOfExperience,
  mustHaveTotalYearsOfExperience: jobDetails.job.mustHaveTotalYearsOfExperience,
  maxAcceptableRate: null,
});

const redirectToJobSearchAfterJobEdit = jobId => {
  return async dispatch => {
    dispatch(switchCurrentJob(jobId));
    const jobSearchRoute = `/jobs/${jobId}/search`;
    history.push(jobSearchRoute);
  };
};
