import React, { useEffect, useMemo, useState } from 'react';
import './invite-coworker-modal.scss';
import { Button, Checkbox, Modal, Select, Switch } from 'antd';
import { Form, Input } from 'antd';
import { InviteCoworkerSource } from '../../app/main/main.types';

const { TextArea } = Input;
const CheckboxGroup = Checkbox.Group;

const getEmailDomain = email => {
  if (email) {
    email = email.toLowerCase();
    return (email.match(/@(.*)/) || [])[1];
  }
  return '';
};

const getInitialSelectedJobIds = jobs => (jobs || []).map(job => job.id);

export const InviteCoworkerModal = ({
  allJobs,
  currentUser,
  showInviteCoworkerModal,
  toggleInviteCoworkerModal,
  invitingCoworker,
  inviteCoworker,
  logInviteCoworkerSubmit,
  sessionId,
  shareDeveloper,
  currentDeveloperHash,
  // this is data object about current share
  shareDeveloperByEmail,
  logShareDeveloperSubmit,
  resetShareDeveloperByEmailConfig,
  logRateToggle,
  logJobIdsUpdate,
  flagMap,
  inviteCoworkerModalTriggerSource,
  currentJobId,
}) => {
  // STATE
  const [includeRates, setIncludeRates] = useState(true);
  const [showError, setShowError] = useState(false);
  const [isEmailsError, setIsEmailsError] = useState(false);
  const [emails, setEmails] = useState([]);
  const [notes, setNotes] = useState(
    'Your input is required for a potential hire on Turing. Please accept this invitation and assist in our talent acquisition process.',
  );

  const [selectedJobIds, setSelectedJobIds] = useState(() =>
    getInitialSelectedJobIds(allJobs),
  );
  const [isSelectedAll, setIsSelectedAll] = useState(true);
  const { canSeeRates } = currentUser || {};
  const { enableInviteCoworkersRates } = flagMap;

  // EFFECTS
  useEffect(() => {
    resetInviteCoworkerModal();
  }, [showInviteCoworkerModal, currentUser]);

  useEffect(() => {
    if (inviteCoworkerModalTriggerSource === InviteCoworkerSource.HBT_TAB) {
      setSelectedJobIds([currentJobId]);
    }
  }, [inviteCoworkerModalTriggerSource, currentJobId]);

  // HANDLERS
  const resetInviteCoworkerModal = () => {
    setIsEmailsError(false);
    setEmails([]);
    setNotes(
      'Your input is required for a potential hire on Turing. Please accept this invitation and assist in our talent acquisition process.',
    );
    setShowError(false);
    setSelectedJobIds(getInitialSelectedJobIds(allJobs));
  };

  const validateEmailInput = emailsToValidate => {
    const emailList = emailsToValidate || emails;

    if (emailList.length === 0) {
      return true;
    }

    for (const email of emailList) {
      if (getEmailDomain(email) !== getEmailDomain(currentUser?.email))
        return false;
    }

    return true;
  };

  const handleNotesChange = e => {
    e.preventDefault();
    setNotes(e.target.value);
  };

  const handleShowRatesChange = e => {
    setIncludeRates(e);
    logRateToggle(emails, selectedJobIds, canSeeRates, notes, sessionId);
  };

  const handleInvite = () => {
    setShowError(true);

    if (!validateEmailInput() || emails.length === 0) {
      return;
    }

    if (shareDeveloperByEmail.showShareDeveloperModal) {
      shareDeveloper(
        emails,
        selectedJobIds,
        notes,
        currentDeveloperHash,
        currentJobId,
      );
      logShareDeveloperSubmit(
        emails,
        selectedJobIds,
        canSeeRates,
        notes,
        sessionId,
        shareDeveloperByEmail.developerToShare,
      );
    } else {
      inviteCoworker({
        emails,
        jobIds: selectedJobIds,
        notes,
        canSeeRates: includeRates,
      });
      logInviteCoworkerSubmit(
        emails,
        selectedJobIds,
        canSeeRates,
        notes,
        sessionId,
      );
    }
    toggleInviteCoworkerModal(false);
  };

  const handleCancel = () => {
    toggleInviteCoworkerModal(false);
    if (shareDeveloperByEmail.showShareDeveloperModal) {
      resetShareDeveloperByEmailConfig();
    }
  };

  const handleEmailChange = newEmails => {
    setEmails(newEmails);
    setIsEmailsError(!validateEmailInput(newEmails) && newEmails.length !== 0);
  };

  const handleCheckboxChange = list => {
    setSelectedJobIds(list);
    setIsSelectedAll(list.length === allJobs.length);
    logJobIdsUpdate(emails, list, canSeeRates, notes, sessionId);
  };

  const toggleSelectAll = () => {
    setSelectedJobIds(isSelectedAll ? [] : getInitialSelectedJobIds(allJobs));
    setIsSelectedAll(prevIsSelectedAll => !prevIsSelectedAll);
  };

  // RENDER
  const isButtonDisabled =
    invitingCoworker ||
    (showError && (!validateEmailInput() || emails.length === 0)) ||
    selectedJobIds.length === 0;

  const memoizedCheckBoxOptions = useMemo(() => {
    return allJobs.map(job => ({
      label: job.name,
      value: job.id,
    }));
  }, [allJobs]);

  return (
    <>
      <Modal
        closable={false}
        destroyOnClose={true}
        open={showInviteCoworkerModal}
        onCancel={handleCancel}
        footer={null}
        bodyStyle={{ padding: '32px 32px 24px 32px' }}
        wrapClassName="cowokrers-modal-container"
        data-cy="invitation-modal-container"
      >
        <h3 data-testid="invitation-modal-header">
          {shareDeveloperByEmail.showShareDeveloperModal
            ? 'Share developer'
            : 'Invite coworkers'}
        </h3>
        <Form
          layout="vertical"
          className="invite-coworkers-form"
          data-cy="invitation-modal-form"
        >
          <Form.Item
            label={<span className="input-label">Email addresses</span>}
          >
            <div>
              <Select
                data-cy="invitation-modal-email-field"
                data-testid="invitation-modal-email-field"
                size="large"
                mode="tags"
                placeholder="Enter email addresses"
                style={{ width: '100%' }}
                value={emails}
                onChange={handleEmailChange}
                notFoundContent={null}
                className={
                  showError && (!validateEmailInput() || emails.length === 0)
                    ? 'coworkers-from-input-container-error'
                    : ''
                }
              />
            </div>
            <span
              data-cy={
                isEmailsError
                  ? 'invite-coworkers-emails-helper-text-error'
                  : 'invite-coworkers-emails-helper-text'
              }
              data-testid={'invite-coworkers-emails-helper-text'}
              className={
                isEmailsError
                  ? 'email-helper-text email-helper-text-error'
                  : 'email-helper-text'
              }
            >
              Only @{getEmailDomain(currentUser?.email)} addresses are allowed
            </span>
          </Form.Item>
          <Form.Item
            label={<span className="input-label">Add a note (optional)</span>}
          >
            <TextArea
              value={notes}
              onChange={e => handleNotesChange(e)}
              className="coworkers-notes-textarea"
              data-cy="invitation-modal-notes-field"
              data-testid="invitation-modal-notes-field"
              bordered={true}
              rows={4}
              placeholder={
                shareDeveloperByEmail.showShareDeveloperModal
                  ? 'Take a look at this developer!'
                  : 'Let your coworkers know why you are inviting them'
              }
            />
          </Form.Item>
          {shareDeveloperByEmail.showShareDeveloperModal ? null : (
            <>
              {canSeeRates && enableInviteCoworkersRates ? (
                <>
                  <Switch
                    defaultChecked={includeRates}
                    onChange={handleShowRatesChange}
                    id="should-include-rates"
                    data-testid="invitation-modal-seerates-field"
                  />
                  &nbsp;&nbsp;
                  <label
                    htmlFor="should-include-rates"
                    style={{ cursor: 'pointer' }}
                    data-testid="invitation-modal-seerates-label"
                  >
                    Show rates
                  </label>
                </>
              ) : null}
              <div className="coworkers-jobs-container">
                <div className="coworkers-jobs-container-header">
                  <span className="coworker-jobs-title">
                    Jobs to be shared (
                    <span data-testid="invitation-modal-jobs-counter">
                      {selectedJobIds.length}
                    </span>
                    )
                  </span>
                  <Button
                    type="link"
                    color="primary"
                    className="coworkers-jobs-container-select-all-btn"
                    onClick={toggleSelectAll}
                    data-cy="invitation-modal-select-all-button"
                    data-testid="invitation-modal-select-all-button"
                  >
                    {isSelectedAll ? 'Deselect all' : 'Select all'}
                  </Button>
                </div>
                <CheckboxGroup
                  options={memoizedCheckBoxOptions}
                  value={selectedJobIds}
                  onChange={handleCheckboxChange}
                  className="ss-checkbox-wrapper"
                  data-testid="invitation-modal-jobs"
                />
              </div>
            </>
          )}
          <div className="coworkers-form-button-container">
            <Form.Item>
              <button
                onClick={handleCancel}
                className="coworkers-form-cancel-button"
                data-cy="invitation-modal-cancel-button"
                data-testid="invitation-modal-cancel-button"
              >
                Cancel
              </button>
            </Form.Item>
            <Form.Item>
              <button
                data-cy="invitation-modal-confirm-button"
                data-testid="invitation-modal-confirm-button"
                disabled={isButtonDisabled}
                onClick={handleInvite}
                className={
                  isButtonDisabled
                    ? 'coworkers-form-invite-button disabled'
                    : 'coworkers-form-invite-button'
                }
              >
                {shareDeveloperByEmail.showShareDeveloperModal
                  ? 'Send'
                  : 'Invite'}
              </button>
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </>
  );
};
