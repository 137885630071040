import { connect } from 'react-redux';
import { syncAdditionalPreferenceSkills } from '../../../../app/job-creation/job-creation.actions';
import { OptionalSkills } from './optional-skills';

const mapStateToProps = state => {
  return {
    skillCategories: state.main.allSkills,
    requiredSkills: state.jobCreation.requiredSkills,
    requiredSkillsList: state.jobCreation.requiredSkillsList,
    optionalSkills: state.jobCreation.additionalPreference.skills,
    showError: state.jobCreation.showValidationErrors,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleOptionalSkillChange: optionalSkill => {
      dispatch(syncAdditionalPreferenceSkills(optionalSkill));
    },
  };
};

const connected = connect(mapStateToProps, mapDispatchToProps)(OptionalSkills);
export { connected as OptionalSkillsContainer };
