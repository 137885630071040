import produce from 'immer';
import { ActionType } from './share-links.types';
const initialState = {
  isFetching: false,
  isValidating: false,
};

export const shareLinksReducer = (state = initialState, action) => {
  return produce(state, draftState => {
    if (action.type === ActionType.GENERATE_SHARE_LINK_STARTED) {
      draftState.isFetching = true;
    }
    if (action.type === ActionType.GENERATE_SHARE_LINK_FINISHED) {
      draftState.isFetching = false;
    }
    if (action.type === ActionType.VALIDATE_SHARE_LINK_STARTED) {
      draftState.isValidating = true;
    }
    if (action.type === ActionType.VALIDATE_SHARE_LINK_FINISHED) {
      draftState.isValidating = false;
    }
  });
};
