import { AppRouter } from './routes/Routes';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { turingLogger } from './shared/TuringLogger';
import { useFirstClickHook } from './components/firstClickComponent';

function App() {
  const history = useHistory();

  useFirstClickHook();

  useEffect(() => {
    turingLogger
      .pageview()
      .catch(e => console.error('[turing-client.pageview]', e));
    turingLogger.registerActivityTracker();

    return history.listen(() => {
      turingLogger
        .pageview()
        .catch(e => console.error('[turing-client.pageview]', e));
    });
  }, [history]);

  return <AppRouter />;
}

export default App;
