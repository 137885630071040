import { action } from 'typesafe-actions';
import {
  ActionType,
  InterviewRequestSource,
  SavedDeveloperInSavedSuggestionsModal,
} from './bulk-interviews.types';
import { RequestService, ToastService } from '../../services';
import { logActivity } from '../user-activity-trail/user-activity-trail.actions';
import { ActivityTypes } from '../user-activity-trail/user-activity-trail.types';
import { ActionType as MainActionType } from '../main/main.types';
import { message } from 'antd';
import Environment from '../../Enviroment';
import { getInterviewRequestedDevelopers } from '../interview-requested-developers/interview-requested-developers.actions';
import { initiateCalendarComponent } from '../main/main.actions';
import { IR_NUDGE_LIMIT } from '../../shared/constants';
import { getSavedDevelopers } from '../saved-developers/saved-developers.actions';
import {
  INTERACTIVE_PACKET_SELECTION_ACTIONS,
  PacketStatus,
} from '../handpicked-developers/handpicked-developers.types';
import { getHandpickedDevelopers } from '../handpicked-developers/handpicked-developers.actions';
import {
  getActionTypeForClosePacketNudgeModal,
  getInterviewRequestedDeveloperNames,
  getSavedDevsToInterview,
} from './bulk-interviews.util';

export const requestBulkInterviewThroughCalendar = (
  data: {
    jobId: number;
    timezone: number;
    interviewDuration: number;
    interviewerEmails: string;
    startTimes: string[];
    developersSelected: Array<{
      developerId: number;
      userHash: string;
      developerName: string;
      countryName: string;
      avatar: string;
    }>;
    packetHash?: string;
    developerPackets?: Array<any>;
  },
  source: string,
  rankingUuid: string,
  totalInterviewRequestedForJob: number,
) => {
  return async (dispatch: any, getState: any) => {
    const developerIds = data.developersSelected.map(
      ({ developerId }) => developerId,
    );

    if (source === InterviewRequestSource.savedSuggestionsModal) {
      dispatch(
        logActivity(
          ActivityTypes.REQUEST_INTERVIEW.KEY,
          ActivityTypes.REQUEST_INTERVIEW.ACTIONS
            .SAVED_SUGGESTIONS_NUDGE_MODAL_REQUEST_INTERVIEW_CLICKED,
          {
            developersCheckedCount: data.developersSelected.length,
            developerIds,
          },
        ),
      );
      dispatch(action(ActionType.BULK_IR_STARTED_FROM_SAVED_SUGGESTION_MODAL));
    }

    dispatch(
      action(MainActionType.CALENDAR_REQUEST_STARTED, {
        developerIds: data.developersSelected.map(
          ({ developerId }) => developerId,
        ),
      }),
    );

    try {
      const { developersSelected, ...rest } = data;
      const postData = {
        ...rest,
        developerIds,
      };

      const { interviewRequestLimit, success } = await RequestService.post(
        `self-serve-interview-requests/bulk`,
        postData,
      );

      dispatch(action(MainActionType.EXIT_INTERVIEW_REQUEST, {}));
      dispatch(closeBulkIRCalendar());
      dispatch(
        action(MainActionType.CALENDAR_REQUEST_COMPLETED, {
          developerIds: data.developersSelected.map(
            ({ developerId }) => developerId,
          ),
        }),
      );

      if (!success) {
        ToastService.error(
          `Error Requesting the developer${
            data.developersSelected.length > 1 ? 's' : ''
          } `,
        );
      } else {
        dispatch(getSavedDevelopers(false));

        for (let index = 0; index < data.developersSelected.length; index++) {
          const developer = data.developersSelected[index];

          dispatch(
            action(MainActionType.REQUEST_INTERVIEW, {
              developerIdHash: developer.userHash,
              developerId: developer.developerId,
              jobId: data.jobId,
            }),
          );

          dispatch(
            logActivity(
              ActivityTypes.REQUEST_INTERVIEW.KEY,
              ActivityTypes.REQUEST_INTERVIEW.ACTIONS
                .REQUESTED_DEVELOPER_INTERVIEW,
              {
                developerId: developer.developerId,
                rankingUuid,
                source,
                jobId: data.jobId,
                calendarUsed: true,
                developerIds,
                packetHash: data.packetHash,
              },
              developer.developerId,
            ),
          );
        }

        if (developerIds.length > 1) {
          dispatch(
            logActivity(
              ActivityTypes.REQUEST_INTERVIEW.KEY,
              ActivityTypes.REQUEST_INTERVIEW.ACTIONS
                .REQUESTED_DEVELOPER_INTERVIEW_BULK,
              {
                source,
                jobId: data.jobId,
                calendarUsed: true,
                developerIds,
                packetHash: data.packetHash,
              },
            ),
          );
        }

        if (interviewRequestLimit) {
          dispatch(
            action(MainActionType.GET_INTERVIEW_REQUEST_LIMIT, {
              interviewRequestLimit,
            }),
          );
        }
        const state = getState();

        const savedDevelopers = getSavedDevsToInterview(
          state,
          data.jobId,
          postData.developerIds,
        ).slice(0, 5);

        totalInterviewRequestedForJob =
          totalInterviewRequestedForJob + data.developersSelected.length;

        const developerNames: string[] = getInterviewRequestedDeveloperNames(
          data.developersSelected,
        );

        let showMessage = true;
        if (
          totalInterviewRequestedForJob < 5 &&
          savedDevelopers.length === 0 &&
          !data.packetHash
        ) {
          const dontRemindJobs: number[] = state.bulkInterviews.dontRemindJobs;
          const dontRemind = dontRemindJobs.includes(data.jobId);

          if (!dontRemind) {
            showMessage = false;
            dispatch(openInterviewReminderModal(totalInterviewRequestedForJob));
          }
          dispatch(setDeveloperSelected([]));
        } else if (
          totalInterviewRequestedForJob > 5 &&
          savedDevelopers.length === 0
        ) {
          //do not show anything
          dispatch(setDeveloperSelected([]));
        } else if (savedDevelopers.length > 0 && !data.packetHash) {
          dispatch(
            showSavedSuggestionModal(savedDevelopers, developerNames.join('')),
          );

          dispatch(
            logActivity(
              ActivityTypes.REQUEST_INTERVIEW.KEY,
              ActivityTypes.REQUEST_INTERVIEW.ACTIONS
                .SAVED_SUGGESTIONS_NUDGE_MODAL_OPEN,
              {
                developerCount: savedDevelopers.length,
              },
            ),
          );

          showMessage = false;
        }

        if (showMessage && !data.packetHash) {
          dispatch(setDeveloperSelected([]));
          let successMessage = `Congratulations! We have requested meeting${
            data.developersSelected.length > 1 ? 's' : ''
          } for developer ${developerNames.join('')} with you. `;

          if (totalInterviewRequestedForJob < IR_NUDGE_LIMIT) {
            let remainingCount = IR_NUDGE_LIMIT - totalInterviewRequestedForJob;
            successMessage =
              successMessage +
              `Please request ${remainingCount} more ${
                remainingCount > 1 ? 'candidates' : 'candidate'
              } to ensure a successful match`;
          }
          // @ts-ignore
          message.success({
            content: successMessage,
            duration: 5,
            className: 'interview-success-message',
          });
        }

        if (data.packetHash) {
          dispatch(
            confirmOrRejectInteractivePacketDevelopers(
              data.packetHash,
              PacketStatus.INTERVIEW_SCHEDULED,
              data.developerPackets,
              INTERACTIVE_PACKET_SELECTION_ACTIONS.CONFIRM,
              () => {
                dispatch(setDeveloperSelected([]));
                dispatch(getHandpickedDevelopers());
                dispatch(getInterviewRequestedDevelopers(false));
              },
            ),
          );
          dispatch(
            action(ActionType.SET_PACKET_HASH_FOR_CALENDAR_IRS, {
              currentPacketHash: null,
              developerPackets: null,
            }),
          );
          dispatch(openPacketNudgeModal(data.packetHash));
        } else {
          dispatch(getInterviewRequestedDevelopers(false));
        }
      }
    } catch (e) {
      ToastService.error('Error occurred while Requesting.', e);
    }

    dispatch(action(MainActionType.EXIT_INTERVIEW_REQUEST, {}));
    dispatch(closeBulkIRCalendar());
    dispatch(
      action(MainActionType.CALENDAR_REQUEST_COMPLETED, {
        developerIds: data.developersSelected.map(
          ({ developerId }) => developerId,
        ),
      }),
    );

    if (source === InterviewRequestSource.savedSuggestionsModal) {
      dispatch(
        action(ActionType.BULK_IR_COMPLETED_FROM_SAVED_SUGGESTION_MODAL),
      );
    }
  };
};

export const mapSavedDevelopersToDevelopersSelected = (
  savedDevelopers: SavedDeveloperInSavedSuggestionsModal[],
) => {
  return savedDevelopers.map(
    ({ developerId, userHash, name, avatar, countryName }) => {
      return {
        developerId,
        userHash,
        developerName: name,
        avatar,
        countryName,
      };
    },
  );
};

export const showSavedSuggestionModal = (
  savedSuggestionModalDevelopers: SavedDeveloperInSavedSuggestionsModal[],
  savedSuggestionsModalDeveloperNames: string,
) => {
  return async dispatch => {
    dispatch(
      action(ActionType.SHOW_SAVED_SUGGESTION_MODAL, {
        savedSuggestionModalDevelopers,
        developersSelected: mapSavedDevelopersToDevelopersSelected(
          savedSuggestionModalDevelopers,
        ),
        savedSuggestionsModalDeveloperNames,
      }),
    );
  };
};
export const openInterviewReminderModal = (
  totalInterviewRequestedForJob: number,
) => {
  return async (dispatch: any, getState: any) => {
    dispatch(
      action(ActionType.OPEN_INTERVIEW_REMINDER_MODAL, {
        totalInterviewRequestedForJob,
      }),
    );
  };
};

export const openPacketNudgeModal = (packetHash: string) => {
  return async (dispatch: any, getState: any) => {
    dispatch(action(ActionType.OPEN_PACKET_NUDGE, { packetHash }));
  };
};

export const closePacketNudgeModal = (
  isAutoClose: boolean,
  isRedirectedToSearch: boolean,
) => {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    const logPayload = {
      packetIdHash: state.bulkInterviews.currentPacketHash,
      jobId: state.jobSelection.currentJobId,
      user: state.auth.currentUser?.id,
      timestamp: Date.now(),
    };
    const actionType = getActionTypeForClosePacketNudgeModal(
      isAutoClose,
      isRedirectedToSearch,
    );

    dispatch(
      logActivity(
        ActivityTypes.HANDPICKED_DEVELOPERS.KEY,
        actionType,
        logPayload,
      ),
    );

    dispatch(action(ActionType.CLOSE_PACKET_NUDGE, {}));
  };
};

export const openPacketDeveloperRejectionModal = (
  packetHash: string,
  packetDeveloper: any,
) => {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    const logPayload = {
      packetIdHash: packetHash,
      developer: {
        developerId: packetDeveloper.userId,
        developerPacketId: packetDeveloper.interactivePacketDeveloperId,
      },
      jobId: state.jobSelection.currentJobId,
      user: state.auth.currentUser?.id,
      timestamp: Date.now(),
    };

    dispatch(
      logActivity(
        ActivityTypes.HANDPICKED_DEVELOPERS.KEY,
        ActivityTypes.HANDPICKED_DEVELOPERS.ACTIONS
          .REJECTED_HANDPICKED_DEVELOPER,
        logPayload,
      ),
    );

    dispatch(
      action(ActionType.OPEN_PACKET_DEVELOPER_REJECTION_MODAL, {
        packetHash,
        packetDeveloper,
      }),
    );
  };
};

export const changePacketStatusToOpened = (packetIds: number[]) => {
  return async (dispatch: any, getState: any) => {
    RequestService.post('interactive-packets/change-packet-status-to-opened', {
      packetIds,
    });
  };
};

export const closePacketDeveloperRejectionModal = () => {
  return async (dispatch: any, getState: any) => {
    dispatch(action(ActionType.CLOSE_PACKET_DEVELOPER_REJECTION_MODAL, {}));
  };
};

export const logRejectionWithoutFeedback = (logPayload: any) => {
  return async (dispatch: any, getState: any) => {
    if (!logPayload) {
      return;
    }
    const state = getState();
    const updatedPayload = {
      ...logPayload,
      jobId: state.jobSelection.currentJobId,
      user: state.auth.currentUser.id,
      timestamp: Date.now(),
    };
    dispatch(
      logActivity(
        ActivityTypes.HANDPICKED_DEVELOPERS.KEY,
        ActivityTypes.HANDPICKED_DEVELOPERS.ACTIONS
          .REJECTED_HANDPICKED_DEVELOPER_WITHOUT_FEEDBACK,
        updatedPayload,
      ),
    );
  };
};

export const logPostPacketNudgeActivity = (logPayload: any) => {
  return async (dispatch: any, getState: any) => {
    const updatedPayload = {
      ...logPayload,
      user: getState().auth.currentUser.id,
      timestamp: Date.now(),
    };
    dispatch(
      logActivity(
        ActivityTypes.HANDPICKED_DEVELOPERS.KEY,
        ActivityTypes.HANDPICKED_DEVELOPERS.ACTIONS
          .POST_CONFIRM_CLOSED_BROWSER_TAB,
        updatedPayload,
      ),
    );
  };
};

export const fetchRejectionReasons = () => {
  return async (dispatch: any, getState: any) => {
    const data = await RequestService.get(
      `${Environment.matchingOrigin}interactive-packets-public/developer-rejection-reasons-options`,
    );
    dispatch(action(ActionType.REJECTION_REASONS_FETCHED, { data }));
  };
};

export const setDeveloperSelected = (
  developersSelected: Array<{
    developerId: number;
    userHash: string;
    developerName: string;
    countryName: string;
    avatar: string;
  }>,
) => {
  return async (dispatch: any, getState: any) => {
    dispatch(
      action(ActionType.SET_DEVELOPERS_SELECTED, { developersSelected }),
    );
  };
};

export const initiateCalendar = (
  developerCount: number,
  source: string,
  currentJobIdForIR: number,
  developers?: Array<any>,
  currentPacketHash?: string,
  developerPackets?: Array<any>,
) => {
  return async (dispatch: any) => {
    if (developers) {
      dispatch(setDeveloperSelected(developers));
    }

    if (currentPacketHash) {
      dispatch(
        action(ActionType.SET_PACKET_HASH_FOR_CALENDAR_IRS, {
          currentPacketHash,
          developerPackets,
        }),
      );
    }

    dispatch(initiateCalendarComponent());

    dispatch(
      action(ActionType.SET_SOURCE_FOR_IR, { source, currentJobIdForIR }),
    );

    if (source === InterviewRequestSource.savedSuggestionsModal) {
      dispatch(
        action(ActionType.INITIATE_CALENDAR_FROM_SAVED_SUGGESTIONS_MODAL, {}),
      );
    }
  };
};

export const closeBulkIRCalendar = () => {
  return async (dispatch: any, getState: any) => {
    dispatch(action(ActionType.CLOSE_BULK_IR_CALENDAR, {}));
  };
};

export const selectOrUnSelectDeveloperForBulkIR = (
  developerId: number,
  selected: boolean,
  userHash: string,
  developerName: string,
  countryName: string,
  avatar: string,
) => {
  return async (dispatch: any, getState: any) => {
    dispatch(
      action(ActionType.SELECT_OR_UNSELECT_FOR_BULK_IR, {
        developerId,
        userHash,
        selected,
        developerName,
        countryName,
        avatar,
      }),
    );
  };
};

export const skipSavedSuggestions = (developerCount: number) => {
  return async (dispatch: any, getState: any) => {
    dispatch(action(ActionType.SKIP_SUGGESTION_MODAL, {}));
    dispatch(
      logActivity(
        ActivityTypes.REQUEST_INTERVIEW.KEY,
        ActivityTypes.REQUEST_INTERVIEW.ACTIONS
          .SAVED_SUGGESTIONS_NUDGE_MODAL_SKIP_CLICKED,
        {
          developersCheckedCount: developerCount,
        },
      ),
    );
  };
};

export const dontRemindAction = (dontRemindJobs: Array<number>) => {
  return async (dispatch: any, getState: any) => {
    dispatch(
      action(ActionType.DONT_REMIND_ME_INTERVIEW_REMINDER, { dontRemindJobs }),
    );
  };
};

export const addDevInFeedBackCollectedList = (developerId: number) => {
  return async (dispatch: any, getState: any) => {
    dispatch(
      action(ActionType.ADD_DEVELOPER_TO_FEEDBACK_COLLECTED_LIST, {
        developerId,
      }),
    );
  };
};

export const continueOnInterviewReminderModal = () => {
  return async (dispatch: any, getState: any) => {
    dispatch(action(ActionType.CONTINUE_ON_INTERVIEW_REMINDER_MODAL, {}));
  };
};

export const setCurrentJobIdForIR = (jobId: number) => {
  return async (dispatch: any, getState: any) => {
    dispatch(action(ActionType.SET_CURRENT_JOB_ID_FOR_IR, { jobId }));
    dispatch(
      logActivity(
        ActivityTypes.REQUEST_INTERVIEW.KEY,
        ActivityTypes.REQUEST_INTERVIEW.ACTIONS
          .CUSTOMER_SWITCHED_JOB_ON_PREFERENCES_MODAL,
        {
          jobId,
        },
      ),
    );
  };
};

export const logPageIdle = (payload: any) => {
  return async (dispatch: any, getState: any) => {
    dispatch(
      logActivity(
        ActivityTypes.HANDPICKED_DEVELOPERS.KEY,
        ActivityTypes.HANDPICKED_DEVELOPERS.ACTIONS.HANDPICKED_PAGE_EXIT,
        payload,
      ),
    );
  };
};

export const confirmOrRejectInteractivePacketDevelopers = (
  packetHashId: string,
  packetStatusTypeSystemName: string,
  developerPackets = [],
  packetAction = INTERACTIVE_PACKET_SELECTION_ACTIONS.CONFIRM,
  onDoneCallback?: any,
) => {
  return async (dispatch: any, getState: any) => {
    if (packetAction === INTERACTIVE_PACKET_SELECTION_ACTIONS.REJECT) {
      const logPayload = {
        packetIdHash: packetHashId,
        developer: developerPackets[0],
        jobId: getState().jobSelection.currentJobId,
        user: getState().auth.currentUser.id,
        timestamp: Date.now(),
      };
      dispatch(
        logActivity(
          ActivityTypes.HANDPICKED_DEVELOPERS.KEY,
          ActivityTypes.HANDPICKED_DEVELOPERS.ACTIONS
            .REJECTED_HANDPICKED_DEVELOPER_WITH_FEEDBACK,
          logPayload,
        ),
      );
    }
    dispatch(action(ActionType.INTERACTIVE_PACKET_ACTION_REQUEST_STARTED));
    try {
      await RequestService.patch(
        `${Environment.matchingOrigin}interactive-packets-public/${packetHashId}/confirm`,
        {
          developerPackets,
          packetStatusTypeSystemName,
          packetAction,
          ...(packetStatusTypeSystemName ===
            PacketStatus.INTERVIEW_SCHEDULED && {
            confirmedFromSelfServe: true,
          }),
        },
      );
    } catch (er) {}

    dispatch(action(ActionType.INTERACTIVE_PACKET_ACTION_COMPLETED));

    if (onDoneCallback) {
      onDoneCallback();
    }
  };
};
