import { combineReducers } from 'redux';
import { mainReducer } from '../app/main/main.reducers';
import { developerProfileReducer } from '../app/developer-profile/developer-profile.reducers';
import { continentsReducer } from '../app/continents/continents.reducers';
import { hiddenDevelopersReducer } from '../app/hidden-developers/hidden-developers.reducers';
import { savedDevelopersReducer } from '../app/saved-developers/saved-developers.reducers';
import { interviewRequestedDevelopersReducer } from '../app/interview-requested-developers/interview-requested-developers.reducers';
import { handpickedDevelopersReducer } from '../app/handpicked-developers/handpicked-developers.reducers';
import { authReducer } from '../app/auth/auth.reducers';
import { shareLinksReducer } from '../app/share-links/share-links.reducer';
import { recentlyViewedDevelopersReducer } from '../app/recently-viewed-developers/recently-viewed-developers.reducers';
import { jobSelectionReducer } from '../app/job-selection/job-selection.reducers';
import { jobCreationReducer } from '../app/job-creation/job-creation.reducers';
import { flagsReducer } from '../app/flags/flags.reducers';
import { ActionType } from '../app/auth/auth.types';
import { bulkInterviewsReducer } from '../app/bulk-interviews/bulk-interviews.reducers';
import { nativeBookACallReducer } from '../components/native-book-a-call/native-book-a-call.reducers';
import { postmatchReducer } from '../app/postmatch/postmatch.reducers';
import { timesheetsReducer } from '../app/timesheets/timesheeets.reducer';
import { timeCardSummaryReducer } from '../app/time-card/time-card.reducer';

export const allReducers = combineReducers({
  main: mainReducer,
  developerProfile: developerProfileReducer,
  continents: continentsReducer,
  recentlyViewedDevelopers: recentlyViewedDevelopersReducer,
  hiddenDevelopers: hiddenDevelopersReducer,
  savedDevelopers: savedDevelopersReducer,
  interviewRequestedDevelopers: interviewRequestedDevelopersReducer,
  handpickedDevelopers: handpickedDevelopersReducer,
  jobSelection: jobSelectionReducer,
  shareLinks: shareLinksReducer,
  auth: authReducer,
  jobCreation: jobCreationReducer,
  flags: flagsReducer,
  bulkInterviews: bulkInterviewsReducer,
  nativeBookACall: nativeBookACallReducer,
  postmatch: postmatchReducer,
  timesheets: timesheetsReducer,
  timeCardPage: timeCardSummaryReducer,
});

export const rootReducers = (state, action) => {
  if (action.type === ActionType.USER_LOGOUT) {
    state = undefined;
  }
  return allReducers(state, action);
};
