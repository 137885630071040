import React from 'react';
import { Input } from 'antd';
import { roleTypeIdMap } from '../../../../app/job-creation/job-creation.constants';
import { isValidRole } from '../../../../app/job-creation/job-creation.util';
import { SkillTags } from '../../../main/components/skill-tags/skill-tags';
import './style.scss';

export const JobRole = ({
  roleTypesList,
  roleType,
  handleRoleTypeChange,
  handleOtherRoleTypeChange,
  showError,
  roleLabel = 'What role type are you looking for?',
}) => {
  const handleOtherRoleChange = ev => {
    handleOtherRoleTypeChange(ev.target.value);
  };

  return (
    <div className="job-role">
      <div className="selection-container" data-cy="role-type">
        <div className="label">{roleLabel}</div>
        <div className="tags-container">
          <SkillTags
            skills={roleTypesList}
            onSkillsChange={handleRoleTypeChange}
            alreadySelectedSkillIds={[roleType.id]}
          />
        </div>
        {roleType.id === roleTypeIdMap.OTHER && (
          <div className="other-role">
            <div>Other (please specify)</div>
            <Input
              onChange={handleOtherRoleChange}
              value={roleType.otherValue}
              className={
                showError && !isValidRole(roleType) ? 'error-border' : ''
              }
              placeholder="Enter here"
            />
          </div>
        )}
        {showError && !isValidRole(roleType) && (
          <div className="error-label">
            {roleType.id === roleTypeIdMap.OTHER
              ? 'Please enter a job role'
              : 'Please select a job role'}
          </div>
        )}
      </div>
    </div>
  );
};
