export const ActionType = {
  SET_TIMEZONES: 'SET_TIMEZONES',
  GET_SKILLS: 'GET_SKILLS',
  SET_ARE_ALL_SKILLS_FETCHED: 'SET_ARE_ALL_SKILLS_FETCHED',
  GET_SENIORITY: 'GET_SENIORITY',
  GET_INTERVIEW_REQUESTS: 'GET_INTERVIEW_REQUESTS',
  GET_INTERVIEW_REQUEST_LIMIT: 'GET_INTERVIEW_REQUEST_LIMIT',
  GET_JOB_LIMIT: 'GET_JOB_LIMIT',
  GET_PROFILE_VIEW_COUNT: 'GET_PROFILE_VIEW_COUNT',
  GET_OPPORTUNITY_OWNER: 'GET_OPPORTUNITY_OWNER',
  GET_JOBS_HAVING_PACKETS: 'GET_JOBS_HAVING_PACKETS',
  REQUEST_INTERVIEW: 'REQUEST_INTERVIEW',
  SET_SEARCH_DEBUG: 'SET_SEARCH_DEBUG',
  SET_SEARCH_DEBUG_DATA: 'SET_SEARCH_DEBUG_DATA',
  SET_OVERRIDE_FEATURE_FLAG: 'SET_OVERRIDE_FEATURE_FLAG',
  GET_DEVELOPERS: 'GET_DEVELOPERS',
  STOP_SEARCH: 'STOP_SEARCH',
  FETCH_DEVELOPERS_STARTED: 'FETCH_DEVELOPERS_STARTED',
  FETCH_DEVELOPERS_COMPLETED: 'FETCH_DEVELOPERS_COMPLETED',
  SET_CURRENT_DEVELOPER_HASH: 'SET_CURRENT_DEVELOPER_HASH',
  SET_FILTER_SKILLS: 'SET_FILTER_SKILLS',
  SET_FETCH_BY_INFINITE_SCROLL: 'SET_FETCH_BY_INFINITE_SCROLL',
  SET_PAGE_NUMBER: 'SET_PAGE_NUMBER',
  REMOVE_HIDDEN_DEVELOPER: 'REMOVE_HIDDEN_DEVELOPER',
  SET_DDP_VIEW_LIMIT_REACHED: 'SET_DDP_VIEW_LIMIT_REACHED',
  CLEAR_RANKING_UUID: 'CLEAR_RANKING_UUID',
  USER_PROFILE_INITIALIZATION_STARTED: 'USER_PROFILE_INITIALIZATION_STARTED',
  USER_PROFILE_INITIALIZATION_COMPLETED:
    'USER_PROFILE_INITIALIZATION_COMPLETED',
  SEARCH_INITIALIZATION_STARTED: 'SEARCH_INITIALIZATION_STARTED',
  SEARCH_INITIALIZATION_COMPLETED: 'SEARCH_INITIALIZATION_COMPLETED',
  RESET_TOTAL_MATCHED: 'RESET_TOTAL_MATCHED',
  INTERVIEW_REQUEST_SUBMISSION_BANNER: 'INTERVIEW_REQUEST_SUBMISSION_BANNER',
  INITIATE_CALENDAR_COMPONENT: 'INITIATE_CALENDAR_COMPONENT',
  SELECT_DATE: 'SELECT_DATE',
  SET_CUSTOMER_TIMEZONE: 'SET_CUSTOMER_TIMEZONE',
  SET_DURATION: 'SET_DURATION',
  REMOVE_INTERVIEWER_EMAIL: 'REMOVE_INTERVIEWER_EMAIL',
  ADD_INTERVIEWER_EMAIL: 'ADD_INTERVIEWER_EMAIL',
  ADD_START_TIME: 'ADD_START_TIME',
  CANCEL_PREFERENCES: 'CANCEL_PREFERENCES',
  CONFIRM_PREFERENCES: 'CONFIRM_PREFERENCES',
  TIME_SLOT_DESELECT: 'TIME_SLOT_DESELECT',
  UPDATE_CALENDAR_PREFERENCES: 'UPDATE_CALENDAR_PREFERENCES',
  CALENDAR_REQUEST_STARTED: 'CALENDAR_REQUEST_STARTED',
  CALENDAR_REQUEST_COMPLETED: 'CALENDAR_REQUEST_COMPLETED',
  NAVIGATE_IN_SEARCH_PAGE: 'NAVIGATE_IN_SEARCH_PAGE',
  TOGGLE_SHOW_DISPLAY_FAILED_SEARCH_COMPONENT:
    'TOGGLE_SHOW_DISPLAY_FAILED_SEARCH_COMPONENT',
  TOGGLE_INVITE_COWORKER_MODAL: 'TOGGLE_INVITE_COWORKER_MODAL',
  INVITE_COWORKER_STARTED: 'INVITE_COWORKER_STARTED',
  INVITE_COWORKER_COMPLETED: 'INVITE_COWORKER_COMPLETED',
  SET_INVITE_COWORKER_TOGGLE_TRIGGER_SOURCE:
    'SET_INVITE_COWORKER_TOGGLE_TRIGGER_SOURCE',
  SET_SIDEBAR_MORE_FILTERS_STATUS: 'SET_SIDEBAR_MORE_FILTERS_STATUS',
  TOGGLE_SHARE_DEVELOPER_BY_EMAIL: 'TOGGLE_SHARE_DEVELOPER_BY_EMAIL',
  CANCEL_CALENDAR: 'CANCEL_CALENDAR',
  EXIT_INTERVIEW_REQUEST: 'EXIT_INTERVIEW_REQUEST',
  CONTINUE_INTERVIEW_REQUEST: 'CONTINUE_INTERVIEW_REQUEST',
  CONNECT_GOOGLE_CALENDAR: 'CONNECT_GOOGLE_CALENDAR',
  GET_CALENDAR_BUSY_SLOTS: 'GET_CALENDAR_BUSY_SLOTS',
  DISCONNECT_GOOGLE_CALENDAR: 'DISCONNECT_GOOGLE_CALENDAR',
  SET_CUSTOMER_STARTTIMES: 'SET_CUSTOMER_STARTTIMES',
  SET_FEWER_SKILLS_FILTER_NUDGE_HIDE_FOR_JOB:
    'SET_FEWER_SKILLS_FILTER_NUDGE_HIDE_FOR_JOB',
  SET_LIMITED_ACCOUNT_PROMPT_TYPE: 'SET_LIMITED_ACCOUNT_PROMPT_TYPE',
  CLOSE_LIMITED_ACCOUNT_PROMPT: 'CLOSE_LIMITED_ACCOUNT_PROMPT',
};

export const PAGE_SIZE = 30;

export const SkillSelectionRuleType = {
  MULTIPLE: 'MULTIPLE',
  SINGLE: 'SINGLE',
  EXCLUSIVE: 'EXCLUSIVE',
};

export const SkillCategories = [
  {
    categoryName: 'General',
    type: SkillSelectionRuleType.MULTIPLE,
    skills: [
      'react',
      'angular',
      'vue.js',
      'typescript',
      'node.js',
      'python',
      'java',
      'php',
      'c#',
      'ruby on rails',
      'swift (for ios)',
      'android',
      'react native',
      'devops',
      'machine learning',
      'data engineering',
    ],
  },
];

export const FilterFieldNames = {
  resumeKeywords: 'resumeKeywords',
  fixedSkillIds: 'fixedSkillIds',
  continentIds: 'continentIds',
  totalYOEFrom: 'totalYOEFrom',
  totalYOETo: 'totalYOETo',
  monthlyBudget: 'monthlyBudget',
};

export const DefaultFilterSkills = {
  [FilterFieldNames.resumeKeywords]: [],
  [FilterFieldNames.fixedSkillIds]: [],
  [FilterFieldNames.continentIds]: [],
  [FilterFieldNames.totalYOEFrom]: '',
  [FilterFieldNames.totalYOETo]: '',
  [FilterFieldNames.monthlyBudget]: '',
};

export const VettedSkills = [
  'android',
  'angular',
  'apollo',
  'aws',
  'blockchain',
  'c',
  'c#',
  'c++',
  'cakephp',
  'css',
  'd3.js',
  'data analysis',
  'data engineering',
  'designer',
  'devops',
  'django',
  'docker',
  'flask',
  'flutter',
  'git',
  'go/golang',
  'google cloud platform',
  'graphql',
  'html',
  'ionic',
  'ios development',
  'java',
  'javascript',
  'jenkins',
  'kubernetes',
  'laravel',
  'machine learning',
  'matlab',
  'mongodb',
  'next.js',
  'node.js',
  'objective-c',
  'php',
  'project management',
  'python',
  'pytorch',
  'qa',
  'react',
  'react native',
  'redis',
  'redux',
  'ruby',
  'ruby on rails',
  'rxswift',
  'scala',
  'spark',
  'spring boot',
  'sql',
  'swift',
  'tensorflow',
  'typescript',
  'vue.js',
  'vuex',
  'wordpress',
  'wpf',
];

export const LimitedAccountPromptTypes = {
  requestInterviewUnSavedDeveloper: 'requestInterviewUnSavedDeveloper',
  requestInterviewSavedDeveloper: 'requestInterviewSavedDeveloper',
  inviteCoworker: 'inviteCoworker',
  limitedAccessChip: 'limitedAccessChip',
};

export const LimitedAccountPromptConstants = {
  [LimitedAccountPromptTypes.requestInterviewSavedDeveloper]: {
    callNotBooked: {
      body: 'Book a call with our sales team to get full access to the Turing platform and start meeting developers for your job.',
      heading: 'Ready to meet developers?',
    },
    callBooked: {
      body: 'After we learn more about your requirements, you will be ready to meet top candidates for your job. ',
      heading: 'Ready to meet a candidate? ',
    },
  },
  [LimitedAccountPromptTypes.requestInterviewUnSavedDeveloper]: {
    callNotBooked: {
      body: 'Book a call with our sales team to get full access to the Turing platform and start meeting developers for your job. We will add the developer to your Saved section.',
      heading: 'Ready to meet developers?',
    },
    callBooked: {
      body: 'After we learn more about your requirements, you will be ready to meet top candidates for your job. ',
      heading: 'Ready to meet a candidate? ',
    },
  },
  [LimitedAccountPromptTypes.inviteCoworker]: {
    callNotBooked: {
      body: 'Book a call with our sales team to get full access to the Turing platform and start inviting coworkers for your job. ',
      heading: 'Ready to invite your coworkers? ',
    },
    callBooked: {
      body: 'After we learn more about your requirements, you will be ready to invite your coworkers to join your team. ',
      heading: 'Ready to invite your coworkers? ',
    },
  },

  [LimitedAccountPromptTypes.limitedAccessChip]: {
    callNotBooked: {
      body: 'Book a call with our sales team to get full access to the Turing platform and start meeting developers for your job.  ',
      heading: 'Ready to meet developers? ',
    },
    callBooked: {
      body: 'After we learn more about your requirements, you’ll unlock access to all Turing features.',
      heading: 'Ready to meet developers? ',
    },
  },
};

export const BookACallSource = {
  requestInterviewModal: 'requestInterviewModal',
  inviteCoworkerModal: 'inviteCoworkerModal',
  dashboard: 'dashboard',
  handpickedTab: 'handpickedTab',
  interviewsTab: 'interviewsTab',
  searchResultsTop: 'searchResultsTop',
  searchResultsInBetween: 'searchResultsInBetween',
  savedTab: 'savedTab',
  limitedAccessModal: 'limitedAccessModal',
};

export const FiltersMonthlyRateFields = [FilterFieldNames.monthlyBudget];

export const InviteCoworkerSource = {
  DASHBOARD: 'Dashboard',
  MANAGE_JOBS_PAGE: 'ManageJobs',
  HBT_TAB: 'HandpickedByTuringTab',
};

export const LSA_DDP_PROFILE_VIEW_CAP = 25;
