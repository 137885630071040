export const ActionType = {
  REQUEST_COMPANY_ENGAGEMENTS_STARTED: 'REQUEST_COMPANY_ENGAGEMENTS_STARTED',
  REQUEST_COMPANY_ENGAGEMENTS_COMPLETED:
    'REQUEST_COMPANY_ENGAGEMENTS_COMPLETED',
  REQUEST_COMPANY_ENGAGEMENTS_FAILED: 'REQUEST_COMPANY_ENGAGEMENTS_FAILED',
  UPDATE_DATE_RANGE: 'UPDATE_DATE_RANGE',
  REQUEST_EXPORT_CSV_STARTED: 'REQUEST_EXPORT_CSV_STARTED',
  REQUEST_EXPORT_CSV_COMPLETED: 'REQUEST_EXPORT_CSV_COMPLETED',
  REQUEST_EXPORT_CSV_FAILED: 'REQUEST_EXPORT_CSV_FAILED',
  UPDATE_ACTIVE_PICKER: 'UPDATE_ACTIVE_PICKER',
};
