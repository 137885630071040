import React from 'react';
import { Input } from 'antd';
import { isValidBudget } from '../../../../app/job-creation/job-creation.util';
import { WORKING_HOURS_PER_MONTH } from '../../../../shared/constants';
import './style.scss';

export const CustomerBudget = ({
  budgetValue,
  handleBudgetValueChange,
  showError,
}) => {
  const handleBudgetChange = ev => {
    handleBudgetValueChange(ev.target.value);
  };

  const getHourlyRate = () => {
    if (!budgetValue || isNaN(budgetValue)) {
      return 'Will be calculated';
    }
    return '$ ' + Math.round(budgetValue / WORKING_HOURS_PER_MONTH);
  };

  return (
    <div className="customer-budget">
      <div className="selection-container">
        <div className="label">What is your monthly budget?</div>
        <Input
          value={budgetValue}
          onChange={handleBudgetChange}
          className={
            showError && !isValidBudget(budgetValue) ? 'error-border' : ''
          }
          placeholder="$ Budget / Month"
          data-cy="customer-budget"
        />
        <p className="helper-text">Hourly rate: {getHourlyRate()}</p>
        {showError && !isValidBudget(budgetValue) && (
          <div className="error-label">
            Please enter a valid value (min 100)
          </div>
        )}
      </div>
    </div>
  );
};
