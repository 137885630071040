import { RESPONSIVE_STATE } from '../shared/constants';

class ResponsiveService {
  constructor() {
    this.currentWindowWidth = window.innerWidth;
    this.currentState = RESPONSIVE_STATE.DEFAULT;
    this.stateResolutions = {};
  }

  init = () => {
    for (const [STATE, STATE_VALUE] of Object.entries(RESPONSIVE_STATE)) {
      const resolution = getComputedStyle(
        document.documentElement,
      ).getPropertyValue(`--${STATE_VALUE}`);
      this.stateResolutions[STATE] = parseInt(
        String(resolution).trim().replace('px', ''),
      );
    }
    window.addEventListener('resize', this.handleWindowResize);
  };

  destroy = () => {
    window.removeEventListener('resize', this.handleWindowResize);
  };

  handleWindowResize = () => {
    const innerWidth = window.innerWidth;
    this.currentWindowWidth = innerWidth;
    if (innerWidth <= this.stateResolutions.MOBILE)
      return this.setResponsiveState(RESPONSIVE_STATE.MOBILE);

    if (
      innerWidth > this.stateResolutions.MOBILE &&
      innerWidth <= this.stateResolutions.TABLET
    )
      return this.setResponsiveState(RESPONSIVE_STATE.TABLET);

    this.setResponsiveState(RESPONSIVE_STATE.DEFAULT);
  };

  setResponsiveState = state => {
    this.currentState = state;
  };

  getResponsiveState = () => {
    return this.currentState;
  };

  getCurrentWindowWidth = () => {
    return this.currentWindowWidth;
  };
}

export const responsiveService = new ResponsiveService();
