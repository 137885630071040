import produce from 'immer';
import { ActionType } from './handpicked-developers.types';

const initialState = {
  isFetching: false,
  handpickedDevelopers: {},
  handpickedDevelopersForDashboard: [],
};

export const handpickedDevelopersReducer = (state = initialState, action) => {
  return produce(state, draftState => {
    if (action.type === ActionType.GET_HANDPICKED_DEVELOPERS_STARTED) {
      draftState.isFetching = true;
    }
    if (action.type === ActionType.GET_HANDPICKED_DEVELOPERS) {
      const { allPackets } = action.payload;
      draftState.handpickedDevelopers = allPackets.reduce((acc, packet) => {
        acc[packet.jobId]
          ? acc[packet.jobId].push(packet)
          : (acc[packet.jobId] = [packet]);
        return acc;
      }, Object.assign({}));
    }
    if (action.type === ActionType.GET_HANDPICKED_DEVELOPERS_FOR_DASHBOARD) {
      const { allPackets } = action.payload;
      draftState.handpickedDevelopersForDashboard = allPackets;
    }
    if (action.type === ActionType.GET_HANDPICKED_DEVELOPERS_FINISHED) {
      draftState.isFetching = false;
    }
  });
};
