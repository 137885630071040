import produce from 'immer';
import { ActionType } from './native-book-a-call.types';

export interface NativeBookACallState {
  isModalOpen: boolean;
  modalSource: string;
  isLoading: boolean;
  isError: boolean;
  callBooked: boolean;
  calendlyLoaded: boolean;
}

const initialState: NativeBookACallState = {
  isModalOpen: false,
  modalSource: '',
  isLoading: false,
  isError: false,
  callBooked: false,
  calendlyLoaded: false,
};

export const nativeBookACallReducer = (
  state: NativeBookACallState = initialState,
  action,
) => {
  return produce(state, (draftState: NativeBookACallState) => {
    if (action.type === ActionType.CF_REGISTER_COMPLETE) {
      draftState.isLoading = false;
      draftState.isError = false;
    }

    if (action.type === ActionType.CF_REGISTER_FAIL) {
      draftState.isLoading = false;
      draftState.isError = true;
    }

    if (action.type === ActionType.CF_REGISTER_START) {
      draftState.isLoading = true;
    }

    if (action.type === ActionType.OPEN_MODAL) {
      draftState.isModalOpen = true;
      draftState.modalSource = action.payload.source;
    }
    if (action.type === ActionType.CLOSE_MODAL) {
      draftState.isModalOpen = false;
      draftState.modalSource = '';
    }

    if (action.type === ActionType.CALL_BOOKED) {
      draftState.callBooked = true;
    }

    if (action.type === ActionType.SET_CALENDLY_LOADED) {
      draftState.calendlyLoaded = true;
    }
  });
};
