import { notification } from 'antd';

export const ToastService = {
  success: (message, config = {}) => {
    notification.success({ message, ...config });
  },

  info: (message, config = {}) => {
    notification.info({ message, ...config });
  },

  error: (message, config = {}) => {
    notification.error({ message, ...config });
  },

  warn: (message, config = {}) => {
    notification.warn({ message, ...config });
  },
};
