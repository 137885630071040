import { Input } from 'antd';
import { isValidJobTitle } from '../../../../app/job-creation/job-creation.util';
import React, { useEffect } from 'react';
import './style.scss';

export const JobTitle = ({
  label,
  title,
  roleTypesList,
  roleType,
  owns,
  handleJobTitleChange,
  showError,
  isUserTitle,
}) => {
  const handleTitleChange = ev => {
    handleJobTitleChange(ev.target.value, true);
  };

  useEffect(() => {
    if (roleTypesList && roleType) {
      const preTitle = (() => {
        if (roleType.otherValue) {
          return roleType.otherValue;
        }
        if (roleType.id) {
          const roleTypeFound = roleTypesList.find(rt => rt.id === roleType.id);
          if (roleTypeFound) {
            return roleTypeFound.skillName;
          }
        }
        return '';
      })();
      const postTitle = (() => {
        switch (owns) {
          case 'tasks':
            return 'Mid-level Engineer';
          case 'features':
            return 'Senior Engineer';
          case 'projects':
            return 'Lead Engineer';
          default:
            return '';
        }
      })();
      if (!isUserTitle) {
        const computedJobTitle = `${preTitle}${
          postTitle ? ` - ${postTitle}` : ''
        }`;
        handleJobTitleChange(computedJobTitle);
      }
    }
  }, [roleTypesList, roleType, owns, handleJobTitleChange]);

  return (
    <div className="job-title">
      <div className="label">{label}</div>
      <Input
        data-cy="job-title-input"
        value={title}
        onChange={handleTitleChange}
        className={
          showError && !isValidJobTitle({ jobTitle: title })
            ? 'error-border'
            : ''
        }
      />
      {showError && !isValidJobTitle({ jobTitle: title }) && (
        <div className="error-label">Please enter a title</div>
      )}
    </div>
  );
};
