import Environment from '../../Enviroment';

export const parseMagicParamteresFromInvite = currentUser => {
  try {
    return JSON.parse(
      currentUser?.selfServeInvite?.magicLinkParameters ?? '{}',
    );
  } catch (e) {
    return {};
  }
};

export const getCustomerFlowLink = (
  currentUser,
  magicLinkParameters,
  jobs,
  isSSNative: boolean = false,
) => {
  const { s, n, email_uuid } = magicLinkParameters;
  const host = `${Environment.customerFlowOrigin}/hire/`;
  const customerFlowUrl = new URL(host);
  customerFlowUrl.searchParams.set('email', currentUser.email);
  customerFlowUrl.searchParams.set('name', currentUser.fullName);
  s && customerFlowUrl.searchParams.set('s', s);
  n && customerFlowUrl.searchParams.set('n', n);
  email_uuid && customerFlowUrl.searchParams.set('email_uuid', email_uuid);
  customerFlowUrl.searchParams.set('isFromSelfServe', (1).toString());
  const skillsId = jobs[0]?.skills ?? [];
  customerFlowUrl.searchParams.set('skills', skillsId.join(','));
  isSSNative && customerFlowUrl.searchParams.set('isSSNative', '1');

  return customerFlowUrl.toString();
};
