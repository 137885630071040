import { connect } from 'react-redux';
import { syncBudgetValue } from '../../../../app/job-creation/job-creation.actions';
import { CustomerBudget } from './customer-budget';

const mapStateToProps = state => {
  return {
    isStrictBudget: state.jobCreation.budget.isStrict,
    budgetValue: state.jobCreation.budget.value,
    showError: state.jobCreation.showValidationErrors,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleBudgetValueChange: value => {
      dispatch(syncBudgetValue(value));
    },
  };
};

const connected = connect(mapStateToProps, mapDispatchToProps)(CustomerBudget);
export { connected as CustomerBudgetContainer };
