export const AppFlags = {
  EnableGoogleCalendarForCustomers: 'enable-google-calendar-for-customers',
  EnableSearchDebug: 'enable-search-debugging',
  SelfServeJobPostOptions: 'self-serve-job-post-options',
  DeveloperServiceOrigin: 'developer-service-origin',
  EnableInviteCoworkersRates: 'enable-invite-coworker-rates',
  EnableInviteCoworkersHBT: 'enable-invite-coworker-hbt',
  EnableAutoPopulateFiltersFromJob: 'enable-autopopulate-filters-from-job',
  EnableSFSV2136EnableJobsHomepage: 'sfsv-2136-enable-jobs-homepage',
  EnableSFSV2135ShowCreateJobCtaOnJobsPage:
    'sfsv-2135-show-create-job-cta-on-jobs-page',
  EnableAfterJobEditRedirect: 'enable-after-job-edit-redirect',
  EnableMoreFiltersDefaultToOpen: 'enable-more-filters-default-to-open',
  EnableNativeBookingCallExperience: 'enable-native-call-booking',
  EnableJobFlowV2: 'enable-job-flow-v2',
  EnablePostmatch: 'enable-postmatch',
  SFSV2246EnableEditJobV2: 'sfsv-2246-enable-edit-job-v2',
  SFSV2273EnableSkillsDedup: 'sfsv-2273-enable-skills-dedup',
  SFSV2348EnableStarDestroyer: 'sfsv-2348-enable-star-destroyer',
  POST322EnableHoursBilledInSummary: 'post-322-enable-hours-billed-in-summary',
  POST323EnableHoursWorkedColumn: 'post-323-enable-hours-worked-column',
  SFSV2397EnableNewDeveloperHeader: 'sfsv-2397-enable-new-developer-header',
  POST471EnableTimesheets: 'post-471-enable-timesheets',
  POST488EnableSlackMessageForApproval:
    'post-488-enable-slack-message-for-approval',
  POST488EnableSlackMessageForRejection:
    'post-488-enable-slack-message-for-rejection',
  POST554DisableDDPPostmatchEngagementsPage:
    'post-554-disable-ddp-postmatch-engagements-page',
  POST555DisableDDPTimesheetsPage: 'post-555-disable-ddp-timesheets-page',
};

export let overrideAppFlags = {};

export const setOverrideAppFlags = (overrides = {}) => {
  overrideAppFlags = {
    ...overrideAppFlags,
    ...overrides,
  };
};

export const clearOverrideAppFlags = () => {
  overrideAppFlags = {};
};
