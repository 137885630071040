import { RequestService } from '../../services';
import { turingLogger } from '../../shared/TuringLogger';

export const logActivity = (
  category,
  action,
  payload = null,
  entityId = null,
  headers = null,
) => {
  return async (dispatch, getState) => {
    const state = getState();

    const limitedAccount = state.auth?.currentUser?.limitedAccount;
    const magicLinkParameters = limitedAccount
      ? getMagicLinkParameters(state.auth?.currentUser)
      : {};

    payload = {
      ...payload,
      ...(limitedAccount ? { limitedAccount: true, magicLinkParameters } : {}),
      bot: await turingLogger.isBot(),
    };

    try {
      await RequestService.post(
        'self-serve-user-activity',
        {
          category,
          action,
          payload,
          entityId,
        },
        headers,
      );
    } catch (e) {
      console.log(e);
    }
  };
};

const getMagicLinkParameters = currentUser => {
  try {
    return JSON.parse(
      currentUser?.selfServeInvite?.magicLinkParameters ?? '{}',
    );
  } catch (e) {
    return {};
  }
};
