import produce from 'immer';
import { ActionType } from './job-selection.types';

const initialState = {
  currentJobId: null,
  jobs: [],
};

// @Todo: Instead of naming it Job Selection it should be `jobs` since it's scope is increasing to more than just job selection and job related data is managed here as well
export const jobSelectionReducer = (state = initialState, action) => {
  return produce(state, draftState => {
    switch (action.type) {
      case ActionType.SET_SELECTED_JOB:
        draftState.currentJobId = action.payload.jobId;
        break;
      case ActionType.SET_ALL_JOBS:
        const { jobs } = action.payload;
        if (jobs.length) {
          if (
            jobs.find(
              job => !draftState.jobs.find(savedJob => savedJob.id === job.id),
            )
          ) {
            draftState.jobs = jobs;
            draftState.currentJobId = jobs[0].id;
          }
        }
        break;
      case ActionType.SET_JOB_LIST:
        const { selfServeJobs } = action.payload;
        draftState.jobs = state.jobs.map(job => {
          const jobDetails = selfServeJobs.find(
            selfServeJob => selfServeJob.job.id === job.id,
          );
          if (!jobDetails) return job;
          return {
            ...job,
            name: jobDetails.job.role,
            selfServeJobDetails: jobDetails.selfserveJobDetails,
            skills: jobDetails.selfserveJobDetails?.job?.requiredSkills,
            niceHaveTotalYearsOfExperience:
              jobDetails.job.niceHaveTotalYearsOfExperience,
            mustHaveTotalYearsOfExperience:
              jobDetails.job.mustHaveTotalYearsOfExperience,
            maxAcceptableRate: jobDetails.job.maxAcceptableRate,
            updatedDate: jobDetails.job.updatedDate,
            createdDate: jobDetails.job.createdDate,
          };
        });
        break;
      case ActionType.SET_NEW_JOB:
        draftState.jobs.unshift(action.payload.job);
        break;
      default:
        break;
    }
  });
};
