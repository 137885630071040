import produce from 'immer';
import { ActionType } from './flags';

const initialState = {
  flagMap: {},
  height: 0,
};

export const flagsReducer = (state = initialState, action) => {
  return produce(state, draftState => {
    switch (action.type) {
      case ActionType.SET_FLAGS:
        draftState.flagMap = { ...action.payload };
        draftState.height++;
        break;
      default:
        break;
    }
  });
};
