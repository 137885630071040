import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import { persistReducer } from 'redux-persist';
import hash from 'object-hash';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import { rootReducers } from './rootReducers';

const initialState = createStore(rootReducers).getState();
const version = hash(initialState);

const persistConfig = {
  key: 'root',
  migrate: async persistedState => {
    const persistedVersion = persistedState?._persist.version;
    const currentInitialStateMd5 = hash(initialState);

    if (persistedVersion !== currentInitialStateMd5) {
      return initialState;
    }

    return {
      ...persistedState,
      main: {
        ...persistedState.main,
        isFetching: false,
      },
    };
  },
  stateReconciler: autoMergeLevel2,
  storage,
  version,
  // at this stage persist only causes UX issues - we don't have clear requirement how it should work, (introduces a bug for seniority list)
  whitelist: [],
};

const persistedReducer = persistReducer(persistConfig, rootReducers);

const middleware = [thunk];

const composeEnhancers =
  (typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

export const configureStore = () => {
  return createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(...middleware)),
  );
};
