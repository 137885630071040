import { connect } from 'react-redux';
import { setJobCreationMode } from '../../../../app/job-creation/job-creation.actions';
import { PostJobButton } from './post-job';

const mapStateToProps = (state, props) => {
  return {
    jobLimit: state.main.jobLimit,
    buttonType: props.type || 'default',
    currentUser: state.auth.currentUser,
    flagMap: state.flags.flagMap,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setJobCreationMode: (source, isLimitedAccount, enableJobFlowV2) => {
      dispatch(setJobCreationMode(source, isLimitedAccount, enableJobFlowV2));
    },
  };
};

const connected = connect(mapStateToProps, mapDispatchToProps)(PostJobButton);
export { connected as PostJobButtonContainer };
