export const ActionType = {
  GET_SAVED_DEVELOPERS_DETAILED_STARTED:
    'GET_SAVED_DEVELOPERS_DETAILED_STARTED',
  GET_SAVED_DEVELOPERS_DETAILED: 'GET_SAVED_DEVELOPERS_DETAILED',
  GET_SAVED_DEVELOPERS_DETAILED_FINISHED:
    'GET_SAVED_DEVELOPERS_DETAILED_FINISHED',
  GET_SAVED_DEVELOPERS: 'GET_SAVED_DEVELOPERS',
  SAVE_DEVELOPER: 'SAVE_DEVELOPER',
  UPDATE_DEVELOPER_NOTE_STARTED: 'UPDATE_DEVELOPER_NOTE_STARTED',
  UPDATE_DEVELOPER_NOTE: 'UPDATE_DEVELOPER_NOTE',
  UPDATE_DEVELOPER_NOTE_FINISHED: 'UPDATE_DEVELOPER_NOTE_FINISHED',
  UNSAVE_DEVELOPER: 'UNSAVE_DEVELOPER',
  SET_TIMEZONES: 'SET_TIMEZONES',
};
