export const ActionType = {
  GET_HIDDEN_DEVELOPERS_STARTED: 'GET_HIDDEN_DEVELOPERS_STARTED',
  GET_HIDDEN_DEVELOPERS: 'GET_HIDDEN_DEVELOPERS',
  GET_HIDDEN_DEVELOPERS_FINISHED: 'GET_HIDDEN_DEVELOPERS_FINISHED',
  HIDE_DEVELOPER: 'HIDE_DEVELOPER',
  UNHIDE_DEVELOPER: 'UNHIDE_DEVELOPER',
  REMOVE_FROM_HIDDEN_DEVELOPERS: 'REMOVE_FROM_HIDDEN_DEVELOPERS',
  SET_TIMEZONES: 'SET_TIMEZONES',
};
export const RequestType = {};
