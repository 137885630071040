import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { PrivateRoute } from './PrivateRoute';
import { PublicOnlyRoute } from './PublicRoute';
import renderPublicLazyComponent from './renderPublicLazyComponent';
import {
  AccountSettings,
  Dashboard,
  DeepDeveloperProfilePage,
  EditJobSubpage,
  ForgotPassword,
  GoogleRedirect,
  JobInterviewsSubpageRedirect,
  JobsMainPage,
  JobsRouter,
  NewNavigationMainRedirect,
  NotFound,
  Postmatch,
  ResetPassword,
  SignIn,
  SignUp,
  Timesheets,
  TimeCardPage,
} from './lazyLoadedRoutes';

export const AppRouter = () => {
  useEffect(() => {
    setTimeout(() => {
      try {
        Promise.all([
          Postmatch.preload(),
          Timesheets.preload(),
          EditJobSubpage.preload(),
          DeepDeveloperProfilePage.preload(),
          Dashboard.preload(),
          ResetPassword.preload(),
          SignIn.preload(),
          SignUp.preload(),
          EditJobSubpage.preload(),
          ForgotPassword.preload(),
          JobInterviewsSubpageRedirect.preload(),
          JobsMainPage.preload(),
          JobsRouter.preload(),
          NewNavigationMainRedirect.preload(),
          NotFound.preload(),
          AccountSettings.preload(),
          TimeCardPage.preload(),
        ]);
      } catch (e) {}
    }, 2000);
  }, []);

  return (
    <Switch>
      <PublicOnlyRoute component={SignIn} exact path="/sign-in/:token?" />
      <PublicOnlyRoute
        component={SignUp}
        stopHomepageRedirect={true}
        exact
        path="/sign-up/:token?"
      />
      <Route
        component={renderPublicLazyComponent(GoogleRedirect)}
        exact
        path="/google-auth"
      />

      <Route
        component={renderPublicLazyComponent(DeepDeveloperProfilePage)}
        exact
        path="/developer-profile/:developerIdHash/:token?"
      />
      <Route
        component={renderPublicLazyComponent(NotFound)}
        exact
        path="/not-found"
      />
      <PublicOnlyRoute
        component={ForgotPassword}
        stopHomepageRedirect={true}
        exact
        path="/forgot-password"
      />
      <PublicOnlyRoute
        component={ResetPassword}
        stopHomepageRedirect={true}
        exact
        path="/reset-password"
      />

      <PrivateRoute
        component={JobInterviewsSubpageRedirect}
        path="/jobs/:jobID/interview"
      />
      <PrivateRoute component={EditJobSubpage} exact path="/jobs/:jobID/edit" />
      <PrivateRoute component={JobsRouter} path="/jobs/:jobID" />
      <PrivateRoute component={JobsMainPage} exact path="/jobs" />
      <PrivateRoute component={Dashboard} exact path="/dashboard" />
      <PrivateRoute
        component={AccountSettings}
        exact
        path="/account-settings"
      />
      <PrivateRoute component={Postmatch} exact path="/postmatch-engagements" />
      <PrivateRoute component={Timesheets} exact path="/timesheets" />
      <PrivateRoute
        component={TimeCardPage}
        path="/timesheets/time-card/:timeCardId"
      />
      <PublicOnlyRoute component={SignUp} path="/:token" />
      <PrivateRoute component={NewNavigationMainRedirect} exact path="/" />
    </Switch>
  );
};
