import { Input, Switch } from 'antd';
import React from 'react';
import { isValidBudget } from '../../../../app/job-creation/job-creation.util';
import { ExperienceSelector } from '../experience-selector/experience-selector';
import './style.scss';
import { WORKING_HOURS_PER_MONTH } from '../../../../shared/constants';
import { useSelector } from 'react-redux';

export const ExperienceAndBudget = ({
  minExperience,
  isStrictBudget,
  budgetValue,
  handleMinExperienceChange,
  handleStrictBudgetToggle,
  handleBudgetValueChange,
  showError,
  jobCreationId,
}) => {
  const currentUser = useSelector(state => state.auth.currentUser);

  const handleBudgetChange = ev => {
    handleBudgetValueChange(ev.target.value);
  };

  const getHourlyRate = () => {
    if (!budgetValue || isNaN(budgetValue)) {
      return 'Will be calculated';
    }
    return '$ ' + Math.round(budgetValue / WORKING_HOURS_PER_MONTH);
  };

  return (
    <div className="experience-and-budget">
      <ExperienceSelector
        experienceLabel="What is the minimum experience this developer should have?"
        experienceValue={minExperience}
        handleExperienceChange={handleMinExperienceChange}
      />
      {!currentUser?.limitedAccount ? (
        <div className="selection-container">
          <div className="label">
            Do you have a strict budget limit?
            <Switch
              key={jobCreationId}
              defaultChecked={isStrictBudget}
              onChange={handleStrictBudgetToggle}
            />
          </div>
          {isStrictBudget && (
            <>
              <Input
                value={budgetValue}
                onChange={handleBudgetChange}
                className={
                  showError && !isValidBudget(budgetValue) ? 'error-border' : ''
                }
                placeholder="$ Budget / Month"
              />
              <p className="helper-text">Hourly rate: {getHourlyRate()}</p>
              {showError && !isValidBudget(budgetValue) && (
                <div className="error-label">
                  Please enter a valid value (min 100)
                </div>
              )}
            </>
          )}
        </div>
      ) : null}
    </div>
  );
};
