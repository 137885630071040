import React from 'react';
import { action } from 'typesafe-actions';
import { v4 as uuidv4 } from 'uuid';
import * as _ from 'lodash';
import {
  ActionType,
  FilterFieldNames,
  LSA_DDP_PROFILE_VIEW_CAP,
} from './main.types';
import { RequestService, ToastService } from '../../services';
import { logActivity } from '../user-activity-trail/user-activity-trail.actions';
import { ActivityTypes } from '../user-activity-trail/user-activity-trail.types';
import { ActionType as JobCreationActionType } from '../job-creation/job-creation.types';
import { getCurrentUser, signOut } from '../auth/auth.actions';
import { message } from 'antd';
import {
  getPagePercentageViewed,
  setObjInSessionStorage,
} from '../../shared/utilties';
import { getReOrderedTimezonesByPopularity } from '../../components/util/timezone.util';
import { getSavedDevelopers } from '../saved-developers/saved-developers.actions';
import qs from 'qs';
import { history } from '../../store/history';
import { getInterviewRequestedDevelopers } from '../interview-requested-developers/interview-requested-developers.actions';
import momentTimezone from 'moment-timezone';
import moment from 'moment';

import {
  didFiltersChanged,
  filtersMeetMinimumSearchCriteria,
  generateFilterHash,
  getCleanFilters,
  getDefaultSidebarMoreFiltersStatusFromFilters,
  getQueryObjFromURL,
} from './main.util';

import {
  DDP_PROFILE_QUERY_KEY,
  MAX_RESULTS_TO_SHOW_PER_PAGE,
} from '../../shared/constants';
import { getContinents } from '../continents/continents.actions';
import { MainInitializationState } from './main.reducers';
import { closeBulkIRCalendar } from '../bulk-interviews/bulk-interviews.actions';
import { SelfServeUITabEnum } from '../auth/auth.constants';

export const getAllSkills = () => {
  return async dispatch => {
    try {
      const response = await RequestService.get('skills-public');
      dispatch(action(ActionType.GET_SKILLS, response.results));
      dispatch(
        action(
          JobCreationActionType.SET_REQUIRED_SKILLS_LIST,
          response.results,
        ),
      );
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(action(ActionType.SET_ARE_ALL_SKILLS_FETCHED, true));
    }
  };
};

export const getTimezones = () => {
  return async dispatch => {
    try {
      let timezones = await RequestService.get(
        'self-serve-interview-requests/timezones',
      );
      timezones = getReOrderedTimezonesByPopularity(timezones);
      dispatch(action(ActionType.SET_TIMEZONES, timezones));
    } catch (err) {
      ToastService.error('Error Occurred Fetching Data', err);
    }
  };
};

export const getPublicSenorities = () => {
  return async (dispatch, getState) => {
    const alreadyFetched = getState().main?.publicSeniorities?.length > 0;
    if (alreadyFetched) {
      return;
    }
    try {
      const response = await RequestService.get('seniority-public');
      dispatch(action(ActionType.GET_SENIORITY, response.results));
    } catch (e) {
      console.log(e);
    }
  };
};

export const clearRankingUuid = () => {
  return async dispatch => {
    dispatch(action(ActionType.CLEAR_RANKING_UUID));
  };
};

export const setOverrideFeatureFlag = overrideFeatureFlag => {
  return async dispatch => {
    dispatch(action(ActionType.SET_OVERRIDE_FEATURE_FLAG, overrideFeatureFlag));
  };
};

export const setSearchDebug = searchDebug => {
  return async (dispatch, getState) => {
    dispatch(action(ActionType.SET_SEARCH_DEBUG, searchDebug));
    if (searchDebug) {
      const { developers, searchDebugData } = getState().main;
      const searchDebugDataLength = searchDebugData?.feature_df
        ? Object.keys(searchDebugData.feature_df).length
        : 0;
      const totalFetchedDevs = developers.filter(dev => dev);
      if (searchDebugDataLength != totalFetchedDevs?.length) {
        await dispatch(getDevelopers({ pageNumber: 1 }));
        dispatch(action(ActionType.SET_PAGE_NUMBER, 1));
        dispatch(action(ActionType.NAVIGATE_IN_SEARCH_PAGE, 1));
      }
    }
  };
};

export const getDevelopers = ({ pageNumber = 1, rankingUuid = '' }) => {
  return async (dispatch, getState) => {
    const jobId = getState().jobSelection.currentJobId;
    const filterSkills = getState().main.filterSkills;
    const { searchDebug, overrideFeatureFlag } = getState().main;
    if (!filtersMeetMinimumSearchCriteria(filterSkills)) {
      return;
    }
    const timeStampBeforeRequest = Date.now();

    dispatch(action(ActionType.FETCH_DEVELOPERS_STARTED, {}));

    const uuid = uuidv4();
    const filterHashForThisRequest = generateFilterHash(filterSkills);

    const totalYOEFilters = {};
    if (filterSkills.totalYOEFrom > 0) {
      totalYOEFilters.minTotalYearsOfExperience = parseFloat(
        filterSkills.totalYOEFrom,
      );
    }
    if (filterSkills.totalYOETo > 0) {
      totalYOEFilters.maxTotalYearsOfExperience = parseFloat(
        filterSkills.totalYOETo,
      );
    }

    const filterVersion = 'V2-Revamped';
    const skillIds = (() => {
      if (Array.isArray(filterSkills.fixedSkillIds)) {
        return filterSkills.fixedSkillIds.filter(
          skillId => typeof skillId === 'number',
        );
      }
      return [];
    })();
    const resumeKeywords = filterSkills.resumeKeywords;
    const continentIds = filterSkills.continentIds;
    const monthlyBudget = +filterSkills.monthlyBudget;
    const queryPayload = {
      resumeKeywords,
      skillIds,
      continentIds,
      ...totalYOEFilters,
      ...(searchDebug ? { debug: searchDebug } : {}),
      ...(!_.isEmpty(overrideFeatureFlag)
        ? { featureFlags: overrideFeatureFlag }
        : {}),
      pageNumber,
      rankingUuid,
      filterVersion,
      monthlyBudget,
      jobId: +jobId,
    };
    dispatch(
      logActivity(
        ActivityTypes.QUERY.KEY,
        ActivityTypes.QUERY.ACTIONS.QUERY_TRIGGERED,
        {
          ...queryPayload,
          percentagePageViewed: getPagePercentageViewed(),
          jobId,
        },
        uuid,
      ),
    );

    dispatch(action(ActionType.SET_PAGE_NUMBER, pageNumber));
    try {
      const timeStampBeforeBERequest = Date.now();
      const {
        developers = [],
        totalMatched,
        customerCategory,
        rankingUuid,
        timeLogging = {},
        debug = null,
      } = await RequestService.post('self-serve/search', queryPayload);
      const timeStampAfterBERequest = Date.now();

      const currentFilterHash = getState().main.filterHash;

      if (filterHashForThisRequest === currentFilterHash) {
        dispatch(
          action(ActionType.GET_DEVELOPERS, {
            developers,
            totalMatched,
            pageNumber,
            customerCategory,
            rankingUuid,
            debug,
          }),
        );
        dispatch(action(ActionType.FETCH_DEVELOPERS_COMPLETED));
      }

      const responseSize = JSON.stringify({
        developers,
        totalMatched,
        customerCategory,
        rankingUuid,
      }).length;
      const developersForLog = developers?.map(({ userId }) => userId);
      const queryResultPayload = {
        resumeKeywords,
        skillIds,
        continentIds,
        monthlyBudget,
        ...totalYOEFilters,
        pageNumber,
        developers: developersForLog,
        rankingUuid,
        filterVersion,
        SearchBackend: timeLogging.SearchBackend,
        SelfServeDeveloperSearch: timeLogging.SelfServeDeveloperSearch,
        SelfServeUISearch: {
          startTime: timeStampBeforeRequest,
          endTime: Date.now(),
          timeStampBeforeBERequest,
          timeStampAfterBERequest,
          requestObject: queryPayload,
          responseSize,
        },
      };
      dispatch(
        logActivity(
          ActivityTypes.QUERY.KEY,
          ActivityTypes.QUERY.ACTIONS.QUERY_RESULT,
          {
            ...queryResultPayload,
            jobId,
          },
          uuid,
        ),
      );
    } catch (e) {
      dispatch(handleFetchError(e));
      const queryErrorPayload = {
        resumeKeywords,
        skillIds,
        continentIds,
        monthlyBudget,
        ...totalYOEFilters,
        pageNumber,
        SelfServeUISearch: {
          startTime: timeStampBeforeRequest,
          endTime: Date.now(),
          requestObject: queryPayload,
          responseSize: JSON.stringify(e).length,
          errorCode: e.statusCode,
          errorMessage: e.message,
        },
      };
      dispatch(action(ActionType.FETCH_DEVELOPERS_COMPLETED));
      dispatch(
        logActivity(
          ActivityTypes.QUERY.KEY,
          ActivityTypes.QUERY.ACTIONS.QUERY_ERROR,
          {
            ...queryErrorPayload,
            percentagePageViewed: getPagePercentageViewed(),
            jobId,
          },
          uuid,
        ),
      );
    }
  };
};

export const updateProfileViewCount = () => {
  return async (dispatch, getState) => {
    const { profileViewCount } = await RequestService.get(
      'self-serve-profile-views/count',
    );
    dispatch(action(ActionType.GET_PROFILE_VIEW_COUNT, { profileViewCount }));

    const { auth } = getState();
    const user = auth?.currentUser || {};
    const profileViewCap = user?.limitedAccount
      ? LSA_DDP_PROFILE_VIEW_CAP
      : user?.profileViewCap;

    if (!user.internalUser && profileViewCount >= profileViewCap) {
      dispatch(action(ActionType.SET_DDP_VIEW_LIMIT_REACHED));
    }
  };
};

export const setCurrentDeveloperIdHash = developerIdHash => {
  return dispatch => {
    dispatch(action(ActionType.SET_CURRENT_DEVELOPER_HASH, developerIdHash));
  };
};

export const setFilterSkills = filterSkills => {
  return dispatch => {
    dispatch(action(ActionType.SET_FILTER_SKILLS, filterSkills));
  };
};

export const inviteCoworker = ({ emails, jobIds, notes, canSeeRates }) => {
  return async (dispatch, getState) => {
    dispatch(action(ActionType.INVITE_COWORKER_STARTED));

    try {
      const success = await RequestService.post(
        `self-serve-invite/invite-coworker`,
        {
          emails,
          jobIds,
          notes,
          canSeeRates,
        },
      );

      if (!success) {
        ToastService.error('Error inviting coworker(s) ');
      } else {
        const successMessage = 'Coworker(s) invited successfully!';
        message.success({
          content: <>{successMessage}</>,
          duration: 5,
        });
      }
    } catch (error) {
      ToastService.error(error.message);
    }
    dispatch(action(ActionType.INVITE_COWORKER_COMPLETED));
  };
};

export const initiateCalendarComponent = () => {
  return (dispatch, getState) => {
    const state = getState();
    const interviewerEmails = localStorage.getItem('interviewers');
    const duration = localStorage.getItem('duration');
    const timezone = localStorage.getItem('timezone');

    const timezones = state.main.timezones;
    const timezoneName = timezones.find(
      element => element.key === (timezone ? Number(timezone) : null),
    )?.name;

    const developersSelected = state.bulkInterviews.developersSelected;
    const developers = developersSelected.map(
      ({ developerId, developerName, countryName, avatar }) => {
        return {
          id: developerId,
          name: developerName,
          location: countryName,
          ddpLink: '',
          profileImage: avatar,
          hourlyRate: 0,
          monthlyRate: 0,
        };
      },
    );

    let timezoneGuess = 'America/Los_Angeles';
    try {
      timezoneGuess = momentTimezone.tz.guess();
    } catch (e) {
      try {
        timezoneGuess = Intl.DateTimeFormat().resolvedOptions().timeZone;
      } catch (err) {}
    }

    dispatch(
      action(ActionType.INITIATE_CALENDAR_COMPONENT, {
        customerState: {
          selectedDate: undefined,
          developers,
          availabilityAlreadyConfirmed: false,
          minimumSlotCount: developers.length * 2,
          availability: {
            canEdit: true,
            timezone: timezoneName ?? timezoneGuess,
            timezoneId: timezone
              ? Number(timezone)
              : timezones.find(element => timezoneGuess)?.key,
            startTimes: [],
            interviewerEmails: interviewerEmails
              ? JSON.parse(interviewerEmails)
              : [state.auth.currentUser.email],
            duration: duration ? Number(duration) : 30,
          },
          notFound: false,
          packetId: '',
          fetchedOnce: false,
          requestOngoing: false,
          windowWidth: 1200,
          preferencesModalOpen: false,
          preferencesModalUpdate: false,
          allTimezones: timezones,
          source: 'self-serve',
          selfServeCalendarModalOpen: true,
          exitInterviewNudgeOpen: false,
        },
      }),
    );
  };
};

export const setCustomerStartTimes = availability => {
  return (dispatch, getState) => {
    const startTimes =
      getState().main.customerState?.availability?.startTimes ?? [];
    const newStartTimes = [];
    for (let i = 0; i < startTimes.length; i++) {
      const { startTime } = startTimes[i];
      let toDelete = false;
      for (let j = 0; j < availability.length; j++) {
        const av = availability[j];
        if (
          av.startTime.date() === startTime.date() &&
          av.startTime.month() === startTime.month() &&
          av.startTime.year() == startTime.year() &&
          av.startTime.hour() === startTime.hour() &&
          av.startTime.minute() === startTime.minute()
        ) {
          toDelete = true;
          break;
        }
      }

      if (!toDelete) {
        newStartTimes.push(startTime);
      }
    }

    dispatch(
      action(ActionType.SET_CUSTOMER_STARTTIMES, {
        availability: availability.concat(newStartTimes),
      }),
    );
  };
};

export const removeInterviewerEmail = email => {
  return async dispatch => {
    dispatch(action(ActionType.REMOVE_INTERVIEWER_EMAIL, { email }));
  };
};

export const addInterviewerEmail = email => {
  return async dispatch => {
    dispatch(action(ActionType.ADD_INTERVIEWER_EMAIL, { email }));
  };
};

export const timeSlotSelect = m => {
  return async dispatch => {
    dispatch(action(ActionType.ADD_START_TIME, { m }));
  };
};

export const selectDate = m => {
  return async dispatch => {
    dispatch(action(ActionType.SELECT_DATE, { m }));
  };
};

export const cancelPreferences = () => {
  return async dispatch => {
    dispatch(action(ActionType.CANCEL_PREFERENCES, {}));
    const preferencesModalShownOnce = localStorage.getItem(
      'preferencesModalShownOnce',
    );
    if (preferencesModalShownOnce !== 'yes') {
      dispatch(closeBulkIRCalendar());
    }
  };
};

export const confirmPreferences = () => {
  return async dispatch => {
    dispatch(action(ActionType.CONFIRM_PREFERENCES, {}));
  };
};

export const cancelCalendar = () => {
  return async dispatch => {
    dispatch(action(ActionType.CANCEL_CALENDAR, {}));
  };
};

export const exitInterviewRequest = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const packetIdHash = state.bulkInterviews.currentPacketHash;
    if (packetIdHash) {
      const logPayload = {
        jobId: state.jobSelection.currentJobId,
        user: state.auth.currentUser.id,
        packetIdHash,
        timestamp: Date.now(),
      };
      dispatch(
        logActivity(
          ActivityTypes.HANDPICKED_DEVELOPERS.KEY,
          ActivityTypes.HANDPICKED_DEVELOPERS.ACTIONS
            .CLICKED_INTERVIEW_REQUEST_AVAILABILITY_NOT_CONFIREMD,
          logPayload,
        ),
      );
    }
    dispatch(action(ActionType.EXIT_INTERVIEW_REQUEST, {}));
    dispatch(closeBulkIRCalendar());
    dispatch(
      logActivity(
        ActivityTypes.REQUEST_INTERVIEW.KEY,
        ActivityTypes.REQUEST_INTERVIEW.ACTIONS.EXIT_INTERVIEW_CLICKED,
        {},
      ),
    );
  };
};

export const continueInterviewRequest = () => {
  return async dispatch => {
    dispatch(action(ActionType.CONTINUE_INTERVIEW_REQUEST, {}));
    dispatch(
      logActivity(
        ActivityTypes.REQUEST_INTERVIEW.KEY,
        ActivityTypes.REQUEST_INTERVIEW.ACTIONS
          .CONTINUE_REQUEST_CLICKED_ON_EXIT_NUDGE,
        {},
      ),
    );
  };
};

export const timeSlotDeselectWithIndex = index => {
  return async dispatch => {
    dispatch(action(ActionType.TIME_SLOT_DESELECT, { index }));
  };
};

export const updatePreferences = () => {
  return async dispatch => {
    dispatch(action(ActionType.UPDATE_CALENDAR_PREFERENCES, {}));
  };
};
export const setCustomerTimezone = name => {
  return async dispatch => {
    dispatch(action(ActionType.SET_CUSTOMER_TIMEZONE, { name }));
  };
};

export const setDuration = selectedDuration => {
  return async dispatch => {
    dispatch(action(ActionType.SET_DURATION, { selectedDuration }));
  };
};

export const removeHiddenDeveloper = developerIdHash => {
  return async dispatch => {
    dispatch(action(ActionType.REMOVE_HIDDEN_DEVELOPER, { developerIdHash }));
  };
};

export const handleFetchError = error => {
  return async dispatch => {
    if ('statusCode' in error && error.statusCode === 429) {
      dispatch(action(ActionType.SET_DDP_VIEW_LIMIT_REACHED));
    }
    if ('statusCode' in error && error.statusCode === 401) {
      dispatch(signOut());
    }
    if ('statusCode' in error && error.statusCode === 500) {
      dispatch(
        action(ActionType.TOGGLE_SHOW_DISPLAY_FAILED_SEARCH_COMPONENT, true),
      );
    }
  };
};

export const logLoginOnProfileNotFound = () => {
  return async dispatch => {
    dispatch(
      logActivity(
        ActivityTypes.DEVELOPER_NOT_FOUND.KEY,
        ActivityTypes.DEVELOPER_NOT_FOUND.ACTIONS.LOGIN_CLICK,
      ),
    );
  };
};

export const logBrowseMoreOnProfileNotFound = () => {
  return async dispatch => {
    dispatch(
      logActivity(
        ActivityTypes.DEVELOPER_NOT_FOUND.KEY,
        ActivityTypes.DEVELOPER_NOT_FOUND.ACTIONS.BROWSE_MORE_DEVELOPERS_CLICK,
      ),
    );
  };
};

export const setSidebarMoreFiltersStatus = (newStatus = false) => {
  return dispatch => {
    dispatch(action(ActionType.SET_SIDEBAR_MORE_FILTERS_STATUS, { newStatus }));
  };
};

export const mergeNewFiltersInQuery = newFilters => {
  const { additionalParameters } = getCleanFilters(getQueryObjFromURL());
  const { filters: newFiltersCleaned } = getCleanFilters(newFilters);

  const search = filtersMeetMinimumSearchCriteria(newFiltersCleaned)
    ? `?${qs.stringify({
        ...additionalParameters,
        ...newFiltersCleaned,
      })}`
    : '';

  history.push({
    pathname: history.location.pathname,
    search,
  });
};

let filterGetDevelopersDebounceTimeout = null;

// Filters middleware for all components - please use this for any global filter hooks
export const applyFilters = (newFilters, force = false) => {
  return async (dispatch, getState) => {
    dispatch(
      action(ActionType.TOGGLE_SHOW_DISPLAY_FAILED_SEARCH_COMPONENT, false),
    );
    const oldFilters = getState().main.filterSkills;
    const { filters: updatedFilter } = getCleanFilters({
      ...oldFilters,
      ...newFilters,
    });
    const filtersChanged = didFiltersChanged(newFilters, oldFilters) || force;
    if (filtersChanged) {
      dispatch(action(ActionType.SET_FILTER_SKILLS, updatedFilter));
      dispatch(action(ActionType.NAVIGATE_IN_SEARCH_PAGE, 1));
      dispatch(action(ActionType.SET_SEARCH_DEBUG_DATA, {}));
      mergeNewFiltersInQuery(updatedFilter);
    } else {
      mergeNewFiltersInQuery(updatedFilter);
      return;
    }
    if (!filtersMeetMinimumSearchCriteria(updatedFilter)) {
      dispatch(action(ActionType.FETCH_DEVELOPERS_COMPLETED));
      dispatch(action(ActionType.RESET_TOTAL_MATCHED));
      return;
    }
    if (filterGetDevelopersDebounceTimeout) {
      clearTimeout(filterGetDevelopersDebounceTimeout);
    }
    filterGetDevelopersDebounceTimeout = setTimeout(() => {
      dispatch(getDevelopers({}));
    }, 500);
  };
};

export const initializeSearch = () => {
  return async (dispatch, getState) => {
    dispatch(action(ActionType.SEARCH_INITIALIZATION_STARTED));
    const { main, jobSelection } = getState();
    const { skillCategories, filterSkills, allSkills } = main;
    const { jobs, currentJobId } = jobSelection;
    const { flagMap } = getState().flags;
    const {
      enableAutoPopulateFiltersFromJob = false,
      enableMoreFiltersDefaultToOpen = false,
    } = flagMap || {};
    const currentJob = jobs.find(job => job.id === currentJobId);

    const { filters, additionalParameters } = getCleanFilters(
      getQueryObjFromURL(),
    );
    const isSearchURL = filtersMeetMinimumSearchCriteria(filters);
    const { devhash, jobId } = additionalParameters;
    if (devhash || jobId) {
      const ddpToShow = {
        devhash: devhash,
        jobId: +jobId,
      };
      // Instead of setting DDP Profile open directly from actions
      setObjInSessionStorage(DDP_PROFILE_QUERY_KEY, ddpToShow);
    }

    const currentFilters = (() => {
      if (currentJob) {
        const selfServeSkills = skillCategories.reduce(
          (acc, category) => [...acc, ...category.skills.map(({ id }) => id)],
          [],
        );
        return getJobFilterObjects(
          currentJob,
          filterSkills,
          selfServeSkills,
          allSkills,
          enableAutoPopulateFiltersFromJob,
        );
      }
      return filterSkills;
    })();

    const sidebarExpanded =
      enableMoreFiltersDefaultToOpen ||
      getDefaultSidebarMoreFiltersStatusFromFilters(currentFilters);
    dispatch(setSidebarMoreFiltersStatus(sidebarExpanded));
    dispatch(applyFilters(currentFilters, true));
    dispatch(action(ActionType.SEARCH_INITIALIZATION_COMPLETED));
  };
};

export const initializeUserProfile = () => {
  return async (dispatch, getState) => {
    const { main } = getState();
    if (main.initializationState === MainInitializationState.PENDING) {
      dispatch(action(ActionType.USER_PROFILE_INITIALIZATION_STARTED));
      await dispatch(getCurrentUser());

      const { auth } = getState();
      if (auth.currentUser?.uiTabs.includes(SelfServeUITabEnum.HIRING)) {
        await Promise.all([
          // public APIs that should be merged into single one
          dispatch(getTimezones()),
          dispatch(getPublicSenorities()),
          dispatch(getContinents()),

          // private APIs that should be part of current user profile loading
          // dispatch(initializeGrowthBook()),
          dispatch(getInterviewRequestedDevelopers()),
          dispatch(getSavedDevelopers()),

          // pure service method, no API call happening
          dispatch(clearRankingUuid()),
        ]);
      }

      await Promise.all([
        // public APIs that should be merged into single one
        dispatch(getAllSkills()),
      ]);
      dispatch(action(ActionType.USER_PROFILE_INITIALIZATION_COMPLETED));
    }
  };
};

export const getJobFilterObjects = (
  currentJob,
  filterSkills,
  includedSkills = [],
  allSkills = [],
  enableAutoPopulateFiltersFromJob = false,
) => {
  const fixedSkillIds =
    currentJob.skills && includedSkills
      ? currentJob.skills.filter(skill => includedSkills.includes(skill))
      : [];

  let totalYOEFrom = '';
  if (
    currentJob.mustHaveTotalYearsOfExperience == 3 ||
    currentJob.mustHaveTotalYearsOfExperience == 4
  )
    totalYOEFrom = 3;
  if (currentJob.mustHaveTotalYearsOfExperience >= 5) totalYOEFrom = 5;

  const jobFilterObject = {
    ...filterSkills,
    [FilterFieldNames.fixedSkillIds]: fixedSkillIds,
    [FilterFieldNames.totalYOEFrom]: totalYOEFrom,
    [FilterFieldNames.totalYOETo]: '',
    [FilterFieldNames.continentIds]: [],
    [FilterFieldNames.resumeKeywords]: [],
  };

  jobFilterObject[[FilterFieldNames.monthlyBudget]] = '';

  const otherSkillIds = currentJob.skills
    ? currentJob.skills.filter(skill => !fixedSkillIds.includes(skill))
    : [];
  const allSkillsMap = {};
  allSkills.forEach(skill => {
    if (!allSkillsMap[skill.id]) {
      allSkillsMap[skill.id] = {};
    }
    allSkillsMap[skill.id]['skillName'] = skill.skillName;
  });
  const resumeKeywords = otherSkillIds
    .map(skill => (allSkillsMap[skill] ? allSkillsMap[skill].skillName : ''))
    .filter(Boolean);
  jobFilterObject[FilterFieldNames.resumeKeywords] = resumeKeywords;

  if (
    enableAutoPopulateFiltersFromJob &&
    currentJob?.selfServeJobDetails?.locationRequired
  ) {
    jobFilterObject[FilterFieldNames.continentIds] =
      currentJob?.selfServeJobDetails?.continentIds || [];
  }

  return jobFilterObject;
};

export const navigateInSearchPage = (pageNumber, rankingUuid, developers) => {
  return async dispatch => {
    const firstDeveloperOnPageIndex =
      (pageNumber - 1) * MAX_RESULTS_TO_SHOW_PER_PAGE;
    if (!developers[firstDeveloperOnPageIndex]) {
      dispatch(
        getDevelopers({
          pageNumber,
          rankingUuid,
        }),
      );
    }
    dispatch(action(ActionType.NAVIGATE_IN_SEARCH_PAGE, pageNumber));
  };
};

export const toggleDisplaySearchComponent = toggleState => {
  return dispatch => {
    dispatch(
      action(
        ActionType.TOGGLE_SHOW_DISPLAY_FAILED_SEARCH_COMPONENT,
        toggleState,
      ),
    );
  };
};
export const setLimitedAccountPromptType = promptType => {
  return dispatch => {
    dispatch(
      logActivity(
        ActivityTypes.LIMITED_ACCOUNT.KEY,
        ActivityTypes.LIMITED_ACCOUNT.ACTIONS.LIMITED_ACCESS_MODAL_OPENED,
        {
          promptType,
        },
      ),
    );
    dispatch(action(ActionType.SET_LIMITED_ACCOUNT_PROMPT_TYPE, promptType));
  };
};

export const closeLimitedAccountPrompt = () => {
  return dispatch => {
    dispatch(action(ActionType.CLOSE_LIMITED_ACCOUNT_PROMPT, {}));
  };
};

export const toggleInviteCoworkerModal = (showModal, source) => {
  return dispatch => {
    dispatch(action(ActionType.TOGGLE_INVITE_COWORKER_MODAL, showModal));
    dispatch(
      action(ActionType.SET_INVITE_COWORKER_TOGGLE_TRIGGER_SOURCE, source),
    );
  };
};

export const connectGoogleCalendar = (email, fromPreference) => {
  return async dispatch => {
    try {
      localStorage.setItem(
        'google-calendar-redirect-path',
        window.location.pathname,
      );
      const response = await RequestService.get(
        `self-serve-interview-requests/get-google-auth-url?email=${email}`,
      );
      googleConnectClickLogger(fromPreference, email);
      dispatch(action(ActionType.CONNECT_GOOGLE_CALENDAR, response));
    } catch (e) {
      console.log(e);
    }
  };
};

export const revokeGoogleCalendar = email => {
  return async dispatch => {
    try {
      const response = await RequestService.delete(
        `self-serve-interview-requests/google-calendar-disconnect/${email}`,
      );
      dispatch(
        action(ActionType.DISCONNECT_GOOGLE_CALENDAR, { authStatus: false }),
      );
    } catch (e) {
      console.log(e);
    }
  };
};

export const getGoogleCalendarSlots = email => {
  return async dispatch => {
    try {
      const response = await RequestService.get(
        `self-serve-interview-requests/get-busy-slots?email=${email}`,
      );
      dispatch(action(ActionType.GET_CALENDAR_BUSY_SLOTS, response));
    } catch (e) {
      console.log(e);
    }
  };
};

export const customerSlotSelectTypeLogger = async (
  slotSelected,
  slotAsPerGC,
  email,
) => {
  try {
    const checkBusySlot = slot => {
      for (let index = 0; index < slotAsPerGC?.length; index++) {
        const time = slotAsPerGC[index];
        const isTimeMatching =
          slot.isSameOrAfter(time.start?.dateTime) &&
          slot.isBefore(time.end?.dateTime);
        if (isTimeMatching) {
          return true;
        }
      }
      return false;
    };

    const slotsWithType = slotSelected.map(slot => {
      const isSlotBusy = checkBusySlot(moment(slot));
      if (isSlotBusy) {
        return { slot: slot, type: 'busy' };
      }
      return { slot: slot, type: 'available' };
    });

    await RequestService.post(
      'self-serve-interview-requests/customer-slot-select-type-log',
      {
        email,
        slots: slotsWithType,
      },
    );
  } catch (e) {
    console.error(e);
  }
};

export const googleConnectClickLogger = async (fromPreference, email) => {
  try {
    await RequestService.get(
      'self-serve-interview-requests/customer-connect-action-log' +
        `?fromPreference=${fromPreference}` +
        `&email=${email}`,
    );
  } catch (e) {
    console.error(e);
  }
};
export const toggleShareDeveloperByEmailConfig = config => {
  return dispatch => {
    dispatch(action(ActionType.TOGGLE_SHARE_DEVELOPER_BY_EMAIL, config));
  };
};

export const shareDeveloperByEmail = (
  emails,
  jobIds,
  notes,
  developerHash,
  currentJobId,
) => {
  return async dispatch => {
    dispatch(action(ActionType.INVITE_COWORKER_STARTED));

    try {
      const success = await RequestService.post(
        `self-serve-invite/share-developer`,
        {
          emails,
          jobIds,
          notes,
          developerHash,
          currentJobId,
        },
      );

      if (!success) {
        ToastService.error('Error sharing developer');
      } else {
        const successMessage = 'Developer shared successfully!';
        message.success({
          content: <>{successMessage}</>,
          duration: 5,
        });
      }
    } catch (error) {
      ToastService.error(error.message);
    }
    dispatch(
      toggleShareDeveloperByEmailConfig({
        showShareDeveloperModal: false,
        developerToShare: null,
      }),
    );
    dispatch(action(ActionType.INVITE_COWORKER_COMPLETED));
  };
};

export const setDontShowFewerSkillsNudge = jobId => {
  return async dispatch => {
    dispatch(
      action(ActionType.SET_FEWER_SKILLS_FILTER_NUDGE_HIDE_FOR_JOB, { jobId }),
    );
  };
};
