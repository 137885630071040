import { locateService } from './util/locateService';
import Environment from '../Enviroment';

const segment = locateService('heap', {
  fallback: {
    identify(...args) {
      if (Environment.environment !== 'production') {
        console.log('Fake Segment Identify:', ...args);
      }
    },
    track(...args) {
      if (Environment.environment !== 'production') {
        console.log('Fake Segment Track:', ...args);
      }
    },
  },
});

class HeapService {
  static #identified = false;

  static async identify(userId) {
    if (this.#identified !== true && userId) {
      this.#identified = true;

      try {
        (await segment).identify(userId);
      } catch (e) {
        this.#identified = false;

        if (Environment.environment !== 'production') {
          throw e;
        }
      }
    }
  }

  static async track(eventName, eventProperties) {
    (await segment).track(eventName, eventProperties);
  }
}

export { HeapService };
