import { REQUEST_SOURCE } from '../app/user-activity-trail/user-activity-trail.types';
import qs from 'qs';
import { qsParsingOptions } from './qsDecoder.util';
import {
  DDP_PROFILE_QUERY_KEY,
  IR_NUDGE_DATA_KEY,
  IR_NUDGE_LIMIT,
  IR_NUDGE_TIME_LIMIT,
  DEVELOPER_SENIORITIES_MAP,
  EXPERIENCE_OPTIONS,
} from './constants';

export const selectFilterOption = (input, option) =>
  `${option.children}`.toLowerCase().indexOf(String(input).toLowerCase()) >= 0;

export const getShortName = name =>
  (name &&
    name
      .match(/\b(\w)/g)
      .join('')
      .toUpperCase()) ||
  '';

export const getPercentageHeight = element => {
  let b = document.body,
    h = element,
    st = 'scrollTop',
    sh = 'scrollHeight',
    ans = Math.round(
      ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100,
    );
  return isNaN(ans) ? 100 : ans;
};

export const getPercentageViewed = elementId => {
  let element = document.getElementsByClassName(elementId);
  return element.length > 0 ? getPercentageHeight(element[0]) : 0;
};

export const getPagePercentageViewed = () => {
  return document.scrollingElement
    ? getPercentageHeight(document.scrollingElement)
    : 0;
};

export const getInitials = name => {
  let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

  let initials = [...name.matchAll(rgx)] || [];

  initials = (
    (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
  ).toUpperCase();
  return initials;
};

export const getDeveloperCardV2ContainerWidth = (width, source) => {
  // SFSV-1985b: should we mantain this?
  if (source === REQUEST_SOURCE.RecommendedInMainPage) return width - 380;
  if (source === REQUEST_SOURCE.SearchCard) return width - 252;
  if (source === REQUEST_SOURCE.SavedCard) {
    return width >= 1398
      ? width - 348
      : width >= 1024
      ? width - 324
      : width >= 650
      ? width - 300
      : width;
  }
  return width;
};

export const getFormattedRate = rate => {
  if (!rate || (rate && !rate.toFixed)) {
    return 'Rate not confirmed';
  }
  return '$' + rate.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export const getURLParameters = (
  query,
  isUrlFormat = false,
  filteredParameters = [],
) => {
  let queryParams = {};
  for (let param of query) {
    if (filteredParameters.indexOf(param[0]) !== -1) continue;
    queryParams[param[0]] = param[1];
  }
  return isUrlFormat
    ? Object.entries(queryParams)
        .map(param => param.join('='))
        .join('&')
    : queryParams;
};

export const resumeKeywordsMatchers = (resumeKeywords, wordToMatch = '') => {
  return resumeKeywords.find(value =>
    wordToMatch.toLocaleLowerCase().includes(value.toLocaleLowerCase()),
  );
};

export const setObjInSessionStorage = (key, obj) => {
  if (obj && typeof obj === 'object') {
    window.sessionStorage.setItem(key, JSON.stringify(obj));
  }
};

export const unsetFromSessionStorage = key => {
  window.sessionStorage.removeItem(key);
};

export const getObjFromSessionStorage = key => {
  const value = window.sessionStorage.getItem(key);
  if (!value) {
    return null;
  }
  return JSON.parse(value);
};

export const checkQueryForDDPAction = search => {
  const queryParams = qs.parse(search.slice(1), qsParsingOptions);
  if (queryParams.devhash && queryParams.jobId) {
    setObjInSessionStorage(DDP_PROFILE_QUERY_KEY, {
      devhash: queryParams.devhash,
      jobId: +queryParams.jobId,
    });
  }
};

export const requiredIRCount = totalInterviewRequested =>
  totalInterviewRequested < IR_NUDGE_LIMIT
    ? IR_NUDGE_LIMIT - totalInterviewRequested
    : 0;

export const shouldShowIRNudgePanel = (jobId, totalInterviewRequested) => {
  const count = requiredIRCount(totalInterviewRequested);

  if (count === 0) {
    return false;
  }

  const today = new Date();
  let nudgePanelData = JSON.parse(localStorage.getItem(IR_NUDGE_DATA_KEY));

  if (nudgePanelData && nudgePanelData[jobId]) {
    const lastNudgeTime = nudgePanelData[jobId];

    const dayDifference =
      (today.getTime() - lastNudgeTime) / (1000 * 3600 * 24);

    if (dayDifference < IR_NUDGE_TIME_LIMIT) {
      return false;
    }
  }

  nudgePanelData = {
    ...nudgePanelData,
    [jobId]: today.getTime(),
  };
  localStorage.setItem(IR_NUDGE_DATA_KEY, JSON.stringify(nudgePanelData));

  return true;
};

export const removeItemFromLocalStorage = item => {
  localStorage.removeItem(item);
};

export const ssParametersToFilter = ['devhash', 'jobId'];
export const getRedirectUrl = (base = null, filteredParameters = []) => {
  const query = qs.parse(window.location.search.slice(1), qsParsingOptions);
  const queryParams = {};
  for (let param of Object.keys(query)) {
    if (filteredParameters.indexOf(param) !== -1) continue;
    queryParams[param] = query[param];
  }
  const search =
    Object.keys(queryParams).length > 0
      ? `?${Object.entries(queryParams)
          .map(param => param.join('='))
          .join('&')}`
      : '';
  return [base || '/', search].join('');
};
export const experienceRoundedValue = experienceInDecimal => {
  if (experienceInDecimal < 1) return 1;
  return Math.round(experienceInDecimal);
};

export const experienceCeilValue = experienceInDecimal =>
  Math.ceil(experienceInDecimal);

export const formatYearsOfExperience = yearsOfExperience => {
  const experience =
    yearsOfExperience > 0 ? experienceCeilValue(yearsOfExperience) : '< 1';
  const years = yearsOfExperience > 1 ? 'years' : 'year';
  return `${experience} ${years}`;
};

export const getDeveloperAvgSeniority = (seniorityRubrics = []) => {
  const seniorityTotal = seniorityRubrics.reduce(
    (senioritySum, category) => senioritySum + category.averageScore,
    0,
  );
  const avgSeniority = parseFloat((seniorityTotal / 5).toFixed(2));
  return Math.min(avgSeniority, 4.99);
};

export const getSeniorityLabel = (seniority, exp) => {
  if (seniority < 0) return { seniorityLabel: '', rubricLabelIndex: null };

  if (
    seniority >= DEVELOPER_SENIORITIES_MAP.techLead.score.min &&
    exp >= DEVELOPER_SENIORITIES_MAP.techLead.exp
  ) {
    return {
      seniorityLabel: DEVELOPER_SENIORITIES_MAP.techLead.label,
      rubricLabelIndex: DEVELOPER_SENIORITIES_MAP.techLead.rubricLabelIndex,
    };
  } else if (seniority >= DEVELOPER_SENIORITIES_MAP.leadDev.score.min) {
    return {
      seniorityLabel: DEVELOPER_SENIORITIES_MAP.leadDev.label,
      rubricLabelIndex: DEVELOPER_SENIORITIES_MAP.leadDev.rubricLabelIndex,
    };
  } else if (seniority >= DEVELOPER_SENIORITIES_MAP.seniorDev.score.min) {
    return {
      seniorityLabel: DEVELOPER_SENIORITIES_MAP.seniorDev.label,
      rubricLabelIndex: DEVELOPER_SENIORITIES_MAP.seniorDev.rubricLabelIndex,
    };
  } else {
    return {
      seniorityLabel: DEVELOPER_SENIORITIES_MAP.midDev.label,
      rubricLabelIndex: DEVELOPER_SENIORITIES_MAP.midDev.rubricLabelIndex,
    };
  }
};

export const getFormattedMinYearsOfExperience = value => {
  if (!value) return '';
  const expereicneOption = EXPERIENCE_OPTIONS.find(
    expOption => expOption.value.min === value,
  );
  return expereicneOption ? `Min exp: ${expereicneOption.text}` : '';
};

export const getFormattedMontlyBudget = value => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  });
  return value ? `Budget: ${formatter.format(value)}` : '';
};

export const isFormSubmitButtonToBeDisabled = ({
  getFieldsValue,
  getFieldsError,
}) => {
  const isTouched = Object.values(getFieldsValue()).every(value => !!value);
  const hasError = getFieldsError().some(({ errors }) => errors.length > 0);

  return !isTouched || hasError;
};

export const isResetPasswordFormSubmitButtonToBeDisabled = ({
  getFieldsValue,
  getFieldsError,
  getFieldValue,
}) => {
  if (isFormSubmitButtonToBeDisabled({ getFieldsValue, getFieldsError }))
    return true;

  if (getFieldValue('password')?.length > 0)
    return getFieldValue('password') !== getFieldValue('passwordConfirm');

  return true;
};

export const getCookieValues = (prefix, whitelist = []) => {
  if (!document || !document.cookie) return {};
  return document.cookie
    .split(';')
    .map(cookie => cookie.split('='))
    .filter(([name]) => name.indexOf(prefix) >= 0)
    .reduce((acc, [name, value]) => {
      const trimName = name.replace('_', '').trim();
      if (whitelist && whitelist.length) {
        if (whitelist.indexOf(trimName)) {
          acc[trimName] = value;
          return acc;
        }
        return acc;
      }
      acc[trimName] = value;
      return acc;
    }, {});
};

export const getFormattedRateFrequency = (rate, frequency = '') => {
  if (!rate || (rate && !rate.toFixed)) {
    return '';
  }
  return frequency;
};
