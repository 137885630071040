import { useEffect, useMemo } from 'react';
import { Modal } from 'antd';
import { NormalButton } from '../shared/buttons';
import {
  getCustomerFlowLink,
  parseMagicParamteresFromInvite,
} from '../book-a-call/book-a-call.util';
import { useDispatch } from 'react-redux';
import {
  closeNativeBookACallModal,
  getCFSessionId,
} from './native-book-a-call.actions';
import { useAppSelector } from '../../store/rootReducersType';
import { selectAllJobs } from '../../app/job-selection/jobs.selection';
import Calendly from '../calendly';
import { CalendlyProps, PageSettings } from '../calendly/calendly.types';
import styles from './native-book-a-call.module.css';
import { getFlagMap } from './native-book-a-call.selectors';
import { NativeBookACallTestConstants } from './native-book-a-call.types';

export const NativeBookACallModal = () => {
  const dispatch = useDispatch();

  const flagMap = useAppSelector(getFlagMap);

  const { enableNativeBookingCallExperience } = flagMap;

  const isModalOpen = useAppSelector(
    state => state.nativeBookACall.isModalOpen,
  );

  const jobs: any[] = useAppSelector(selectAllJobs);

  const currentUser = useAppSelector(state => state.auth.currentUser);

  const customerFlowLink = useMemo(() => {
    if (!currentUser) return '';

    const magicLinkParameters = parseMagicParamteresFromInvite(currentUser);
    return getCustomerFlowLink(currentUser, magicLinkParameters, jobs, true);
  }, [currentUser, jobs]);

  useEffect(() => {
    if (
      currentUser &&
      !currentUser?.cfSessionId &&
      enableNativeBookingCallExperience
    ) {
      dispatch(getCFSessionId(customerFlowLink));
    }
  }, [
    currentUser,
    currentUser?.cfSessionId,
    enableNativeBookingCallExperience,
  ]);

  const prefill: CalendlyProps['prefill'] = useMemo(() => {
    const prefillObject: CalendlyProps['prefill'] = {};
    if (currentUser?.email) {
      prefillObject.email = currentUser.email;
    }
    if (currentUser?.fullName) {
      prefillObject.name = currentUser.fullName;
    }

    if (currentUser?.cfSessionId) {
      prefillObject.utm = {
        utmSource: currentUser?.cfSessionId,
      };
    }

    return prefillObject;
  }, [currentUser?.cfSessionId, currentUser]);

  const calendlyPageSettings: PageSettings = {
    hideGdprBanner: true,
    phoneNumber: undefined,
  };

  const closeModal = () => {
    dispatch(closeNativeBookACallModal());
  };

  if (!enableNativeBookingCallExperience || !isModalOpen) {
    return (
      <div
        data-testid={`${NativeBookACallTestConstants.noNativeModalContainerId}`}
      ></div>
    );
  }

  return (
    <Modal
      mask={true}
      onCancel={closeModal}
      title=""
      centered={true}
      closable={false}
      footer={null}
      maskClosable={false}
      maskStyle={{ zIndex: '1100' }}
      width={600}
      wrapClassName="native-call-booking-modal-container"
      open={true}
      data-testid={`${NativeBookACallTestConstants.nativeModalContainerId}`}
      data-customerflowlink={`${customerFlowLink}`}
      data-cfsessionid={`${currentUser?.cfSessionId}`}
    >
      <>
        <div className={styles['native-call-container']}>
          <Calendly prefill={prefill} pageSettings={calendlyPageSettings} />
        </div>
        <div className={styles['native-call-footer']}>
          <div style={{ marginRight: '8px' }}>
            <NormalButton
              text="Cancel"
              onClick={closeModal}
              data-testid={`${NativeBookACallTestConstants.closeNativeModalBtn}`}
            />
          </div>
        </div>
      </>
    </Modal>
  );
};
