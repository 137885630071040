export const ActionType = {
  GET_HANDPICKED_DEVELOPERS_STARTED: 'GET_HANDPICKED_DEVELOPERS_STARTED',
  GET_HANDPICKED_DEVELOPERS_FINISHED: 'GET_HANDPICKED_DEVELOPERS_FINISHED',
  GET_HANDPICKED_DEVELOPERS: 'GET_HANDPICKED_DEVELOPERS',
  GET_HANDPICKED_DEVELOPERS_FOR_DASHBOARD:
    'GET_HANDPICKED_DEVELOPERS_FOR_DASHBOARD',
};

export enum PacketStatus {
  OPENED = 'opened',
  CONFIRMED = 'confirmed',
  INTERVIEW_SCHEDULED = 'interview-scheduled',
  REJECTED = 'rejected',
  DRAFT = 'draft',
  SENT = 'sent',
  READY = 'ready',
  EMAIL_OPENED = 'email-opened',
}

export const INTERACTIVE_PACKET_SELECTION_ACTIONS = {
  CONFIRM: 'confirm',
  REJECT: 'reject',
};
