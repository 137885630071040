import React, { useRef, useState } from 'react';
import { Spin } from 'antd';
import {
  isCalendlyEvent,
  isEventScheduledCalendlyEvent,
  useScript,
  withPageSettings,
} from './calendly.util';

import Classes from './calendly.module.scss';
import { CalendlyProps, CalendlyTestConstants } from './calendly.types';
import { useDispatch } from 'react-redux';
import {
  closeNativeBookACallModal,
  logNativeCallBooked,
  setCalendlyLoadedStatus,
  setCallBookedStatus,
} from '../native-book-a-call/native-book-a-call.actions';
import Environment from '../../Enviroment';
import { useAppSelector } from '../../store/rootReducersType';

export function Calendly({
  prefill = {},
  pageSettings,
}: CalendlyProps): JSX.Element {
  const eventString = `${Environment.calendlyUsername}/${Environment.calendlyEventName}`;
  const [uiReady, setUiReady] = useState(false);
  const calendlyLoaded = useAppSelector(
    state => state.nativeBookACall.calendlyLoaded,
  );
  const dispatch = useDispatch();

  const containerRef = useRef<HTMLDivElement>(null);

  useScript({
    src: 'https://assets.calendly.com/assets/external/widget.js',
    onLoad: async () => {
      if (!containerRef.current) {
        throw new Error('Instantiate an element to attach widget to');
      }
      window.addEventListener('message', async function (event) {
        if (isCalendlyEvent && !uiReady) setUiReady(true);
        if (isCalendlyEvent(event) && isEventScheduledCalendlyEvent(event)) {
          dispatch(setCallBookedStatus());
          setTimeout(() => {
            dispatch(closeNativeBookACallModal());
          }, 4000);
          dispatch(logNativeCallBooked({ event, prefill }));
        }
      });
      window.Calendly.initInlineWidget({
        url: withPageSettings(
          new URL(eventString, 'https://calendly.com/'),
          pageSettings,
        ),
        parentElement: containerRef.current,
        prefill,
        utm: prefill?.utm,
      });

      dispatch(setCalendlyLoadedStatus());
    },
  });

  return (
    <>
      <div
        className={Classes.containerLoading}
        style={{ display: uiReady ? 'none' : 'flex' }}
      >
        <Spin />
      </div>
      <div
        className={Classes.container}
        ref={containerRef}
        data-testid={`${CalendlyTestConstants.calendlyContainerId}`}
        data-calendlyLoaded={calendlyLoaded}
      />
    </>
  );
}

export default Calendly;
