import styles from './buttons.module.css';

export const PrimaryButton = ({
  text,
  onClick,
}: {
  text: string;
  onClick?: any;
}) => {
  return (
    <button onClick={onClick} className={styles['primary-button']}>
      {text}
    </button>
  );
};

export const NormalButton = ({
  text,
  onClick,
  ...rest
}: {
  text: string;
  onClick?: any;
}) => {
  return (
    <button onClick={onClick} className={styles['normal-button']} {...rest}>
      {text}
    </button>
  );
};
