import produce from 'immer';
import {
  jobCreationSkills,
  jobCreationStatus,
  otherRequiredSkill,
  roleTypeIdMap,
} from './job-creation.constants';
import { ActionType } from './job-creation.types';
import { WORKING_HOURS_PER_MONTH } from '../../shared/constants';
import isNull from 'lodash/isNull';

const initialState = {
  enableJobCreation: false,
  enableJobEdit: false,
  jobTitle: '',
  roleType: {
    id: '',
    otherValue: '',
  },
  requiredSkills: [],
  developerOwns: '',
  minExperience: '2',
  budget: {
    isStrict: false,
    value: '',
  },
  location: {
    isStrict: false,
    value: [],
  },
  currentPage: 1,
  lastPage: 3,
  roleTypesList: [],
  requiredSkillsList: [],
  additionalPreference: {
    skills: [],
    numPositions: '1',
    preference: '',
  },
  jobStatus: jobCreationStatus.INITIAL,
  id: null,
  postJobButtonSource: 'TOP_NAV',
  showValidationErrors: false,
  jobId: null,
  isUserTitle: false,
  createdDate: null,
  hoursOverlap: '4',
  optionalSkills: [],
  numberOfOpenRoles: '1',
  notes: '',
};

export const jobCreationReducer = (state = initialState, action) => {
  return produce(state, draftState => {
    switch (action.type) {
      case ActionType.ENABLE_JOB_CREATION_MODAL:
        draftState.enableJobCreation = true;
        draftState.postJobButtonSource = action.payload.source;
        draftState.showValidationErrors = false;
        draftState.currentPage = 1;
        draftState.jobTitle = '';
        draftState.roleType.id = '';
        draftState.roleType.otherValue = '';
        draftState.requiredSkills = [];
        draftState.developerOwns = '';
        draftState.minExperience = '2';
        draftState.budget.isStrict = action.payload.enableJobFlowV2
          ? true
          : false;
        draftState.budget.value = '';
        draftState.location.isStrict = false;
        draftState.location.value = '';
        draftState.additionalPreference.skills = [];
        draftState.additionalPreference.numPositions = '1';
        draftState.additionalPreference.preference = '';
        draftState.jobStatus = jobCreationStatus.INITIAL;
        draftState.id = null;
        draftState.showValidationErrors = false;
        draftState.lastPage =
          action.payload.isLimitedAccount && !action.payload.enableJobFlowV2
            ? 2
            : 3;
        draftState.isUserTitle = false;
        draftState.createdDate = null;
        draftState.hoursOverlap = '4';
        draftState.optionalSkills = [];
        draftState.numberOfOpenRoles = '1';
        draftState.notes = '';
        break;
      case ActionType.DISABLE_JOB_CREATION_MODAL:
      case ActionType.DISABLE_JOB_EDIT_MODE:
      case ActionType.EDIT_JOB_SUCCESS:
      case ActionType.SAVE_ADDITIONAL_PREFERENCE_COMPLETED:
        draftState.jobTitle = '';
        draftState.roleType.id = '';
        draftState.roleType.otherValue = '';
        draftState.requiredSkills = [];
        draftState.developerOwns = '';
        draftState.minExperience = '2';
        draftState.budget.isStrict = false;
        draftState.budget.value = '';
        draftState.location.isStrict = false;
        draftState.location.value = '';
        draftState.additionalPreference.skills = [];
        draftState.additionalPreference.numPositions = '1';
        draftState.additionalPreference.preference = '';
        draftState.jobStatus = jobCreationStatus.INITIAL;
        draftState.enableJobEdit = false;
        draftState.enableJobCreation = false;
        draftState.id = null;
        draftState.currentPage = 1;
        draftState.showValidationErrors = false;
        draftState.jobId = null;
        draftState.isUserTitle = false;
        draftState.createdDate = null;
        draftState.hoursOverlap = '4';
        draftState.optionalSkills = [];
        draftState.numberOfOpenRoles = '1';
        draftState.notes = '';
        break;
      case ActionType.NAVIGATE_NEXT:
        draftState.currentPage += 1;
        break;
      case ActionType.NAVIGATE_BACK:
        draftState.currentPage -= 1;
        break;
      case ActionType.SET_JOB_TITLE:
        draftState.jobTitle = action.payload.title;
        draftState.isUserTitle = action.payload.isUserTitle;
        draftState.showValidationErrors = false;
        break;
      case ActionType.SET_DEVELOPER_OWNS:
        draftState.developerOwns = action.payload;
        draftState.showValidationErrors = false;
        break;
      case ActionType.SET_MIN_EXPERIENCE:
        draftState.minExperience = action.payload;
        break;
      case ActionType.SET_STRICT_BUDGET:
        draftState.budget.isStrict = action.payload;
        break;
      case ActionType.SET_BUDGET_VALUE:
        draftState.budget.value = action.payload;
        draftState.showValidationErrors = false;
        break;
      case ActionType.SET_STRICT_LOCATION:
        draftState.location.isStrict = action.payload;
        break;
      case ActionType.SET_LOCATION_VALUE:
        draftState.location.value = action.payload;
        draftState.showValidationErrors = false;
        break;
      case ActionType.SET_JOB_ROLE_TYPES:
        draftState.roleTypesList = action.payload.map(option => {
          return { id: option.id, skillName: option.roleType };
        });
        break;
      case ActionType.SET_ROLE_TYPE:
        draftState.roleType.id = action.payload.pop();
        draftState.roleType.otherValue = '';
        draftState.showValidationErrors = false;
        break;
      case ActionType.SET_OTHER_ROLE_TYPE:
        draftState.roleType.otherValue =
          draftState.roleType.id === roleTypeIdMap.OTHER ? action.payload : '';
        draftState.showValidationErrors = false;
        break;
      case ActionType.SET_REQUIRED_SKILLS:
        draftState.requiredSkills = action.payload;
        draftState.showValidationErrors = false;
        break;
      case ActionType.SET_REQUIRED_SKILLS_LIST:
        const allSkills = action.payload;
        draftState.requiredSkillsList = [
          ...allSkills
            .filter(skill =>
              jobCreationSkills.includes(skill.skillName.toLowerCase()),
            )
            .sort(
              (a, b) =>
                jobCreationSkills.indexOf(a.skillName.toLowerCase()) -
                jobCreationSkills.indexOf(b.skillName.toLowerCase()),
            ),
          otherRequiredSkill,
        ];
        break;
      case ActionType.SET_ADDITIONAL_PREFERENCE_SKILLS:
        draftState.additionalPreference.skills = action.payload;
        draftState.showValidationErrors = false;
        break;
      case ActionType.SET_ADDITIONAL_PREFERENCE_POSITIONS:
        draftState.additionalPreference.numPositions = action.payload;
        break;
      case ActionType.SET_ADDITIONAL_PREFERENCE_TEXT:
        draftState.additionalPreference.preference = action.payload;
        draftState.showValidationErrors = false;
        break;
      case ActionType.CREATE_NEW_JOB:
      case ActionType.SAVE_ADDITIONAL_PREFERENCE_STARTED:
        draftState.jobStatus = jobCreationStatus.IN_PROGRESS;
        break;
      case ActionType.CREATE_NEW_JOB_SUCCESS:
        draftState.jobStatus = jobCreationStatus.SUCCESS;
        draftState.id = action.payload.id;
        draftState.currentPage = -1;
        draftState.jobId = action.payload.job.id;
        break;
      case ActionType.CREATE_NEW_JOB_FAILURE:
        draftState.jobStatus = jobCreationStatus.FAILURE;
        break;
      case ActionType.EDIT_JOB:
        draftState.jobStatus = jobCreationStatus.IN_PROGRESS;
        break;
      case ActionType.EDIT_JOB_FAILURE:
        draftState.jobStatus = jobCreationStatus.FAILURE;
        break;
      case ActionType.ENABLE_JOB_EDIT_MODE:
        draftState.enableJobEdit = true;

        const { jobToEdit, isNewJobRatesEnable, sfsv2246EnableEditJobV2 } =
          action.payload;
        const requiredSkillsListIds = draftState.requiredSkillsList.map(
          skill => skill.id,
        );
        const isStrictBudget = Boolean(
          isNewJobRatesEnable
            ? jobToEdit.job.jobDetail?.customerBudget
            : jobToEdit.job.maxAcceptableRate,
        );

        draftState.id = jobToEdit.id;
        draftState.jobTitle = jobToEdit.job.role;
        draftState.roleType.id = jobToEdit.job.roleTypeId;
        draftState.roleType.otherValue = jobToEdit.otherRole;
        draftState.requiredSkills =
          jobToEdit.job.requiredSkills.filter(
            req => requiredSkillsListIds.indexOf(req) === -1,
          ).length > 0
            ? [...jobToEdit.job.requiredSkills, -1]
            : jobToEdit.job.requiredSkills;
        draftState.developerOwns = jobToEdit.seniorityLevel;
        draftState.minExperience = isNull(
          jobToEdit.job.mustHaveTotalYearsOfExperience,
        )
          ? -1
          : jobToEdit.job.mustHaveTotalYearsOfExperience;
        draftState.budget.isStrict = sfsv2246EnableEditJobV2
          ? sfsv2246EnableEditJobV2
          : isStrictBudget;
        draftState.budget.value = isNewJobRatesEnable
          ? jobToEdit.job.jobDetail?.customerBudget
          : jobToEdit.job.maxAcceptableRate * WORKING_HOURS_PER_MONTH;
        draftState.location.isStrict = jobToEdit.locationRequired;
        draftState.location.value = jobToEdit.continentIds;
        draftState.additionalPreference.skills =
          jobToEdit.job.optionalSkills.filter(
            req => requiredSkillsListIds.indexOf(req) === -1,
          ).length > 0
            ? [...jobToEdit.job.optionalSkills, -1]
            : jobToEdit.job.optionalSkills;
        draftState.additionalPreference.numPositions =
          jobToEdit.job.numberOfOpenRoles || 1;
        draftState.additionalPreference.preference = jobToEdit.job.notes;
        draftState.postJobButtonSource = 'MANAGE_JOB_PAGE';
        draftState.showValidationErrors = false;
        draftState.isUserTitle = true;
        draftState.createdDate = jobToEdit.job.createdDate;
        draftState.hoursOverlap = jobToEdit.job.jobDetail?.hoursOverlap || 4;
        break;
      case ActionType.SET_SHOW_ERRORS:
        draftState.showValidationErrors = true;
        break;
      case ActionType.DUPLICATE_JOB:
        draftState.enableJobEdit = false;

        const { jobToDuplicate } = action.payload;
        const requiredSkillsLists = draftState.requiredSkillsList.map(
          skill => skill.id,
        );

        draftState.id = null;
        draftState.jobTitle = jobToDuplicate.job.role;
        draftState.roleType.id = jobToDuplicate.job.roleTypeId;
        draftState.roleType.otherValue = jobToDuplicate.otherRole;
        draftState.requiredSkills =
          jobToDuplicate.job.requiredSkills.filter(
            req => requiredSkillsLists.indexOf(req) === -1,
          ).length > 0
            ? [...jobToDuplicate.job.requiredSkills, -1]
            : jobToDuplicate.job.requiredSkills;
        draftState.developerOwns = jobToDuplicate.seniorityLevel;
        draftState.minExperience =
          jobToDuplicate.job.mustHaveTotalYearsOfExperience || '2';
        draftState.budget.isStrict = jobToDuplicate.job.maxAcceptableRate
          ? true
          : false;
        draftState.budget.value = jobToDuplicate.job.maxAcceptableRate;
        draftState.location.isStrict = jobToDuplicate.locationRequired;
        draftState.location.value = jobToDuplicate.continentIds;
        draftState.additionalPreference.skills =
          jobToDuplicate.job.optionalSkills.filter(
            req => requiredSkillsLists.indexOf(req) === -1,
          ).length > 0
            ? [...jobToDuplicate.job.optionalSkills, -1]
            : jobToDuplicate.job.optionalSkills;
        draftState.additionalPreference.numPositions =
          jobToDuplicate.job.numberOfOpenRoles || '';
        draftState.additionalPreference.preference = jobToDuplicate.job.notes;
        draftState.postJobButtonSource = 'DUPLICATE_JOB';
        draftState.showValidationErrors = false;
        draftState.enableJobCreation = true;
        draftState.isUserTitle = false;
        draftState.createdDate = null;
        break;
      case ActionType.SET_OPTIONAL_SKILLS:
        draftState.optionalSkills = action.payload;
        draftState.showValidationErrors = false;
      case ActionType.SET_HOURS_OVERLAP:
        draftState.hoursOverlap = action.payload;
        draftState.showValidationErrors = false;
      default:
        break;
    }
  });
};
