import produce from 'immer';
import moment from 'moment';
import { ActionType } from './bulk-interviews.types';

const initialState: {
  requestSavedSugestionModalOpen: boolean;
  savedSuggestionModalDevelopers: Array<{
    developerId: number;
    avatar: string;
    countryName: string;
    hourlyRate: number;
    name: string;
    userHash: string;
    monthlyRate: number;
  }>;
  savedSuggestionIROnGoing: boolean;
  developersSelected: Array<{
    developerId: number;
    userHash: string;
    developerName: string;
    countryName: string;
    avatar: string;
  }>;
  bulkIRCalendarOpen: boolean;
  source: string;
  interviewReminderModalOpen: boolean;
  interviewReminderModalRequestedCount: number;
  dontRemindJobs: Array<number>;
  currentJobIdForIR: number;
  savedSuggestionsModalDeveloperNames: string;
  customerAvailabilityJobMap: {
    [jobId: number]: {
      availability: {
        isAvailable: boolean;
        startTime: moment.Moment;
      };
      developers: Array<{
        id: number;
        name: string;
        location: string;
        ddpLink: string;
        profileImage: string;
        hourlyRate: number;
        monthlyRate: number;
      }>;
    };
  };
  packetInterviewsNudge: boolean;
  currentPacketHash?: string;
  rejectPacketDeveloperModal: boolean;
  developerForRejectedModal?: any;
  packetDeveloperRejectOrConfirmOngoing: boolean;
  packetDeveloperRejectionReasons?: Array<{ systemName: string; name: string }>;
  developerPackets?: Array<any>;
  feedbackCollectedDevelopers: Array<number>;
} = {
  requestSavedSugestionModalOpen: false,
  savedSuggestionModalDevelopers: [],
  savedSuggestionIROnGoing: false,
  developersSelected: [],
  bulkIRCalendarOpen: false,
  source: '',
  interviewReminderModalOpen: false,
  interviewReminderModalRequestedCount: 0,
  dontRemindJobs: [],
  currentJobIdForIR: 0,
  savedSuggestionsModalDeveloperNames: '',
  customerAvailabilityJobMap: {},
  packetInterviewsNudge: false,
  rejectPacketDeveloperModal: false,
  packetDeveloperRejectOrConfirmOngoing: false,
  feedbackCollectedDevelopers: [],
};

export const bulkInterviewsReducer = (state = initialState, action: any) => {
  return produce(state, draftState => {
    if (action.type === ActionType.SHOW_SAVED_SUGGESTION_MODAL) {
      draftState.developersSelected = action.payload.developersSelected;
      draftState.requestSavedSugestionModalOpen = true;
      draftState.savedSuggestionModalDevelopers =
        action.payload.savedSuggestionModalDevelopers;
      draftState.savedSuggestionsModalDeveloperNames =
        action.payload.savedSuggestionsModalDeveloperNames;
    }

    if (
      action.type === ActionType.BULK_IR_STARTED_FROM_SAVED_SUGGESTION_MODAL
    ) {
      draftState.savedSuggestionIROnGoing = true;
      draftState.requestSavedSugestionModalOpen = false;
    }

    if (action.type === ActionType.SET_CUSTOMER_AVAILABILITY) {
      draftState.customerAvailabilityJobMap[action.payload.jobId] = {
        availability: action.payload.availability,
        developers: action.payload.developers,
      };
    }

    if (action.type === ActionType.OPEN_PACKET_NUDGE) {
      draftState.packetInterviewsNudge = true;
      draftState.currentPacketHash = action.payload.packetHash;
    }

    if (action.type === ActionType.CLOSE_PACKET_NUDGE) {
      draftState.packetInterviewsNudge = false;
      draftState.currentPacketHash = null;
    }

    if (action.type === ActionType.INTERACTIVE_PACKET_ACTION_REQUEST_STARTED) {
      draftState.packetDeveloperRejectOrConfirmOngoing = true;
    }

    if (action.type === ActionType.ADD_DEVELOPER_TO_FEEDBACK_COLLECTED_LIST) {
      draftState.feedbackCollectedDevelopers = [
        ...draftState.feedbackCollectedDevelopers,
        action.payload.developerId,
      ];
    }

    if (action.type === ActionType.INTERACTIVE_PACKET_ACTION_COMPLETED) {
      draftState.packetDeveloperRejectOrConfirmOngoing = false;
    }

    if (action.type === ActionType.REJECTION_REASONS_FETCHED) {
      draftState.packetDeveloperRejectionReasons = action.payload.data;
    }

    if (action.type === ActionType.OPEN_PACKET_DEVELOPER_REJECTION_MODAL) {
      draftState.currentPacketHash = action.payload.packetHash;
      draftState.developerForRejectedModal = action.payload.packetDeveloper;
      draftState.rejectPacketDeveloperModal = true;
    }

    if (action.type === ActionType.SET_PACKET_HASH_FOR_CALENDAR_IRS) {
      draftState.currentPacketHash = action.payload.currentPacketHash;
      draftState.developerPackets = action.payload.developerPackets;
    }

    if (action.type === ActionType.CLOSE_PACKET_DEVELOPER_REJECTION_MODAL) {
      draftState.currentPacketHash = null;
      draftState.developerForRejectedModal = null;
      draftState.rejectPacketDeveloperModal = false;
    }

    if (
      action.type === ActionType.BULK_IR_COMPLETED_FROM_SAVED_SUGGESTION_MODAL
    ) {
      draftState.savedSuggestionIROnGoing = false;
      draftState.requestSavedSugestionModalOpen = false;
      draftState.bulkIRCalendarOpen = false;
    }
    if (
      action.type === ActionType.SELECT_OR_UNSELECT_FOR_BULK_IR &&
      action.payload.developerId
    ) {
      const developerId = action.payload.developerId;
      const userHash = action.payload.userHash;
      const developerName = action.payload.developerName;
      const avatar = action.payload.avatar;
      const countryName = action.payload.countryName;
      if (
        action.payload.selected === true &&
        !draftState.developersSelected
          .map(({ developerId }) => developerId)
          .includes(developerId)
      ) {
        draftState.developersSelected = [
          ...draftState.developersSelected,
          { developerId, userHash, developerName, avatar, countryName },
        ];
      } else {
        const newArray = [...draftState.developersSelected];
        const index = newArray.findIndex(
          val => val.developerId === developerId,
        );
        if (index > -1) {
          newArray.splice(index, 1);
          draftState.developersSelected = [...newArray];
        }
      }
    }

    if (
      action.type === ActionType.INITIATE_CALENDAR_FROM_SAVED_SUGGESTIONS_MODAL
    ) {
      draftState.requestSavedSugestionModalOpen = false;
    }
    if (action.type === ActionType.SKIP_SUGGESTION_MODAL) {
      draftState.requestSavedSugestionModalOpen = false;
      draftState.developersSelected = [];
    }

    if (action.type === ActionType.SET_SOURCE_FOR_IR) {
      draftState.source = action.payload.source;
      draftState.bulkIRCalendarOpen = true;
      draftState.currentJobIdForIR = action.payload.currentJobIdForIR;
    }

    if (action.type === ActionType.CLOSE_BULK_IR_CALENDAR) {
      draftState.bulkIRCalendarOpen = false;
    }

    if (action.type === ActionType.SET_DEVELOPERS_SELECTED) {
      draftState.developersSelected = action.payload.developersSelected;
    }

    if (action.type === ActionType.OPEN_INTERVIEW_REMINDER_MODAL) {
      draftState.interviewReminderModalOpen = true;
      draftState.interviewReminderModalRequestedCount =
        action.payload.totalInterviewRequestedForJob;
    }

    if (action.type === ActionType.CLOSE_INTERVIEW_REMINDER_MODAL) {
      draftState.interviewReminderModalOpen = false;
    }

    if (action.type === ActionType.DONT_REMIND_ME_INTERVIEW_REMINDER) {
      draftState.dontRemindJobs = action.payload.dontRemindJobs;
      draftState.interviewReminderModalOpen = false;
    }

    if (action.type === ActionType.CONTINUE_ON_INTERVIEW_REMINDER_MODAL) {
      draftState.interviewReminderModalOpen = false;
    }

    if (action.type === ActionType.SET_CURRENT_JOB_ID_FOR_IR) {
      draftState.currentJobIdForIR = action.payload.jobId;
    }
  });
};
