import TuringLogo from '../../../assets/img/turing-logo.png';
import React from 'react';
import './splash-screen.scss';

export const SplashScreen = () => {
  return (
    <div className="splash-screen">
      <div>
        <img src={TuringLogo} alt="Turing-Logo" />
      </div>
      <div className="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};
