import produce from 'immer';
import { ActionType } from './interview-requested-developers.types';

const initialState = {
  interviewRequestedDevelopers: [],
  isFetching: false,
};

export const interviewRequestedDevelopersReducer = (
  state = initialState,
  action,
) => {
  return produce(state, draftState => {
    if (action.type === ActionType.GET_INTERVIEW_REQUESTED_DEVELOPERS_STARTED) {
      draftState.isFetching = true;
    }
    if (action.type === ActionType.GET_INTERVIEW_REQUESTED_DEVELOPERS) {
      const { interviewRequestedDevelopers } = action.payload;
      draftState.interviewRequestedDevelopers = interviewRequestedDevelopers;
    }
    if (
      action.type === ActionType.GET_INTERVIEW_REQUESTED_DEVELOPERS_FINISHED
    ) {
      draftState.isFetching = false;
    }
  });
};
