import { Spin } from 'antd';
import { Suspense } from 'react';

const renderPublicLazyComponent = (Component: any) => {
  return function (props: any) {
    return (
      <Suspense
        fallback={
          <div>
            <Spin size="large" />
          </div>
        }
      >
        <Component {...props} />
      </Suspense>
    );
  };
};

export default renderPublicLazyComponent;
