import React, { useMemo } from 'react';
import { isValidOptionalSkills } from '../../../../app/job-creation/job-creation.util';
import { SkillTags } from '../../../main/components/skill-tags/skill-tags';
import { OtherOption } from '../other-option/other-option';
import './style.scss';

export const OptionalSkills = ({
  skillCategories,
  requiredSkills,
  requiredSkillsList,
  optionalSkills,
  handleOptionalSkillChange,
  skillLabel = 'What are the optional skills?',
  showError,
}) => {
  const handleOtherSkillChange = skills => {
    let seen = {},
      uniqueSkillIds = [...getSelectedRequiredSkills(), ...skills].filter(
        item => {
          return seen.hasOwnProperty(item) ? false : (seen[item] = true);
        },
      );
    handleOptionalSkillChange(uniqueSkillIds);
  };

  const handleFixedSkillsChange = skills => {
    if (skills.indexOf(-1) === -1) {
      const otherSkills = getOtherRequiredSkills();
      skills = skills.filter(id => otherSkills.indexOf(id) === -1);
    }
    handleOptionalSkillChange(skills);
  };

  const getOtherRequiredSkills = () => {
    const requiredSkillsListIds = requiredSkillsList.map(skill => skill.id);
    return optionalSkills.filter(
      req => requiredSkillsListIds.indexOf(req) === -1,
    );
  };

  const getSelectedRequiredSkills = () => {
    const requiredSkillsListIds = requiredSkillsList.map(skill => skill.id);
    return optionalSkills.filter(
      req => requiredSkillsListIds.indexOf(req) > -1,
    );
  };

  const requiredSkillsDiff = useMemo(() => {
    return requiredSkillsList.filter(
      skill => skill.id === -1 || requiredSkills.indexOf(skill.id) === -1,
    );
  }, [requiredSkills]);

  const otherOptions = useMemo(() => {
    return skillCategories
      .filter(skill => !requiredSkills.includes(skill.id))
      .map(skill => {
        return { value: skill.id, text: skill.skillName };
      });
  }, [requiredSkills]);

  const shouldShowOtherOptionWarning = useMemo(() => {
    return (
      showError &&
      optionalSkills.includes(-1) &&
      getOtherRequiredSkills().length === 0
    );
  }, [showError, optionalSkills]);

  return (
    <div className="optional-skills" data-cy="optional-skills">
      <div className="selection-container">
        <div className="label">{skillLabel}</div>
        <div className="sub-label">Select up to 3 options</div>
        <div className="tags-container">
          <SkillTags
            skills={requiredSkillsDiff}
            onSkillsChange={handleFixedSkillsChange}
            alreadySelectedSkillIds={optionalSkills}
          />
        </div>
        {optionalSkills.indexOf(-1) > -1 && (
          <OtherOption
            optionsList={otherOptions}
            value={getOtherRequiredSkills()}
            handleOnChange={handleOtherSkillChange}
          />
        )}
        {showError && !isValidOptionalSkills(optionalSkills) && (
          <div className="error-label">
            {optionalSkills.length > 0 && optionalSkills.length > 3
              ? 'Please select up to 3 skills'
              : ''}
          </div>
        )}
        {shouldShowOtherOptionWarning ? (
          <div className="error-label">Please specify an other skill</div>
        ) : null}
      </div>
    </div>
  );
};
