import { Select } from 'antd';
import React from 'react';
import './other-option.scss';

export const OtherOption = ({ optionsList = [], value, handleOnChange }) => {
  const { Option } = Select;

  const getOptions = () => {
    const options = [];
    for (let i = 0; i < optionsList.length; i++) {
      options.push(
        <Option key={optionsList[i].value} value={optionsList[i].value}>
          {optionsList[i].text}
        </Option>,
      );
    }
    return options;
  };

  return (
    <div className="other-option">
      <div>Other (please specify)</div>
      <Select
        showSearch
        optionFilterProp="children"
        size="medium"
        mode="multiple"
        placeholder="Enter here..."
        style={{ width: '100%' }}
        value={value}
        onChange={handleOnChange}
      >
        {getOptions()}
      </Select>
    </div>
  );
};
