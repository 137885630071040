import produce from 'immer';
import { ActionType } from './hidden-developers.types';

const initialState = {
  hiddenDevelopers: [],
  isFetching: false,
  timezones: [],
};

export const hiddenDevelopersReducer = (state = initialState, action) => {
  return produce(state, draftState => {
    if (action.type === ActionType.GET_HIDDEN_DEVELOPERS_STARTED) {
      draftState.isFetching = true;
    }
    if (action.type === ActionType.GET_HIDDEN_DEVELOPERS_FINISHED) {
      draftState.isFetching = false;
    }
    if (action.type === ActionType.GET_HIDDEN_DEVELOPERS) {
      const { hiddenDevelopers } = action.payload;
      draftState.hiddenDevelopers = hiddenDevelopers;
    }
    if (action.type === ActionType.REMOVE_FROM_HIDDEN_DEVELOPERS) {
      const { developerIdHash } = action.payload;
      draftState.hiddenDevelopers = state.hiddenDevelopers.filter(
        developer => developerIdHash !== developer.userHash,
      );
    }
    if (action.type === ActionType.SET_TIMEZONES) {
      draftState.timezones = action.payload;
    }
  });
};
