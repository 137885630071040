export const ActionType = {
  REQUEST_TIMESHEETS_STARTED: 'REQUEST_TIMESHEETS_STARTED',
  REQUEST_TIMESHEETS_COMPLETED: 'REQUEST_TIMESHEETS_COMPLETED',
  REQUEST_TIMESHEETS_FAILED: 'REQUEST_TIMESHEETS_FAILED',
  SUBMIT_TIMESHEETS_STARTED: 'SUBMIT_TIMESHEETS_STARTED',
  SUBMIT_TIMESHEETS_COMPLETED: 'SUBMIT_TIMESHEETS_COMPLETED',
  SUBMIT_TIMESHEETS_FAILED: 'SUBMIT_TIMESHEETS_FAILED',
  OPEN_TIMESHEETS_MODAL: 'OPEN_TIMESHEETS_MODAL',
  CLOSE_TIMESHEETS_MODAL: 'CLOSE_TIMESHEETS_MODAL',
  UPDATE_MESSAGE: 'UPDATE_MESSAGE',
  UPDATE_TIMESHEETS_DATE_RANGE: 'UPDATE_TIMESHEETS_DATE_RANGE',
};
