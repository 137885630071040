import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAppSelector } from '../../store/rootReducersType';
import { logActivity } from '../../app/user-activity-trail/user-activity-trail.actions';
import { ActivityTypes } from '../../app/user-activity-trail/user-activity-trail.types';

export const useFirstClickHook = () => {
  const currentUser = useAppSelector(state => state.auth.currentUser);

  const dispatch = useDispatch();

  const listener = useCallback(() => {
    if (!currentUser) return;
    const email = currentUser.email;

    const emailClicks: Array<string> = JSON.parse(
      sessionStorage.getItem('emailClicks') ?? '[]',
    );
    if (!emailClicks.includes(email)) {
      //log first email click
      dispatch(
        logActivity(
          ActivityTypes.PAGE_VIEW.KEY,
          ActivityTypes.PAGE_VIEW.ACTIONS.FIRST_CLICK,
          {},
        ),
      );
      emailClicks.push(email);
    }
    sessionStorage.setItem('emailClicks', JSON.stringify(emailClicks));
  }, [currentUser]);

  useEffect(() => {
    window.addEventListener('click', listener);

    return () => {
      window.removeEventListener('click', listener);
    };
  }, [listener]);

  return null;
};
