var gbUserFlags = {};

export function getGBUserFlags() {
  return gbUserFlags;
}

export async function setGBUserFlags(userFlags) {
  gbUserFlags = JSON.parse(JSON.stringify(userFlags));
  return;
}
