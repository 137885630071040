import Environment from '../../Enviroment';
import { getGBUserFlags } from '../../shared/growth-book';
import { AppFlags, overrideAppFlags } from '../../shared/appFlags';
import { AuthorizationService } from '../AuthService';

const getHeaders = (isDSHeader = false) => {
  const token = AuthorizationService.getAuthPayload().token;
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  if (token) headers.append('Authorization', 'Bearer ' + token);
  if (Environment.environment !== 'production') {
    headers.append('flag-overrides', JSON.stringify(overrideAppFlags));
  }
  if (isDSHeader) {
    headers.append('product', 'self-serve-frontend');
  }
  return headers;
};

const makeRequest = (url, options, headers = null) => {
  return new Promise((resolve, reject) => {
    url = url.startsWith('http') ? url : `${Environment.remoteOrigin}${url}`;
    fetch(url, {
      ...options,
      credentials: 'same-origin',
      headers: headers || getHeaders(),
    })
      .then(async response => {
        const { ok, status } = response;
        const statusWithEmptyBody = [204, 205];
        if (statusWithEmptyBody.includes(status)) {
          return resolve({ status });
        }
        const responseJson = await response.json();
        if (ok) {
          const res = responseJson;
          res.httpStatus = status;
          res.httpCode = status;
          return resolve(res);
        }
        return reject(responseJson);
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const RequestService = {
  postFormData: (url, data) => {
    const headers = getHeaders();
    headers.delete('Content-Type');
    return makeRequest(
      url,
      {
        body: data,
        method: 'POST',
      },
      headers,
    );
  },
  post: (url, data, headers, keepAlive = false) => {
    return makeRequest(
      url,
      {
        body: JSON.stringify(data),
        method: 'POST',
        ...(keepAlive ? { keepalive: true } : {}),
      },
      headers,
    );
  },
  postV2: (url, data, headers) => {
    headers = getHeaders();
    headers.append('TURING-API-VERSION', '2');
    return makeRequest(
      url,
      {
        body: JSON.stringify(data),
        method: 'POST',
      },
      headers,
    );
  },
  put: (url, data, headers) => {
    return makeRequest(
      url,
      {
        body: JSON.stringify(data),
        method: 'PUT',
      },
      headers,
    );
  },
  patch: (url, data, headers) => {
    return makeRequest(
      url,
      {
        body: JSON.stringify(data),
        method: 'PATCH',
      },
      headers,
    );
  },
  patchV2: (url, data, headers) => {
    headers = getHeaders();
    headers.append('TURING-API-VERSION', '2');
    return makeRequest(
      url,
      {
        body: JSON.stringify(data),
        method: 'PATCH',
      },
      headers,
    );
  },
  get: (url, headers) => {
    return makeRequest(
      url,
      {
        method: 'GET',
      },
      headers,
    );
  },
  delete: (url, data = {}, headers) => {
    return makeRequest(
      url,
      {
        body: JSON.stringify(data),
        method: 'DELETE',
      },
      headers,
    );
  },
  makeDSRequest: (url, options) => {
    let fullUrl;
    if (url.startsWith('http')) {
      fullUrl = url;
    } else if (getGBUserFlags()[AppFlags.DeveloperServiceOrigin]) {
      fullUrl = `${getGBUserFlags()[AppFlags.DeveloperServiceOrigin]}${url}`;
    } else {
      fullUrl = `${Environment.developerServiceOrigin}${url}`;
    }
    const headers = getHeaders(true);
    return makeRequest(fullUrl, options, headers);
  },
};
