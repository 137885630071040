export const ActionType = {
  SHOW_SAVED_SUGGESTION_MODAL: 'SHOW_SAVED_SUGGESTION_MODAL',
  BULK_IR_STARTED_FROM_SAVED_SUGGESTION_MODAL:
    'BULK_IR_STARTED_FROM_SAVED_SUGGESTION_MODAL',
  SELECT_OR_UNSELECT_FOR_BULK_IR: 'SELECT_OR_UNSELECT_FOR_BULK_IR',
  SKIP_SUGGESTION_MODAL: 'SKIP_SUGGESTION_MODAL',
  INITIATE_CALENDAR_FROM_SAVED_SUGGESTIONS_MODAL:
    'INITIATE_CALENDAR_FROM_SAVED_SUGGESTIONS_MODAL',
  BULK_IR_COMPLETED_FROM_SAVED_SUGGESTION_MODAL:
    'BULK_IR_COMPLETED_FROM_SAVED_SUGGESTION_MODAL',
  SET_SOURCE_FOR_IR: 'SET_SOURCE_FOR_IR',
  SET_DEVELOPERS_SELECTED: 'SET_DEVELOPERS_SELECTED',
  OPEN_INTERVIEW_REMINDER_MODAL: 'OPEN_INTERVIEW_REMINDER_MODAL',
  CLOSE_INTERVIEW_REMINDER_MODAL: 'CLOSE_INTERVIEW_REMINDER_MODAL',
  DONT_REMIND_ME_INTERVIEW_REMINDER: 'DONT_REMIND_ME_INTERVIEW_REMINDER',
  CONTINUE_ON_INTERVIEW_REMINDER_MODAL: 'CONTINUE_ON_INTERVIEW_REMINDER_MODAL',
  SET_CURRENT_JOB_ID_FOR_IR: 'SET_CURRENT_JOB_ID_FOR_IR',
  CLOSE_BULK_IR_CALENDAR: 'CLOSE_BULK_IR_CALENDAR',
  SET_CUSTOMER_AVAILABILITY: 'SET_CUSTOMER_AVAILABILITY',
  OPEN_PACKET_NUDGE: 'OPEN_PACKET_NUDGE',
  CLOSE_PACKET_NUDGE: 'CLOSE_PACKET_NUDGE',
  OPEN_PACKET_DEVELOPER_REJECTION_MODAL:
    'OPEN_PACKET_DEVELOPER_REJECTION_MODAL',
  CLOSE_PACKET_DEVELOPER_REJECTION_MODAL:
    'CLOSE_PACKET_DEVELOPER_REJECTION_MODAL',
  REJECTION_REASONS_FETCHED: 'REJECTION_REASONS_FETCHED',
  INTERACTIVE_PACKET_ACTION_REQUEST_STARTED:
    'INTERACTIVE_PACKET_ACTION_REQUEST',
  INTERACTIVE_PACKET_ACTION_COMPLETED: 'INTERACTIVE_PACKET_ACTION_COMPLETED',
  SET_PACKET_HASH_FOR_CALENDAR_IRS: 'SET_PACKET_HASH_FOR_CALENDAR_IRS',
  ADD_DEVELOPER_TO_FEEDBACK_COLLECTED_LIST:
    'ADD_DEVELOPER_TO_FEEDBACK_COLLECTED_LIST',
};

export interface SavedDeveloperInSavedSuggestionsModal {
  developerId: number;
  avatar: string;
  countryName: string;
  hourlyRate: number;
  monthlyRate: number;
  name: string;
  userHash: string;
}

export const InterviewRequestSource = {
  savedSuggestionsModal: 'saved-suggestions-modal',
  developerCard: 'developer-card',
};
