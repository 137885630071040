import React from 'react';
import { Tag } from 'antd';
import 'antd/dist/antd.css';
import './skill-tags.css';

export const SkillTags = ({
  skills = [],
  onSkillsChange,
  alreadySelectedSkillIds = [],
}) => {
  const handleSkillTagClick = skillId => {
    const index = alreadySelectedSkillIds.indexOf(skillId);
    if (index > -1) {
      const skillIdsCopy = [...alreadySelectedSkillIds];
      skillIdsCopy.splice(index, 1);
      onSkillsChange(skillIdsCopy);
    } else {
      onSkillsChange([...alreadySelectedSkillIds, skillId]);
    }
  };

  return (
    <>
      {skills.map(({ id, skillName }) => {
        const active = alreadySelectedSkillIds.includes(id);
        const clickHandler = {
          onClick: () => handleSkillTagClick(id),
        };
        return (
          <Tag
            key={id}
            {...clickHandler}
            data-cy="fixed-skill-tag"
            className={`fixed-skill-tag ${
              active ? 'fixed-skill-tag-selected' : 'fixed-skill-tag-unselected'
            }`}
          >
            {skillName}
          </Tag>
        );
      })}
    </>
  );
};
