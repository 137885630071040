import { RootState } from '../../store/rootReducersType';
import { ActivityTypes } from '../user-activity-trail/user-activity-trail.types';
import { SavedDeveloperInSavedSuggestionsModal } from './bulk-interviews.types';

export const getSavedDevsToInterview = (
  state: RootState,
  jobId: number,
  dontInclude: number[],
) => {
  const { savedDevelopersMap } = state.savedDevelopers;
  const savedDevelopers: Array<SavedDeveloperInSavedSuggestionsModal> = [];

  if (savedDevelopersMap[jobId]) {
    for (let developerId in savedDevelopersMap[jobId]) {
      const savedDev = savedDevelopersMap[jobId][developerId];

      if (
        savedDev.available === true &&
        !savedDev.requestedForInterview &&
        !dontInclude.includes(Number(developerId))
      ) {
        const { avatar, countryName, hourlyRate, monthlyRate, name, userHash } =
          savedDev;
        savedDevelopers.push({
          developerId: Number(developerId),
          avatar,
          countryName,
          hourlyRate,
          name,
          userHash,
          monthlyRate,
        });
      }
    }
  }
  return savedDevelopers;
};

export const getInterviewRequestedDeveloperNames = (
  developersSelected: Array<{
    developerId: number;
    userHash: string;
    developerName: string;
    countryName: string;
    avatar: string;
  }>,
) => {
  const developerNames: string[] = [];
  developersSelected.forEach((developerSelected, currentIndex) => {
    const lastName = currentIndex === developersSelected.length - 1,
      firstName = currentIndex === 0;
    const developerName = developerSelected.developerName || '';
    if (lastName && !firstName) {
      developerNames.push(` and ${developerName}`);
      return;
    }
    developerNames.push(
      `${firstName === false ? `, ${developerName}` : `${developerName}`}`,
    );
  });
  return developerNames;
};

export const getActionTypeForClosePacketNudgeModal = (
  isAutoClose: boolean,
  isRedirectedToSearch: boolean,
) => {
  let actionType = '';
  if (isAutoClose && isRedirectedToSearch) {
    actionType =
      ActivityTypes.HANDPICKED_DEVELOPERS.ACTIONS
        .POST_CONFIRM_AUTO_REDIRECTED_TO_SEARCH;
  } else if (!isAutoClose && isRedirectedToSearch) {
    actionType =
      ActivityTypes.HANDPICKED_DEVELOPERS.ACTIONS
        .POST_CONFIRM_CLICKED_REDIRECT_TO_SEARCH;
  } else if (!isAutoClose && !isRedirectedToSearch) {
    actionType =
      ActivityTypes.HANDPICKED_DEVELOPERS.ACTIONS
        .POST_CONFIRM_CLOSED_POPUP_STAYED_IN_HANDPICKED;
  }
  return actionType;
};
