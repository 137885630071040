import { connect } from 'react-redux';
import {
  syncOtherRoleType,
  syncRoleType,
} from '../../../../app/job-creation/job-creation.actions';
import { jobRoleTypes } from '../../../../app/job-creation/job-creation.constants';
import { JobRole } from './job-role';

const mapStateToProps = state => {
  return {
    roleTypesList: jobRoleTypes,
    roleType: state.jobCreation.roleType,
    showError: state.jobCreation.showValidationErrors,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleRoleTypeChange: roleType => {
      dispatch(syncRoleType(roleType));
    },
    handleOtherRoleTypeChange: value => {
      dispatch(syncOtherRoleType(value));
    },
  };
};

const connected = connect(mapStateToProps, mapDispatchToProps)(JobRole);
export { connected as JobRoleContainer };
