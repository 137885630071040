import { ITimeCardSummary } from '@turingenterprises/postmatch-components/*';

export const ActionType = {
  REQUEST_TIMECARD_STARTED: 'REQUEST_TIMECARD_STARTED',
  REQUEST_TIMECARD_COMPLETED: 'REQUEST_TIMECARD_COMPLETED',
  REQUEST_TIMECARD_FAILED: 'REQUEST_TIMECARD_FAILED',
  APPROVAL_ACTION_STARTED: 'APPROVAL_ACTION_STARTED',
  APPROVAL_ACTION_COMPLETED: 'APPROVAL_ACTION_COMPLETED',
  COMMENT_REQUEST_STARTED: 'COMMENT_REQUEST_STARTED',
  COMMENT_REQUEST_COMPLETED: 'COMMENT_REQUEST_COMPLETED',
  SET_COMMENT_TRIGGER: 'SET_COMMENT_TRIGGER',
};

export interface TimeCardSummaryResponse {
  timeCardResponse?: ITimeCardSummary;
  isFetchingTimeCard: boolean;
  activeTimeCardId?: string;
  approveActionProgress?: boolean;
  commentActionProgress?: boolean;
  rejectActionProgress?: boolean;
  resetCommentTrigger?: boolean;
  disableApprovalWorkflow: boolean;
}

export const ApprovelActionTypes = {
  approve: 'approve',
  reject: 'reject',
};

export const ApproverTypes = {
  CLIENT: 'CLIENT',
  INTERNAL: 'INTERNAL',
};
export enum APPROVAL_STATUS {
  QUEUED = 'QUEUED',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  AUTO_APPROVED = 'AUTO_APPROVED',
}
