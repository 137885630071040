import React, { useEffect, useMemo } from 'react';
import { isValidRequiredSkills } from '../../../../app/job-creation/job-creation.util';
import { SkillTags } from '../../../main/components/skill-tags/skill-tags';
import { OtherOption } from '../other-option/other-option';
import './style.scss';

export const RequiredSkills = ({
  skillCategories,
  requiredSkills,
  skillLabel = 'Required skills',
  handleRequiredSkillsChange,
  requiredSkillsList,
  getAllSkills,
  optionalSkills,
  showError,
}) => {
  useEffect(() => {
    getAllSkills();
  }, []);

  const handleOtherSkillChange = skills => {
    let seen = {},
      uniqueSkillIds = [...getSelectedRequiredSkills(), ...skills].filter(
        item => {
          return seen.hasOwnProperty(item) ? false : (seen[item] = true);
        },
      );
    handleRequiredSkillsChange(uniqueSkillIds);
  };

  const handleFixedSkillsChange = skills => {
    if (skills.indexOf(-1) === -1) {
      const otherSkills = getOtherRequiredSkills();
      skills = skills.filter(id => otherSkills.indexOf(id) === -1);
    }
    handleRequiredSkillsChange(skills);
  };

  const getOtherRequiredSkills = () => {
    const requiredSkillsListIds = requiredSkillsList.map(skill => skill.id);
    return requiredSkills.filter(
      req => requiredSkillsListIds.indexOf(req) === -1,
    );
  };

  const getSelectedRequiredSkills = () => {
    const requiredSkillsListIds = requiredSkillsList.map(skill => skill.id);
    return requiredSkills.filter(
      req => requiredSkillsListIds.indexOf(req) > -1,
    );
  };

  const optionalSkillsDiff = useMemo(() => {
    return requiredSkillsList.filter(
      skill => skill.id === -1 || optionalSkills.indexOf(skill.id) === -1,
    );
  }, [optionalSkills]);

  const otherOptions = useMemo(() => {
    return skillCategories
      .filter(skill => !optionalSkills.includes(skill.id))
      .map(skill => {
        return { value: skill.id, text: skill.skillName };
      });
  }, [optionalSkills]);

  const shouldShowOtherOptionWarning = useMemo(() => {
    return (
      showError &&
      requiredSkills.includes(-1) &&
      getOtherRequiredSkills().length === 0
    );
  }, [showError, optionalSkills]);

  return (
    <div className="required-skills">
      <div className="selection-container" data-cy="required-skills">
        <div className="label required-skill-label">{skillLabel}</div>
        <div className="sub-label">Select up to 3 options</div>
        <div className="tags-container">
          <SkillTags
            skills={optionalSkillsDiff}
            onSkillsChange={handleFixedSkillsChange}
            alreadySelectedSkillIds={requiredSkills}
          />
        </div>
        {requiredSkills.indexOf(-1) > -1 && (
          <OtherOption
            optionsList={otherOptions}
            value={getOtherRequiredSkills()}
            handleOnChange={handleOtherSkillChange}
          />
        )}
        {showError && !isValidRequiredSkills(requiredSkills) && (
          <div className="error-label">
            {requiredSkills.length === 0
              ? 'Please select a skill'
              : 'Please select up to 3 skills'}
          </div>
        )}
        {shouldShowOtherOptionWarning ? (
          <div className="error-label">Please specify an other skill</div>
        ) : null}
      </div>
    </div>
  );
};
