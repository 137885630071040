import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { MainInitializationState } from '../../app/main/main.reducers';
import { SplashScreen } from './components/splash-screen';
import {
  clearOverrideAppFlags,
  setOverrideAppFlags,
} from '../../shared/appFlags';
import { getUserOptions } from '../../shared/customer';
import Environment from '../../Enviroment';
import { InviteCoworkerModal } from '../invite-coworker-modal';
import { responsiveService } from '../../services';
import { preLoadImages } from '../../app/util';
import { preLoadedImagesList } from '../../app/constants';
import { NativeBookACallModal } from '../native-book-a-call/native-book-a-call';
import { JobCreationModal, CreateJobModalV2 } from '../job-creation/components';

export const JobCreationSources = {
  OPENING_A_LIMITED_ACCOUNT: 'OPENING_A_LIMITED_ACCOUNT',
};

export const TuringBootstrap = ({
  initializationState,
  currentUser,
  currentJobId,
  jobs,
  initializeUserProfile,
  switchCurrentJob,
  history,
  children,
  setAppFlags,
  flags,
  setJobCreationMode,
}) => {
  let { jobID } = useParams();
  const originalJobIdParam = jobID;
  jobID = isNaN(jobID) ? null : parseInt(jobID);
  const isJobEntertained = !jobID || currentJobId === jobID;
  const initializationCompletedOnce =
    initializationState === MainInitializationState.INITIALIZED_ONCE;

  useEffect(() => {
    if (initializationState === MainInitializationState.PENDING) {
      initializeUserProfile();
    }
  }, [initializationState, initializeUserProfile]);

  useEffect(() => {
    if (initializationCompletedOnce) {
      if (originalJobIdParam && !jobID) {
        return history.push('/');
      }
      if (!isJobEntertained) {
        const foundJob = jobs.find(job => job.id === jobID);
        if (!foundJob) {
          return history.push('/');
        }
        switchCurrentJob(jobID);
      }
    }
  }, [jobs, jobID, initializationState, currentJobId]);

  const appFlagsMap = getUserOptions();

  const { enableJobFlowV2 } = appFlagsMap;

  useEffect(() => {
    if (currentUser?.limitedAccount && jobs?.length === 0) {
      setJobCreationMode(
        JobCreationSources.OPENING_A_LIMITED_ACCOUNT,
        currentUser?.limitedAccount,
        enableJobFlowV2,
      );
    }
  }, [currentUser, jobs]);

  // @Todo: This is a hacky way finding the difference between each version of flags to stop rerender and control useEffect
  const flagsString = JSON.stringify(appFlagsMap);

  useEffect(() => {
    if (currentUser) {
      setAppFlags(appFlagsMap);
    }
  }, [currentUser, flagsString, setAppFlags]);

  window.setAppFlagOverrides = (overrides = {}) => {
    setOverrideAppFlags(overrides);
    if (currentUser) {
      const flagsMap = getUserOptions();
      setAppFlags(flagsMap);
    }
  };

  window.clearAppFlagOverrides = () => {
    clearOverrideAppFlags();
    if (currentUser) {
      const flagsMap = getUserOptions();
      setAppFlags(flagsMap);
    }
  };

  useEffect(() => {
    responsiveService.init();

    preLoadImages(preLoadedImagesList);
    return () => responsiveService.destroy();
  }, []);

  useEffect(() => {
    const script = document.createElement('script');

    script.src = '//js-na1.hs-scripts.com/24421359.js';
    script.type = 'text/javascript';
    script.async = true;
    script.defer = true;
    script.id = 'hs-script-loader';

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  });

  const featureFlagsLoaded = Environment.growthbookEnabled
    ? Object.keys(appFlagsMap).length
    : flags.height;
  const assertionDone =
    initializationCompletedOnce && isJobEntertained && featureFlagsLoaded;

  if (!assertionDone) {
    return <SplashScreen />;
  }
  return (
    <>
      <InviteCoworkerModal />
      <NativeBookACallModal />
      {enableJobFlowV2 ? <CreateJobModalV2 /> : <JobCreationModal />}
      {children}
    </>
  );
};
