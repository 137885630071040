import _ from 'lodash';
import { roleTypeIdMap, jobRoleTypes } from './job-creation.constants';
import { WORKING_HOURS_PER_MONTH } from '../../shared/constants';
import moment from 'moment';

export const isRequiredField = field => {
  return field.length > 0;
};

export const isValidRole = roleType => {
  let isValid = true;
  isValid = isValid && roleType.id;
  if (roleType.id === roleTypeIdMap.OTHER) {
    isValid = isValid && isRequiredField(roleType.otherValue);
  }
  return isValid;
};

export const isValidRequiredSkills = requiredSkills => {
  let isValid = true;
  isValid = isValid && requiredSkills.length > 0;
  if (requiredSkills.indexOf(-1) > -1) {
    isValid = isValid && requiredSkills.length > 1 && requiredSkills.length < 5;
  } else {
    isValid = isValid && requiredSkills.length < 4;
  }
  return isValid;
};

export const isValidRolesAndSkillsConfig = rolesAndSkillsConfig => {
  if (!rolesAndSkillsConfig) return false;
  return (
    isValidRole(rolesAndSkillsConfig.roleType) &&
    isValidRequiredSkills(rolesAndSkillsConfig.requiredSkills)
  );
};

export const isValidResponsibilityConfig = jobCreationConfig => {
  if (!jobCreationConfig) return false;
  return isRequiredField(jobCreationConfig.developerOwns);
};

export const isValidBudget = budgetValue => {
  let numberRegex = /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/;
  return numberRegex.test(budgetValue) && budgetValue >= 100;
};

export const isValidExperienceAndBudgetConfig = jobCreationConfig => {
  if (!jobCreationConfig) return false;
  let isValid = true;
  isValid =
    isValid &&
    (jobCreationConfig.minExperience === null ||
      !isNaN(parseInt(jobCreationConfig.minExperience, 10)));
  if (jobCreationConfig.budget.isStrict) {
    isValid = isValid && isValidBudget(jobCreationConfig.budget.value);
  }
  return isValid;
};

export const isValidJobTitle = jobCreationConfig => {
  if (!jobCreationConfig) return false;
  return jobCreationConfig.jobTitle.length > 0;
};

export const isValidLocationAndStartDateConfig = jobCreationConfig => {
  if (!jobCreationConfig) return false;
  let isValid = true;
  if (jobCreationConfig.location.isStrict) {
    isValid =
      isValid &&
      isRequiredField(jobCreationConfig.location.value) &&
      jobCreationConfig.location.value.length < 6;
  }
  return isValid;
};

export const isValidJobConfig = jobConfig => {
  if (!jobConfig) return false;
  let isValid = true;
  isValid = isValid && isValidRolesAndSkillsConfig(jobConfig);
  isValid = isValid && isValidResponsibilityConfig(jobConfig);
  isValid = isValid && isValidExperienceAndBudgetConfig(jobConfig);
  isValid = isValid && isValidJobTitle(jobConfig);
  isValid = isValid && isValidLocationAndStartDateConfig(jobConfig);
  return isValid;
};

export const isValidAddtionalPreference = config => {
  if (!config) return false;
  let isValid = config.additionalPreference.numPositions;
  return isValid;
};

export const isValidOptionalSkills = optionalSkills => {
  let isValid = true;
  if (optionalSkills.length === 0) return isValid;

  isValid = isValid && optionalSkills.length > 0;
  if (optionalSkills.indexOf(-1) > -1) {
    isValid = isValid && optionalSkills.length > 1 && optionalSkills.length < 5;
  } else {
    isValid = isValid && optionalSkills.length < 4;
  }
  return isValid;
};

export const isValidJobDescription = jobDescription => {
  if (!jobDescription) return false;
  let isValid = true;
  jobDescription = jobDescription.trim();
  isValid =
    isValid && jobDescription.length <= 5000 && jobDescription.length >= 50;
  return isValid;
};

export const isValidOtherSkills = (requiredSkillsList, skills) => {
  const requiredSkillsListIds = requiredSkillsList.map(skill => skill.id);
  const isValid = true;
  if (!skills.includes(-1)) return isValid;
  const selectedOtherSkills = skills.filter(
    skill => requiredSkillsListIds.indexOf(skill) === -1,
  );
  return isValid && selectedOtherSkills.length > 0;
};

export const getJobCreationPayload = (
  jobConfig,
  isNewJobRatesEnabled,
  isLimitedAccount = false,
  flagValue = false,
  jobsLength = 0,
  isJobEdit = false,
) => {
  const jobConfigPayload = {
    roleTypeId: jobConfig.roleType.id,
    otherRole: jobConfig.roleType.otherValue,
    requiredSkills: jobConfig.requiredSkills.filter(skill => skill !== -1),
    seniorityLevel: jobConfig.developerOwns,
    mustHaveTotalYearsOfExperience: parseInt(jobConfig.minExperience, 10),
    isBudgetLimited: jobConfig.budget.isStrict,
    role: shouldUseDefaultJobTitle(
      flagValue,
      jobsLength,
      isLimitedAccount,
      isJobEdit,
    )
      ? createDefaultJobTitle(
          jobConfig.roleType,
          jobRoleTypes,
          jobConfig.developerOwns,
        )
      : jobConfig.jobTitle,
    locationRequired: jobConfig.location.isStrict,
    continentIds: jobConfig.location.value,
    customerStartDate: jobConfig.startDate,
    hoursOverlap: parseInt(jobConfig.hoursOverlap, 10),
  };
  if (isNewJobRatesEnabled) {
    jobConfigPayload.customerBudget = jobConfig.budget.isStrict
      ? Math.round(parseFloat(jobConfig.budget.value))
      : '';
  } else {
    jobConfigPayload.maxAcceptableRate = jobConfig.budget.isStrict
      ? Math.round(parseFloat(jobConfig.budget.value) / WORKING_HOURS_PER_MONTH)
      : '';
  }
  return jobConfigPayload;
};

export const getAdditionalPreferencePayload = jobConfig => {
  return {
    optionalSkills: jobConfig.additionalPreference.skills.filter(
      skill => skill !== -1,
    ),
    niceHaveTotalYearsOfExperience: parseInt(
      jobConfig.additionalPreference.experience,
      10,
    ),
    numberOfOpenRoles: parseInt(
      jobConfig.additionalPreference.numPositions,
      10,
    ),
    notes: jobConfig.additionalPreference.preference,
  };
};

export const noChangeInAdditionalPreference = jobConfig => {
  const initialAdditionalPreference = {
    skills: [],
    numPositions: '1',
    preference: '',
  };
  return (
    jobConfig &&
    jobConfig.additionalPreference &&
    _.isEqual(jobConfig.additionalPreference, initialAdditionalPreference)
  );
};

export const isNewJobRatesEnabled = (
  selfServeJobPostOptions,
  jobCreatedDate,
) => {
  let today = moment().startOf('day');
  if (jobCreatedDate) {
    today = moment(jobCreatedDate).startOf('day');
  }
  const newJobRatesDate = moment(
    selfServeJobPostOptions?.newJobRatesEnableDate,
  );
  return today.diff(newJobRatesDate, 'd') >= 0;
};

export const createDefaultJobTitle = (roleType, roleTypesList, owns) => {
  const preTitle = (() => {
    if (roleType.otherValue) {
      return roleType.otherValue;
    }
    if (roleType.id) {
      const roleTypeFound = roleTypesList.find(rt => rt.id === roleType.id);
      if (roleTypeFound) {
        return roleTypeFound.skillName;
      }
    }
    return '';
  })();
  const postTitle = (() => {
    switch (owns) {
      case 'tasks':
        return 'Mid-level Engineer';
      case 'features':
        return 'Senior Engineer';
      case 'projects':
        return 'Lead Engineer';
      default:
        return '';
    }
  })();
  const computedJobTitle = preTitle + (postTitle ? ` - ${postTitle}` : '');
  return computedJobTitle;
};

export const getJobFlowV2Payload = (
  payload,
  jobConfig,
  isLimitedAccount = false,
  flagValue = false,
  jobsLength = 0,
  isJobEdit = false,
) => {
  return {
    ...payload,
    optionalSkills: jobConfig.additionalPreference.skills.filter(
      skill => skill !== -1,
    ),
    numberOfOpenRoles: parseInt(
      jobConfig.additionalPreference.numPositions,
      10,
    ),
    notes: shouldUseDefaultJobDescription(
      flagValue,
      jobsLength,
      isLimitedAccount,
      isJobEdit,
    )
      ? payload.role
      : jobConfig.additionalPreference.preference,
  };
};

export const isValidJobUpdateV2Config = jobConfig => {
  if (!jobConfig) return false;
  let isValid = true;
  isValid = isValid && isValidRolesAndSkillsConfig(jobConfig);
  isValid =
    isValid && isValidOptionalSkills(jobConfig.additionalPreference.skills);
  isValid =
    isValid &&
    isValidOtherSkills(jobConfig.requiredSkillsList, jobConfig.requiredSkills);
  isValid =
    isValid &&
    isValidOtherSkills(
      jobConfig.requiredSkillsList,
      jobConfig.additionalPreference.skills,
    );
  isValid = isValid && isValidResponsibilityConfig(jobConfig);
  isValid = isValid && isValidExperienceAndBudgetConfig(jobConfig);
  isValid = isValid && isValidJobTitle(jobConfig);
  isValid = isValid && isValidLocationAndStartDateConfig(jobConfig);
  isValid =
    isValid && isValidJobDescription(jobConfig.additionalPreference.preference);
  return isValid;
};

export const shouldUseDefaultJobTitle = (
  flagValue,
  jobsLength,
  isLimitedAccount,
  isJobEdit,
) => {
  if (!isLimitedAccount || flagValue || isJobEdit || jobsLength >= 1)
    return false;
  return true;
};

export const shouldUseDefaultJobDescription = (
  flagValue,
  jobsLength,
  isLimitedAccount,
  isJobEdit,
) => {
  if (!isLimitedAccount || flagValue || isJobEdit || jobsLength >= 1)
    return false;
  return true;
};
