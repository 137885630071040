import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { GoogleOAuthProvider } from '@react-oauth/google';

import { store } from './store';
import { history } from './store/history';
import './services/SentryService';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.scss';
import '@vime/core/themes/default.css';
import '@turingenterprises/postmatch-components/dist/index.css';
import Environment from './Enviroment';

const persistor = persistStore(store);

ReactDOM.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={Environment.googleClientId}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router basename="" history={history}>
            <App />
          </Router>
        </PersistGate>
      </Provider>
    </GoogleOAuthProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//
//
reportWebVitals();
