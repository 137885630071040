import { connect } from 'react-redux';
import {
  inviteCoworker,
  shareDeveloperByEmail,
  toggleInviteCoworkerModal,
  toggleShareDeveloperByEmailConfig,
} from '../../app/main/main.actions';
import { logActivity } from '../../app/user-activity-trail/user-activity-trail.actions';
import { ActivityTypes } from '../../app/user-activity-trail/user-activity-trail.types';
import { InviteCoworkerModal } from './invite-coworker-modal';

const mapStateToProps = (state, props) => {
  return {
    allJobs: state.jobSelection.jobs,
    currentUser: state.auth.currentUser,
    showInviteCoworkerModal: state.main.showInviteCoworkerModal,
    invitingCoworker: state.main.invitingCoworker,
    sessionId: state.main.sessionId,
    shareDeveloperByEmail: state.main.shareDeveloperByEmail,
    flagMap: state.flags.flagMap,
    inviteCoworkerModalTriggerSource:
      state.main.inviteCoworkerModalTriggerSource,
    currentJobId: state.jobSelection.currentJobId,
    currentDeveloperHash: state.main.currentDeveloperHash,
  };
};

const createDefaultLoggingHandler =
  (dispatch, action) =>
  (emailIds, jobIds, canSeeRates, note, sessionId, source = '', props = {}) => {
    dispatch(
      logActivity(ActivityTypes.INVITE_COWORKER.KEY, action, {
        email_ids: emailIds,
        job_ids: jobIds,
        view_rate: canSeeRates,
        note,
        session_id: sessionId,
        source,
        ...props,
      }),
    );
  };

const mapDispatchToProps = dispatch => {
  return {
    toggleInviteCoworkerModal: showModal => {
      dispatch(toggleInviteCoworkerModal(showModal));
    },
    inviteCoworker: ({ emails, jobIds, notes, canSeeRates }) => {
      dispatch(inviteCoworker({ emails, jobIds, notes, canSeeRates }));
    },
    // User turning on/off “Show developer rates to user” toggle when invites are shared
    logRateToggle: createDefaultLoggingHandler(
      dispatch,
      ActivityTypes.INVITE_COWORKER.ACTIONS.RATE_SWITCH_TOGGLE,
    ),
    logInviteCoworkerSubmit: createDefaultLoggingHandler(
      dispatch,
      ActivityTypes.INVITE_COWORKER.ACTIONS.INVITE_COWORKER_SUBMIT,
    ),
    logJobIdsUpdate: createDefaultLoggingHandler(
      dispatch,
      ActivityTypes.INVITE_COWORKER.ACTIONS.JOB_IDS_UPDATE,
    ),
    logShareDeveloperSubmit: (
      emailIds,
      jobId,
      note,
      sessionId,
      userId,
      developerId,
    ) => {
      const payload = {
        email: emailIds,
        jobId,
        note,
        sessionId,
        userId,
        developerId,
      };
      dispatch(
        logActivity(
          ActivityTypes.SHARE_DEVELOPER_BY_EMAIL.KEY,
          ActivityTypes.SHARE_DEVELOPER_BY_EMAIL.ACTIONS
            .SHARE_DEVELOPER_BY_EMAIL_SUBMIT,
          { payload },
        ),
      );
    },
    shareDeveloper: (emails, jobId, notes, developerHash, currentJobId) => {
      dispatch(
        shareDeveloperByEmail(
          emails,
          jobId,
          notes,
          developerHash,
          currentJobId,
        ),
      );
    },
    resetShareDeveloperByEmailConfig: () => {
      dispatch(
        toggleShareDeveloperByEmailConfig({
          showShareDeveloperModal: false,
          developerToShare: null,
        }),
      );
    },
  };
};

const connected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(InviteCoworkerModal);
export { connected as InviteCoworkerModalContainer };
