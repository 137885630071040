import Environment from '../Enviroment';

export const EXCLUDED_CONTINENT_CODES = ['AN'];

export const CustomerCategoryEnum = {
  PLATINUM: 'Platinum',
  GOLD: 'Gold',
  SILVER: 'Silver',
  BRONZE: 'Bronze',
};

export const DDPViewQuotaWarningThreshold = 0.8;

export const DDPModalSelector = 'ant-modal-wrap';

const COPYRIGHT_YEAR = Environment.copyrightYear || new Date().getFullYear();

export const COPYRIGHT_YEAR_TEXT = `© ${COPYRIGHT_YEAR} Turing`;

export const turingFooterData = {
  nav: [
    {
      title: 'Platform',
      list: [
        {
          href: 'https://customers.turing.com/hire/',
          text: 'For Companies',
        },
        {
          cta: 'https://developers.turing.com/',
          text: 'For Developers',
        },
      ],
    },
    {
      title: 'Company',
      list: [
        {
          href: 'https://www.turing.com/contact-us',
          text: 'Contact Us',
        },
        {
          href: 'https://www.turing.com/company',
          text: 'About Us',
        },
        {
          href: 'https://www.turing.com/review',
          text: 'Developer reviews',
        },
        {
          href: 'https://careers.turing.com/',
          text: 'Careers',
        },
        {
          href: 'https://www.turing.com/press',
          text: 'Press',
        },
      ],
    },
    {
      title: 'Resource',
      list: [
        {
          href: 'https://www.turing.com/blog',
          text: 'Blog',
        },
        {
          href: 'https://help.turing.com/hc/en-us',
          text: 'FAQs',
        },
      ],
    },
  ],
  copyright: COPYRIGHT_YEAR_TEXT,
  address: '1900 Embarcadero Road Palo Alto, CA, 94303',
  docs: [
    {
      href: 'https://www.turing.com/sitemap',
      text: 'Sitemap',
      new_tab: true,
    },
    {
      href: 'https://www.turing.com/terms-of-service',
      text: 'Terms of Service',
      new_tab: true,
    },
    {
      href: 'https://www.turing.com/policy',
      text: 'Privacy Policy',
      new_tab: true,
    },
  ],
};

export const OPEN_JOB_STATUSES = [
  'Creating Shortlist',
  'Soliciting Further Requirements',
  'Offline Client Vetting',
  'Preparing Packet',
  'Waiting for Packet Response',
  'Scheduling Client Interview',
];

export const MAX_RECOMMENDED_DEVS_IN_MAIN_PAGE = 5;
export const DDP_PROFILE_QUERY_KEY = 'DDP_PROFILE_QUERY_KEY';
export const IR_NUDGE_LIMIT = 5;
export const IR_NUDGE_DATA_KEY = 'nudgePanelData';
export const IR_NUDGE_TIME_LIMIT = 2; // Should be in days

export const JOB_INTERVIEW_STATUS_MAPPING = {
  'awaiting-candidate-response': "Awaiting developer's response",
  'awaiting-client-availability': "Awaiting client's response",
  'developer-proposed-alternate-schedule': 'New time proposed by developer',
  'developer-noshow': 'Developer no-show',
  'developer-not-available': 'Developer unavailable',
  'developer-not-interested': 'Developer not interested',
  'customer-noshow': 'Client no-show',
  'interview-completed': 'Meeting completed',
  'interview-rejected': 'Meeting not completed',
  'interview-scheduled': 'Meeting confirmed',
  closed: 'Closed',
  pending: 'Meeting requested',
};

export const MAX_RESULTS_TO_SHOW_PER_PAGE = 15;

export const RESPONSIVE_STATE = {
  DEFAULT: 'default',
  MOBILE: 'mobile',
  TABLET: 'tablet',
};

export const ARCHIVED_JOBS_STATUSES = [
  'lost opportunity', // backward compatibility
  'project aborted',
  'project successfully completed',
  'lost',
];

export const CUSTOMER_PORTAL_DASHBOARD_THRESHOLDS = {
  MAX_INITIAL_INTERVIEWS: 5,
  MAX_INITIAL_PACKETS: 5,
  MAX_INITIAL_SAVED_DEVELOPERS: 5,
};

export const DEVELOPER_SENIORITIES = [
  {
    score: { min: 0, max: 3.4 },
    label: 'Mid-level Developer',
    rubricLabelIndex: 0,
  },
  {
    score: { min: 3.4, max: 3.75 },
    label: 'Senior Developer',
    rubricLabelIndex: 1,
  },
  {
    score: { min: 3.75, max: 4.25 },
    label: 'Lead Developer',
    rubricLabelIndex: 2,
  },
  { score: { min: 4.25, max: 5 }, label: 'Tech Lead', rubricLabelIndex: 3 },
];

export const DEVELOPER_SENIORITIES_MAP = {
  midDev: {
    score: { min: 0, max: 3.4 },
    label: 'Mid-level Developer',
    rubricLabelIndex: 0,
  },
  seniorDev: {
    score: { min: 3.4, max: 3.75 },
    label: 'Senior Developer',
    rubricLabelIndex: 1,
  },
  leadDev: {
    score: { min: 3.75, max: 4.25 },
    label: 'Lead Developer',
    rubricLabelIndex: 2,
  },
  techLead: {
    score: { min: 4.25, max: 5 },
    exp: 5,
    label: 'Tech Lead',
    rubricLabelIndex: 3,
  },
};

export const WORKING_HOURS_PER_MONTH = 173;

export const EXPERIENCE_OPTIONS = [
  {
    id: 'any',
    text: 'Any',
    value: {
      min: '',
      max: '',
    },
  },
  {
    id: 'gt3',
    text: '3+ years',
    value: {
      min: 3,
      max: '',
    },
  },
  {
    id: 'gt5',
    text: '5+ years',
    value: {
      min: 5,
      max: '',
    },
  },
];

export const IR_SOURCE = {
  MATCHING: 'matching',
  SELF_SERVE: 'self-serve',
};

export const FEWER_SKILLS_THRESHOLD = 4;

export const SS_APP_VERSION = Environment.appVersion || '27.0.0';

export const JEST_TIMEOUT = 20000;
