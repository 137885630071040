import produce from 'immer';
import { AnyAction } from 'redux';
import {
  ActionType,
  ApprovelActionTypes,
  TimeCardSummaryResponse,
} from './time-card.types';

const initialState: TimeCardSummaryResponse = {
  timeCardResponse: null,
  isFetchingTimeCard: false,
  activeTimeCardId: null,
  resetCommentTrigger: false,
  disableApprovalWorkflow: false,
};

export const timeCardSummaryReducer = (
  state = initialState,
  action: AnyAction,
) => {
  return produce(state, draftState => {
    if (action.type === ActionType.REQUEST_TIMECARD_STARTED) {
      draftState.isFetchingTimeCard = true;
    }

    if (action.type === ActionType.REQUEST_TIMECARD_COMPLETED) {
      const { timeCardResponse } = action.payload;
      draftState.timeCardResponse = timeCardResponse;
      draftState.isFetchingTimeCard = false;
      draftState.activeTimeCardId = timeCardResponse.id;
      draftState.disableApprovalWorkflow =
        timeCardResponse.flags?.removeApprovalFlow ?? false;
    }

    if (action.type === ActionType.REQUEST_TIMECARD_FAILED) {
      draftState.isFetchingTimeCard = false;
    }

    if (action.type === ActionType.APPROVAL_ACTION_STARTED) {
      const { actionType } = action.payload;
      draftState.approveActionProgress =
        actionType === ApprovelActionTypes.approve ? true : false;
      draftState.rejectActionProgress =
        actionType === ApprovelActionTypes.reject ? true : false;
    }

    if (action.type === ActionType.APPROVAL_ACTION_COMPLETED) {
      const { actionType } = action.payload;
      draftState.approveActionProgress =
        actionType === ApprovelActionTypes.approve
          ? false
          : draftState.approveActionProgress;
      draftState.rejectActionProgress =
        actionType === ApprovelActionTypes.reject
          ? false
          : actionType === ApprovelActionTypes.reject;
    }

    if (action.type === ActionType.COMMENT_REQUEST_STARTED) {
      draftState.commentActionProgress = true;
    }

    if (action.type === ActionType.COMMENT_REQUEST_COMPLETED) {
      draftState.commentActionProgress = false;
    }
    if (action.type === ActionType.SET_COMMENT_TRIGGER) {
      draftState.resetCommentTrigger = action.payload.value;
    }
  });
};
