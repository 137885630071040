import produce from 'immer';
import { ActionType } from './continents.types';
import { EXCLUDED_CONTINENT_CODES } from '../../shared/constants';

const initialState = {
  continents: [],
};

export const continentsReducer = (state = initialState, action) => {
  return produce(state, draftState => {
    if (action.type === ActionType.GET_CONTINENTS) {
      draftState.continents = action.payload?.filter(
        ({ code }) => !EXCLUDED_CONTINENT_CODES.includes(code),
      );
    }
  });
};
