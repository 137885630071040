import { connect } from 'react-redux';
import { syncAdditionalPreferenceText } from '../../../../app/job-creation/job-creation.actions';
import { JobDescription } from './job-description';

const mapStateToProps = state => {
  return {
    additionalPreference: state.jobCreation.additionalPreference,
    showError: state.jobCreation.showValidationErrors,
    jobId: state.jobCreation.id,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handlePreferenceChange: preferenceText => {
      dispatch(syncAdditionalPreferenceText(preferenceText));
    },
  };
};

const connected = connect(mapStateToProps, mapDispatchToProps)(JobDescription);
export { connected as JobDescriptionContainer };
