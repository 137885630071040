import React from 'react';
import { Input } from 'antd';
import './style.scss';
import { isValidJobDescription } from '../../../../app/job-creation/job-creation.util';

export const JobDescription = ({
  additionalPreference,
  handlePreferenceChange,
  showError,
  jobId = 0,
}) => {
  const handlePreferenceTextChange = ev => {
    handlePreferenceChange(ev.target.value);
  };

  return (
    <div className="job-description" data-cy="job-description">
      <div className="selection-container preference">
        <div className="label">Job description</div>
        <p className="sub-label">
          Provide at least 1-2 sentences to attract more interest and boost your
          meeting success rate.
        </p>
        <Input.TextArea
          data-cy="job-description"
          required
          placeholder={
            'We strongly recommend providing a description to attract more interest and boost the success rate of your meetings.'
          }
          defaultValue={additionalPreference.preference}
          rows={4}
          onBlur={handlePreferenceTextChange}
          showCount
          maxLength={5000}
          minLength={50}
          key={jobId}
        />
        {showError &&
          !isValidJobDescription(additionalPreference.preference) && (
            <div className="error-label">
              Please provide at least 1-2 sentences of 50 chars length
            </div>
          )}
      </div>
    </div>
  );
};
