import { CaretDownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Input, Menu } from 'antd';
import React from 'react';
import { isValidRequiredSkills } from '../../../../app/job-creation/job-creation.util';
import { SkillSelectionRuleType } from '../../../../app/main/main.types';
import { SkillTags } from '../../../main/components/skill-tags/skill-tags';
import { OtherOption } from '../other-option/other-option';
import './style.scss';

export const AdditionalPreference = ({
  skillCategories,
  requiredSkills,
  requiredSkillsList,
  additionalPreference,
  handleOptionalSkillChange,
  handlePositionsChange,
  handlePreferenceChange,
  showError,
}) => {
  const categorySelection = {
    exclusiveSelectedCategory: false,
    singleSelectedCategories: [],
    multipleSelectedCategories: [],
    selectedCategories: [],
  };

  const handleOtherSkillChange = skills => {
    let seen = {},
      uniqueSkillIds = [...getSelectedRequiredSkills(), ...skills].filter(
        item => {
          return seen.hasOwnProperty(item) ? false : (seen[item] = true);
        },
      );
    handleOptionalSkillChange(uniqueSkillIds);
  };

  const handleFixedSkillsChange = skills => {
    if (skills.indexOf(-1) === -1) {
      const otherSkills = getOtherRequiredSkills();
      skills = skills.filter(id => otherSkills.indexOf(id) === -1);
    }
    handleOptionalSkillChange(skills);
  };

  const handlePreferenceTextChange = ev => {
    handlePreferenceChange(ev.target.value);
  };

  const getOtherRequiredSkills = () => {
    const requiredSkillsListIds = requiredSkillsList.map(skill => skill.id);
    return additionalPreference.skills.filter(
      req => requiredSkillsListIds.indexOf(req) === -1,
    );
  };

  const getSelectedRequiredSkills = () => {
    const requiredSkillsListIds = requiredSkillsList.map(skill => skill.id);
    return additionalPreference.skills.filter(
      req => requiredSkillsListIds.indexOf(req) > -1,
    );
  };

  const handleMenuClick = ev => {
    handlePositionsChange(ev.key);
  };

  const positionsMenu = (
    <Menu onClick={handleMenuClick}>
      {Array(10)
        .fill()
        .map((_, i) => i + 1)
        .map(option => (
          <Menu.Item key={option}>
            <span>{option}</span>
          </Menu.Item>
        ))}
    </Menu>
  );

  const requiredSkillsDiff = requiredSkillsList.filter(
    skill => requiredSkills.indexOf(skill.id) === -1,
  );
  return (
    <div
      className="additional-preference"
      data-cy="additional-preference"
      data-testid="additional-preference"
    >
      <div className="selection-container">
        <div className="label">Optional skills</div>
        <div className="sub-label">Select up to 3 options</div>
        <SkillTags
          skills={requiredSkillsDiff}
          onSkillsChange={handleFixedSkillsChange}
          alreadySelectedSkillIds={additionalPreference.skills}
          categoryType={SkillSelectionRuleType.MULTIPLE}
          categorySelection={categorySelection}
        />
        {additionalPreference.skills.indexOf(-1) > -1 && (
          <OtherOption
            optionsList={skillCategories.map(skill => {
              return { value: skill.id, text: skill.skillName };
            })}
            value={getOtherRequiredSkills()}
            handleOnChange={handleOtherSkillChange}
          />
        )}
        {showError && !isValidRequiredSkills(additionalPreference.skills) && (
          <div className="error-label">
            {additionalPreference.skills.length === 0
              ? 'Please select a skill'
              : 'Please select up to 3 skills'}
          </div>
        )}
      </div>
      <div className="selection-container selection-container-inline">
        <div className="container selection-container-inline">
          <div className="label">No. of positions for this role</div>
          <Dropdown overlay={positionsMenu} placement="bottomLeft" arrow>
            <Button>
              {additionalPreference.numPositions} <CaretDownOutlined />{' '}
            </Button>
          </Dropdown>
        </div>
      </div>
      <div className="selection-container preference">
        <div className="label">Job description</div>
        <Input.TextArea
          data-cy="job-description"
          required
          placeholder={
            'We strongly recommend to provide a description as it will attract more interest and boost meeting success rate'
          }
          defaultValue={additionalPreference.preference}
          rows={4}
          onBlur={handlePreferenceTextChange}
          showCount
          maxLength={5000}
        />
      </div>
    </div>
  );
};
