import { Switch } from 'antd';
import React from 'react';
import { useEffect } from 'react';
import { isRequiredField } from '../../../../app/job-creation/job-creation.util';
import { SkillSelectionRuleType } from '../../../../app/main/main.types';
import { SkillTags } from '../../../main/components/skill-tags/skill-tags';
import './style.scss';

export const LocationAndStartDate = ({
  isStrictLocation,
  location,
  continents,
  handleLocationChange,
  handleLocationToggle,
  getContinents,
  showError,
  jobCreationId,
}) => {
  useEffect(() => {
    getContinents();
  }, [getContinents]);

  const categorySelection = {
    exclusiveSelectedCategory: false,
    singleSelectedCategories: [],
    multipleSelectedCategories: [],
    selectedCategories: [],
  };

  const getFormattedContinents = () => {
    return continents
      .map(continent => ({
        id: continent.id,
        skillName: continent.name,
      }))
      .sort((a, b) => (a.skillName < b.skillName ? -1 : 1));
  };

  return (
    <div className="location-and-start-date" data-cy="location-requirement">
      <div className="selection-container">
        <div className="label">
          Do you have a strict location requirement?
          <Switch
            key={jobCreationId}
            defaultChecked={isStrictLocation}
            onChange={handleLocationToggle}
          />
        </div>
        {isStrictLocation && (
          <>
            <p>A minimum of 4 hours of overlap is always guaranteed</p>
            <SkillTags
              alreadySelectedSkillIds={location}
              skills={getFormattedContinents()}
              onSkillsChange={handleLocationChange}
              categoryType={SkillSelectionRuleType.MULTIPLE}
              categorySelection={categorySelection}
            />
            {showError && !isRequiredField(location) && (
              <div className="error-label">Please select a location</div>
            )}
            {showError && location.length === 6 && (
              <div className="error-label">Please select up to 5 locations</div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
