import React from 'react';
import { Dropdown, Menu, Button, Tag } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
import { isRequiredField } from '../../../../app/job-creation/job-creation.util';
import { SkillTags } from '../../../main/components/skill-tags/skill-tags';
import './style.scss';

export const LocationAndHoursOverlap = ({
  isStrictLocation,
  location,
  continents,
  handleLocationChange,
  handleLocationToggle,
  getContinents,
  showError,
  hoursOverlap,
  handleHoursOverlapChange,
}) => {
  useEffect(() => {
    getContinents();
  }, [getContinents]);

  const getFormattedContinents = () => {
    return continents
      .map(continent => ({
        id: continent.id,
        skillName: continent.name,
      }))
      .sort((a, b) => (a.skillName < b.skillName ? -1 : 1));
  };

  const handleMenuClick = ev => {
    handleHoursOverlapChange(ev.key);
  };

  const hoursOverlapMenu = (
    <Menu onClick={handleMenuClick}>
      {[4, 6, 8].map(option => (
        <Menu.Item key={option}>
          <span>
            {option} hrs {option === 4 ? '(recommended)' : ''}
          </span>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div className="location-and-hours-overlap" data-cy="location-requirement">
      <div className="selection-container">
        <div className="label">
          Do you have a strict location requirement?
          <div className="tags-container">
            <Tag
              key={'yes'}
              onClick={() => handleLocationToggle(true)}
              className={`fixed-skill-tag ${
                isStrictLocation
                  ? 'fixed-skill-tag-selected'
                  : 'fixed-skill-tag-unselected'
              }`}
            >
              Yes
            </Tag>
            <Tag
              key={'no'}
              onClick={() => handleLocationToggle(false)}
              className={`fixed-skill-tag ${
                !isStrictLocation
                  ? 'fixed-skill-tag-selected'
                  : 'fixed-skill-tag-unselected'
              }`}
            >
              No
            </Tag>
          </div>
        </div>
        {isStrictLocation && (
          <div className="location-container">
            <div className="contients-container">
              <p className="sub-label">Continents</p>
              <div className="tags-container">
                <SkillTags
                  alreadySelectedSkillIds={location}
                  skills={getFormattedContinents()}
                  onSkillsChange={handleLocationChange}
                />
                {showError && !isRequiredField(location) && (
                  <div className="error-label">Please select a location</div>
                )}
                {showError && location.length === 6 && (
                  <div className="error-label">
                    Please select up to 5 locations
                  </div>
                )}
              </div>
            </div>
            <div className="hours-overlap-container">
              <p className="sub-label">Required hours of overlap</p>
              <Dropdown overlay={hoursOverlapMenu} placement="bottomLeft" arrow>
                <Button>
                  {hoursOverlap} hrs{' '}
                  {hoursOverlap === '4' ? '(recommended)' : ''}{' '}
                  <CaretDownOutlined />
                </Button>
              </Dropdown>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
