import { useEffect } from 'react';
import { PageSettings, ScriptOptions } from './calendly.types';

export const isEventScheduledCalendlyEvent = (event: MessageEvent): boolean => {
  const checkOrigin = process.env.NODE_ENV === 'production';
  return (
    (!checkOrigin || event.origin === 'https://calendly.com') &&
    event.data &&
    event.data.event &&
    event.data.event === 'calendly.event_scheduled'
  );
};

export const isCalendlyEvent = (e: MessageEvent) => {
  return e.data.event && e.data.event.indexOf('calendly') === 0;
};

export const withPageSettings = (
  url: URL,
  pageSettings?: PageSettings,
): string => {
  if (!pageSettings) {
    return url.toString();
  }

  const {
    backgroundColor,
    hideEventTypeDetails,
    hideLandingPageDetails,
    primaryColor,
    textColor,
    hideGdprBanner,
    phoneNumber,
  } = pageSettings;

  const params = url.searchParams;

  if (backgroundColor) {
    params.set('background_color', backgroundColor);
  }
  if (primaryColor) {
    params.set('primary_color', primaryColor);
  }
  if (textColor) {
    params.set('text_color', textColor);
  }
  if (hideEventTypeDetails) {
    params.set('hide_event_type_details', '1');
  }
  if (hideLandingPageDetails) {
    params.set('hide_landing_page_details', '1');
  }
  if (hideGdprBanner) {
    params.set('hide_gdpr_banner', '1');
  }
  if (phoneNumber) {
    params.set('a2', phoneNumber);
  }

  return url.toString();
};

export function useScript(options: ScriptOptions): void {
  useEffect(() => {
    const { src, async = true, onLoad, ...rest } = options;
    const script = document.createElement('script');
    script.async = async;
    if (onLoad) {
      script.onload = onLoad;
    }
    Object.assign(script, rest);
    script.src = src;
    document.body.appendChild(script);
    return (): void => {
      document.body.removeChild(script);
    };
  }, []);
}
