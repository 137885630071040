import { connect } from 'react-redux';
import { syncJobTitle } from '../../../../app/job-creation/job-creation.actions';
import { JobTitle } from './job-title';
import { jobRoleTypes } from '../../../../app/job-creation/job-creation.constants';

const mapStateToProps = state => {
  return {
    title: state.jobCreation.jobTitle,
    showError: state.jobCreation.showValidationErrors,
    roleTypesList: jobRoleTypes,
    roleType: state.jobCreation.roleType,
    owns: state.jobCreation.developerOwns,
    isUserTitle: state.jobCreation.isUserTitle,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleJobTitleChange: (changedTitle, isUserTitle = false) => {
      dispatch(syncJobTitle(changedTitle, isUserTitle));
    },
  };
};

const connected = connect(mapStateToProps, mapDispatchToProps)(JobTitle);
export { connected as JobTitleContainer };
