export const ActionType = {
  GET_INVITE_DETAILS: 'GET_INVITE_DETAILS',
  AUTO_LOGIN_DETAILS: 'AUTO_LOGIN_DETAILS',
  AUTHENTICATE_USER: 'AUTHENTICATE_USER',
  AUTH_ACTION_REQUEST: 'AUTH_ACTION_REQUEST',
  CURRENT_USER: 'CURRENT_USER',
  USER_LOGOUT: 'USER_LOGOUT',
  AUTH_ACTION_COMPLETED: 'AUTH_ACTION_COMPLETED',
  SAVE_USER_ACTION_REQUEST: 'SAVE_USER_ACTION_REQUEST',
  SAVE_USER_ACTION_COMPLETED: 'SAVE_USER_ACTION_COMPLETED',
  AUTO_LOGIN_REQUEST: 'AUTO_LOGIN_REQUEST',
  AUTO_LOGIN_REQUEST_COMPLETED: 'AUTO_LOGIN_REQUEST_COMPLETED',
  SET_CF_SESSION_ID: 'SET_CF_SESSION_ID',
  SET_CF_SESSION: 'SET_CF_SESSION',
};

export const AuthTypes = {
  GOOGLE: 'google',
  FORM: 'form',
};

export const SelfServeUserStatus = {
  PreSignup: 'pre-signup',
  TempSignup: 'temp-signup',
  Registered: 'registered',
  LimitedAccountSignUp: 'limited-account-signup',
};
