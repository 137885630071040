import { action } from 'typesafe-actions';
import { logActivity } from '../user-activity-trail/user-activity-trail.actions';
import { ActivityTypes } from '../user-activity-trail/user-activity-trail.types';
import { ActionType } from './saved-developers.types';
import { RequestService, ToastService } from '../../services';
import { handleFetchError } from '../main/main.actions';
import { getPercentageViewed } from '../../shared/utilties';
import { DDPModalSelector } from '../../shared/constants';

export const getSavedDevelopers = (withLogging = true) => {
  return async (dispatch, getState) => {
    const state = getState();
    if (state.savedDevelopers.isFetching) {
      return;
    }

    if (withLogging) {
      dispatch(
        logActivity(
          ActivityTypes.SAVED_DEVELOPERS.KEY,
          ActivityTypes.SAVED_DEVELOPERS.ACTIONS.GET_SAVED_DEVELOPERS_DETAILED,
          {},
        ),
      );
    }

    dispatch(action(ActionType.GET_SAVED_DEVELOPERS_DETAILED_STARTED));

    try {
      const { savedDevelopers } = await RequestService.get(
        'self-serve-saved-developers',
      );
      dispatch(
        action(ActionType.GET_SAVED_DEVELOPERS_DETAILED, {
          savedDevelopers,
        }),
      );
    } catch (e) {
      dispatch(handleFetchError(e));
      if (withLogging) {
        ToastService.error(
          'Error occurred while fetching saved developers data',
          e,
        );
      }
    }

    dispatch(action(ActionType.GET_SAVED_DEVELOPERS_DETAILED_FINISHED));
  };
};

export const saveDeveloper = ({
  developerIdHash,
  developerId,
  developerName,
  rankingUuid,
  jobId,
  source = '',
}) => {
  return async (dispatch, getSate) => {
    dispatch(
      logActivity(
        ActivityTypes.SAVED_DEVELOPERS.KEY,
        ActivityTypes.SAVED_DEVELOPERS.ACTIONS.SAVE_DEVELOPER,
        {
          rankingUuid,
          developerId,
          percentageDDPViewed: getPercentageViewed(DDPModalSelector),
          jobId,
          source,
        },
        developerId,
      ),
    );
    try {
      const { savedDeveloper } = await RequestService.post(
        `self-serve-saved-developers/${developerIdHash}`,
        {
          jobId,
        },
      );

      const state = getSate();
      const job = state.jobSelection.jobs.find(cJob => cJob.id === jobId);

      dispatch(
        action(ActionType.SAVE_DEVELOPER, {
          savedDeveloper,
          jobId,
          developerId,
        }),
      );
      ToastService.success(
        `${developerName} was added to your Saved Developers list against job ${job.name}`,
      );
    } catch (e) {
      ToastService.error('Error occured while saving developer', e);
    }
  };
};

export const updateDeveloperNote = ({
  savedId,
  developerId,
  note,
  savedAgainstJob,
}) => {
  return async dispatch => {
    dispatch(
      logActivity(
        ActivityTypes.SAVED_DEVELOPERS.KEY,
        ActivityTypes.SAVED_DEVELOPERS.ACTIONS.UPDATE_DEVELOPER_NOTE,
        {
          developerId,
          note,
          savedId,
          savedAgainstJob,
        },
        developerId,
      ),
    );
    dispatch(action(ActionType.UPDATE_DEVELOPER_NOTE_STARTED));

    try {
      await RequestService.put(`self-serve-saved-developers/${savedId}`, {
        note,
      });
      dispatch(
        action(ActionType.UPDATE_DEVELOPER_NOTE, {
          developerId,
          note,
          savedAgainstJob,
        }),
      );
    } catch (e) {
      ToastService.error('Error occured while updating developer note', e);
    }

    dispatch(action(ActionType.UPDATE_DEVELOPER_NOTE_FINISHED));
  };
};

export const unsaveDeveloper = ({
  savedAgainstJob,
  savedId,
  developerId,
  jobId,
}) => {
  return async dispatch => {
    dispatch(
      logActivity(
        ActivityTypes.SAVED_DEVELOPERS.KEY,
        ActivityTypes.SAVED_DEVELOPERS.ACTIONS.UNSAVE_DEVELOPER,
        { jobId },
        developerId,
      ),
    );
    try {
      await RequestService.delete(`self-serve-saved-developers/${savedId}`);
      dispatch(
        action(ActionType.UNSAVE_DEVELOPER, {
          developerId,
          savedAgainstJob,
        }),
      );
    } catch (e) {
      ToastService.error('Error occured while un-saving developer', e);
    }
  };
};
