import { connect } from 'react-redux';
import {
  createANewJob,
  navigateBack,
  navigateNext,
  postAdditionalPreferences,
  setShowErrors,
  unSetJobCreationMode,
  syncMinExperience,
} from '../../../../app/job-creation/job-creation.actions';
import { selectAllJobs } from '../../../../app/job-selection/jobs.selection';
import { CreateJobModalV2 } from './create-job-modal-v2';

const mapStateToProps = state => {
  return {
    currentPage: state.jobCreation.currentPage,
    lastPage: state.jobCreation.lastPage,
    jobStatus: state.jobCreation.jobStatus,
    jobConfig: state.jobCreation,
    currentUser: state.auth.currentUser,
    jobs: selectAllJobs(state),
    enableJobCreation: state.jobCreation.enableJobCreation,
    requiredSkillsList: state.jobCreation.requiredSkillsList,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleNavigateNext: (currentPage, jobConfig) => {
      dispatch(navigateNext(currentPage, jobConfig));
    },
    handleNavigateBack: () => {
      dispatch(navigateBack());
    },
    handleJobCreationClick: () => {
      dispatch(createANewJob());
    },
    handleSaveChangesClick: id => {
      dispatch(postAdditionalPreferences(id));
    },
    handleSkipClick: () => {
      dispatch(unSetJobCreationMode());
    },
    showValidationError: () => {
      dispatch(setShowErrors());
    },
    handleMinExperienceChange: minExperience => {
      dispatch(syncMinExperience(minExperience));
    },
  };
};

const connected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateJobModalV2);
export { connected as CreateJobModalV2Container };
