import { immerable } from 'immer';

export const ActionType = {
  SET_CURRENT_DEVELOPER_HASH: 'SET_CURRENT_DEVELOPER_HASH',
  GET_PROFILE_HEADER: 'GET_PROFILE_HEADER',
  GET_PROFILE_HEADER_V2: 'GET_PROFILE_HEADER_V2',
  GET_PROFILE_LOGO: 'GET_PROFILE_LOGO',
  PROFILE_ACTION_REQUEST: 'PROFILE_ACTION_REQUEST',
  PROFILE_ACTION_COMPLETED: 'PROFILE_ACTION_COMPLETED',
  GET_PROFILE_AVAILABILITY: 'GET_PROFILE_AVAILABILITY',
  GET_PROFILE_EDUCATION: 'GET_PROFILE_EDUCATION',
  GET_PROFILE_PUBLICATIONS: 'GET_PROFILE_PUBLICATIONS',
  GET_PROFILE_CERTIFICATIONS: 'GET_PROFILE_CERTIFICATIONS',
  GET_PROFILE_SIDE_PROJECTS: 'GET_PROFILE_SIDE_PROJECTS',
  GET_PROFILE_WORK_HISTORY: 'GET_PROFILE_WORK_HISTORY',
  GET_PROFILE_ENGAGEMENTS: 'GET_PROFILE_ENGAGEMENTS',
  GET_SELF_SERVE_PROFILE_DETAILS: 'GET_SELF_SERVE_PROFILE_DETAILS',
  GET_SELF_SERVE_OPEN_DEVELOPER_PROFILE:
    'GET_SELF_SERVE_OPEN_DEVELOPER_PROFILE',
  CLEAR_SELF_SERVE_OPEN_DEVELOPER_PROFILE:
    'CLEAR_SELF_SERVE_OPEN_DEVELOPER_PROFILE',
  GET_PROFILE_SKILLS: 'GET_PROFILE_SKILLS',
  GET_PROFILE_SENIORITY: 'GET_PROFILE_SENIORITY',
  GET_PROFILE_INTERVIEW: 'GET_PROFILE_INTERVIEW',
  GET_LIVE_CODING_INTERVIEW: 'GET_LIVE_CODING_INTERVIEW',
  GET_YEARS_OF_EXPERIENCE: 'GET_YEARS_OF_EXPERIENCE',
  GET_PROFILE_CLOUD_CERTIFICATIONS: 'GET_PROFILE_CLOUD_CERTIFICATIONS',
  ENABLE_DDP_OPEN_REQUEST: 'ENABLE_DDP_OPEN_REQUEST',
  CANCEL_DDP_OPEN_REQUEST: 'CANCEL_DDP_OPEN_REQUEST',
  GET_PROFILE_TURING_ENGAGEMENTS: 'GET_PROFILE_TURING_ENGAGEMENTS',
};

export class DeveloperProfile {
  [immerable] = true;
  header = {};
  availability = '';
  workHistory = [];
  sideProjects = [];
  certifications = [];
  awards = [];
  publications = [];
  skills = [];
  experienceOnTuring = {};
  workExperience = {};
  selfServeDeveloperDetails = {};
  interviewDetail = {};
  yearsOfExperience = {};
  cloudCertifications = [];
  turingEngagements = [];
}
export const RequestType = {
  HEADER: 'HEADER',
  LOGO: 'LOGO',
  AVAILABILITY: 'AVAILABILITY',
  WORK_HISTORY: 'WORK_HISTORY',
  SIDE_PROJECTS: 'SIDE_PROJECTS',
  PUBLICATIONS: 'PUBLICATIONS',
  EDUCATION: 'EDUCATION',
  CERTIFICATIONS: 'CERTIFICATIONS',
  SKILLS: 'SKILLS',
  ENGAGEMENTS: 'ENGAGEMENTS',
  SELF_SERVE_DETAILS: 'SELF_SERVE_DETAILS',
  SENIORITY: 'SENIORITY',
  INTERVIEW: 'INTERVIEW',
  LIVE_CODING_VIDEO: 'LIVE_CODING_VIDEO',
  YEARS_OF_EXPERIENCE: 'YEARS_OF_EXPERIENCE',
  CLOUD_CERTIFICATIONS: 'CLOUD_CERTIFICATIONS',
  TURING_ENGAGEMENTS: 'TURING_ENGAGEMENTS',
};
