import produce from 'immer';
import { ActionType } from './recently-viewed-developers.types';

const initialState = {
  developersViewed: [],
  developersViewedCount: 0,
  isFetching: false,
  page: 1,
  timezones: [],
};

export const recentlyViewedDevelopersReducer = (
  state = initialState,
  action,
) => {
  return produce(state, draftState => {
    if (action.type === ActionType.RESET_RECENTLY_VIEWED_DEVELOPERS) {
      draftState.developersViewed = [];
      draftState.developersViewedCount = 0;
      draftState.isFetching = false;
      draftState.page = 1;
    }
    if (action.type === ActionType.GET_RECENTLY_VIEWED_DEVELOPERS_STARTED) {
      draftState.isFetching = true;
    }
    if (action.type === ActionType.GET_RECENTLY_VIEWED_DEVELOPERS_COMPLETED) {
      draftState.isFetching = false;
    }
    if (action.type === ActionType.SET_RECENTLY_VIEWED_DEVELOPERS) {
      const { developersViewed, developersViewedCount, page } = action.payload;
      draftState.developersViewed = [
        ...draftState.developersViewed,
        ...developersViewed,
      ];
      draftState.developersViewedCount = developersViewedCount;
      draftState.page = +page;
    }
    if (action.type === ActionType.SET_TIMEZONES) {
      draftState.timezones = action.payload;
    }
  });
};
