import { Input } from 'antd';
import React, { useEffect } from 'react';
import { roleTypeIdMap } from '../../../../app/job-creation/job-creation.constants';
import {
  isValidRequiredSkills,
  isValidRole,
} from '../../../../app/job-creation/job-creation.util';
import { SkillSelectionRuleType } from '../../../../app/main/main.types';
import { SkillTags } from '../../../main/components/skill-tags/skill-tags';
import { OtherOption } from '../other-option/other-option';
import './style.scss';

export const RoleAndSkills = ({
  skillCategories,
  roleTypesList,
  roleType,
  requiredSkills,
  skillLabel = 'Required skills',
  handleRoleTypeChange,
  handleRequiredSkillsChange,
  handleOtherRoleTypeChange,
  requiredSkillsList,
  getAllSkills,
  showError,
  roleLabel = 'What role type are you looking for?',
}) => {
  useEffect(() => {
    getAllSkills();
  }, []);

  const categorySelection = {
    exclusiveSelectedCategory: false,
    singleSelectedCategories: [],
    multipleSelectedCategories: [],
    selectedCategories: [],
  };

  const handleOtherRoleChange = ev => {
    handleOtherRoleTypeChange(ev.target.value);
  };

  const handleOtherSkillChange = skills => {
    let seen = {},
      uniqueSkillIds = [...getSelectedRequiredSkills(), ...skills].filter(
        item => {
          return seen.hasOwnProperty(item) ? false : (seen[item] = true);
        },
      );
    handleRequiredSkillsChange(uniqueSkillIds);
  };

  const handleFixedSkillsChange = skills => {
    if (skills.indexOf(-1) === -1) {
      const otherSkills = getOtherRequiredSkills();
      skills = skills.filter(id => otherSkills.indexOf(id) === -1);
    }
    handleRequiredSkillsChange(skills);
  };

  const getOtherRequiredSkills = () => {
    const requiredSkillsListIds = requiredSkillsList.map(skill => skill.id);
    return requiredSkills.filter(
      req => requiredSkillsListIds.indexOf(req) === -1,
    );
  };

  const getSelectedRequiredSkills = () => {
    const requiredSkillsListIds = requiredSkillsList.map(skill => skill.id);
    return requiredSkills.filter(
      req => requiredSkillsListIds.indexOf(req) > -1,
    );
  };

  return (
    <div className="role-and-skills">
      <div className="selection-container" data-cy="role-type">
        <div className="label">{roleLabel}</div>
        <SkillTags
          skills={roleTypesList}
          onSkillsChange={handleRoleTypeChange}
          alreadySelectedSkillIds={[roleType.id]}
          categoryType={SkillSelectionRuleType.SINGLE}
          categorySelection={categorySelection}
        />
        {roleType.id === roleTypeIdMap.OTHER && (
          <div className="other-role">
            <div>Other (please specify)</div>
            <Input
              onChange={handleOtherRoleChange}
              value={roleType.otherValue}
              className={
                showError && !isValidRole(roleType) ? 'error-border' : ''
              }
            />
          </div>
        )}
        {showError && !isValidRole(roleType) && (
          <div className="error-label">
            {roleType.id === roleTypeIdMap.OTHER
              ? 'Please enter a job role'
              : 'Please select a job role'}
          </div>
        )}
      </div>
      <div className="selection-container" data-cy="required-skills">
        <div className="label required-skill-label">{skillLabel}</div>
        <div className="sub-label">Select up to 3 options</div>
        <SkillTags
          skills={requiredSkillsList}
          onSkillsChange={handleFixedSkillsChange}
          alreadySelectedSkillIds={requiredSkills}
          categoryType={SkillSelectionRuleType.MULTIPLE}
          categorySelection={categorySelection}
        />
        {requiredSkills.indexOf(-1) > -1 && (
          <OtherOption
            optionsList={skillCategories.map(skill => {
              return { value: skill.id, text: skill.skillName };
            })}
            value={getOtherRequiredSkills()}
            handleOnChange={handleOtherSkillChange}
          />
        )}
        {showError && !isValidRequiredSkills(requiredSkills) && (
          <div className="error-label">
            {requiredSkills.length === 0
              ? 'Please select a skill'
              : 'Please select up to 3 skills'}
          </div>
        )}
      </div>
    </div>
  );
};
