import { AuthTypes } from '../app/auth/auth.types';

const authKey = 'self_serve_login_token';
const authTypeKey = 'self_serve_login_auth_type';

export const AuthorizationService = {
  getAuthPayload: () => {
    return {
      token: localStorage.getItem(authKey),
      authType: localStorage.getItem(authTypeKey) || AuthTypes.FORM,
    };
  },
  setToken: ({ token, authType }) => {
    localStorage.setItem(authKey, token);
    localStorage.setItem(authTypeKey, authType);
  },
  isLoggedIn: () => {
    return !!AuthorizationService.getAuthPayload().token;
  },
};
