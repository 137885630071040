import { connect } from 'react-redux';
import {
  syncAdditionalPreferencePositions,
  syncAdditionalPreferenceSkills,
  syncAdditionalPreferenceText,
} from '../../../../app/job-creation/job-creation.actions';
import { AdditionalPreference } from './additional-preference';

const mapStateToProps = state => {
  return {
    skillCategories: state.main.allSkills,
    requiredSkills: state.jobCreation.requiredSkills,
    requiredSkillsList: state.jobCreation.requiredSkillsList,
    additionalPreference: state.jobCreation.additionalPreference,
    showError: state.jobCreation.showValidationErrors,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleOptionalSkillChange: optionalSkill => {
      dispatch(syncAdditionalPreferenceSkills(optionalSkill));
    },
    handlePositionsChange: numPositions => {
      dispatch(syncAdditionalPreferencePositions(numPositions));
    },
    handlePreferenceChange: preferenceText => {
      dispatch(syncAdditionalPreferenceText(preferenceText));
    },
  };
};

const connected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdditionalPreference);
export { connected as AdditionalPreferenceContainer };
