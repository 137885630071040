export const ActionType = {
  ENABLE_JOB_CREATION_MODAL: 'ENABLE_JOB_CREATION_MODAL',
  DISABLE_JOB_CREATION_MODAL: 'DISABLE_JOB_CREATION_MODAL',
  NAVIGATE_NEXT: 'NAVIGATE_NEXT',
  NAVIGATE_BACK: 'NAVIGATE_BACK',
  SET_JOB_TITLE: 'SET_JOB_TITLE',
  SET_DEVELOPER_OWNS: 'SET_DEVELOPER_OWNS',
  SET_MIN_EXPERIENCE: 'SET_MIN_EXPERIENCE',
  SET_STRICT_BUDGET: 'SET_STRICT_BUDGET',
  SET_BUDGET_VALUE: 'SET_BUDGET_VALUE',
  SET_STRICT_LOCATION: 'SET_STRICT_LOCATION',
  SET_LOCATION_VALUE: 'SET_LOCATION_VALUE',
  SET_JOB_ROLE_TYPES: 'SET_JOB_ROLE_TYPES',
  SET_ROLE_TYPE: 'SET_ROLE_TYPE',
  SET_REQUIRED_SKILLS: 'SET_REQUIRED_SKILLS',
  SET_OTHER_ROLE_TYPE: 'SET_OTHER_ROLE_TYPE',
  SET_REQUIRED_SKILLS_LIST: 'SET_REQUIRED_SKILLS_LIST',
  SET_ADDITIONAL_PREFERENCE_SKILLS: 'SET_ADDITIONAL_PREFERENCE_SKILLS',
  SET_ADDITIONAL_PREFERENCE_POSITIONS: 'SET_ADDITIONAL_PREFERENCE_POSITIONS',
  SET_ADDITIONAL_PREFERENCE_TEXT: 'SET_ADDITIONAL_PREFERENCE_TEXT',
  CREATE_NEW_JOB: 'CREATE_NEW_JOB',
  CREATE_NEW_JOB_SUCCESS: 'CREATE_NEW_JOB_SUCCESS',
  CREATE_NEW_JOB_FAILURE: 'CREATE_NEW_JOB_FAILURE',
  ENABLE_JOB_EDIT_MODE: 'ENABLE_JOB_EDIT_MODE',
  DISABLE_JOB_EDIT_MODE: 'DISABLE_JOB_EDIT_MODE',
  EDIT_JOB: 'EDIT_JOB',
  EDIT_JOB_SUCCESS: 'EDIT_JOB_SUCCESS',
  EDIT_JOB_FAILURE: 'EDIT_JOB_FAILURE',
  SAVE_ADDITIONAL_PREFERENCE_STARTED: 'SAVE_ADDITIONAL_PREFERENCE_STARTED',
  SAVE_ADDITIONAL_PREFERENCE_COMPLETED: 'SAVE_ADDITIONAL_PREFERENCE_COMPLETED',
  SET_SHOW_ERRORS: 'SET_SHOW_ERRORS',
  UNSET_SHOW_ERRORS: 'UNSET_SHOW_ERRORS',
  DUPLICATE_JOB: 'DUPLICATE_JOB',
  SET_OPTIONAL_SKILLS: 'SET_OPTIONAL_SKILLS',
  SET_HOURS_OVERLAP: 'SET_HOURS_OVERLAP',
};
