import { action } from 'typesafe-actions';
import { logActivity } from '../user-activity-trail/user-activity-trail.actions';
import { ActivityTypes } from '../user-activity-trail/user-activity-trail.types';
import { ActionType } from './handpicked-developers.types';
import { RequestService, ToastService } from '../../services';
import { handleFetchError } from '../main/main.actions';

export const getHandpickedDevelopers = () => {
  return async (dispatch, getState) => {
    const state = getState();
    if (
      Object.keys(state.handpickedDevelopers.handpickedDevelopers || {})
        .length > 0
    ) {
      return;
    }
    dispatch(
      logActivity(
        ActivityTypes.HANDPICKED_DEVELOPERS.KEY,
        ActivityTypes.HANDPICKED_DEVELOPERS.ACTIONS.GET_HANDPICKED_DEVELOPERS,
        {},
      ),
    );
    dispatch(action(ActionType.GET_HANDPICKED_DEVELOPERS_STARTED));

    try {
      const { packets: allPackets } = await RequestService.get(
        'interactive-packets',
      );
      dispatch(
        action(ActionType.GET_HANDPICKED_DEVELOPERS, {
          allPackets,
        }),
      );
    } catch (e) {
      dispatch(handleFetchError(e));
      ToastService.error(
        'Error occurred while fetching handpicked developers data',
        e,
      );
    }

    dispatch(action(ActionType.GET_HANDPICKED_DEVELOPERS_FINISHED));
  };
};

export const getHandpickedDevelopersForDashboard = () => {
  return async (dispatch, getState) => {
    dispatch(
      logActivity(
        ActivityTypes.HANDPICKED_DEVELOPERS.KEY,
        ActivityTypes.HANDPICKED_DEVELOPERS.ACTIONS
          .GET_HANDPICKED_DEVELOPERS_FOR_DASHBOARD,
        {},
      ),
    );
    dispatch(action(ActionType.GET_HANDPICKED_DEVELOPERS_STARTED));

    try {
      const allPackets = await RequestService.get(
        'interactive-packets/dashboard',
      );
      dispatch(
        action(ActionType.GET_HANDPICKED_DEVELOPERS_FOR_DASHBOARD, {
          allPackets,
        }),
      );
    } catch (e) {
      dispatch(handleFetchError(e));
      ToastService.error(
        'Error occurred while fetching handpicked developers data',
        e,
      );
    }

    dispatch(action(ActionType.GET_HANDPICKED_DEVELOPERS_FINISHED));
  };
};
