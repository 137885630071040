import { connect } from 'react-redux';
import { syncDeveloperOwns } from '../../../../app/job-creation/job-creation.actions';
import { DeveloperResponsibility } from './dev-responsibility';

const mapStateToProps = state => {
  return {
    owns: state.jobCreation.developerOwns,
    showError: state.jobCreation.showValidationErrors,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleDeveloperOwnsChange: owns => {
      dispatch(syncDeveloperOwns(owns));
    },
  };
};

const connected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeveloperResponsibility);
export { connected as DeveloperResponsibilityContainer };
