import { connect } from 'react-redux';
import { syncAdditionalPreferencePositions } from '../../../../app/job-creation/job-creation.actions';
import { JobPositions } from './job-positions';

const mapStateToProps = state => {
  return {
    additionalPreference: state.jobCreation.additionalPreference,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handlePositionsChange: numPositions => {
      dispatch(syncAdditionalPreferencePositions(numPositions));
    },
  };
};

const connected = connect(mapStateToProps, mapDispatchToProps)(JobPositions);
export { connected as JobPositionsContainer };
