export const ActionType = {
  CF_REGISTER_START: 'CF_REGISTER_START',
  CF_REGISTER_FAIL: 'CF_REGISTER_FAIL',
  CF_REGISTER_COMPLETE: 'CF_REGISTER_COMPLETE',
  CALENDLY_START: 'CALENDLY_START',
  CALENDLY_FAIL: 'CALENDLY_FAIL',
  CALENDLY_COMPLETE: 'CALENDLY_COMPLETE',
  OPEN_MODAL: 'OPEN_MODAL',
  CLOSE_MODAL: 'CLOSE_MODAL',
  CALL_BOOKED: 'CALL_BOOKED',
  SET_CALENDLY_LOADED: 'SET_CALENDLY_LOADED',
};

export const NativeBookACallTestConstants = {
  noNativeModalContainerId: 'no-native-modal-container',
  nativeModalContainerId: 'native-modal-container',
  closeNativeModalBtn: 'close-native-modal-btn',
};
