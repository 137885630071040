import { connect } from 'react-redux';
import { TuringBootstrap } from './turing-bootstrap';
import { initializeUserProfile } from '../../app/main/main.actions';
import { selectAllJobs } from '../../app/job-selection/jobs.selection';
import { switchCurrentJob } from '../../app/job-selection/job-selection.actions';
import { setAppFlags } from '../../app/flags/flags.actions';
import { setJobCreationMode } from '../../app/job-creation/job-creation.actions';

const mapStateToProps = (state, props) => {
  return {
    initializationState: state.main.initializationState,
    currentUser: state.auth.currentUser,
    currentJobId: state.jobSelection.currentJobId,
    jobs: selectAllJobs(state, props),
    flags: state.flags,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setAppFlags: appFlags => {
      dispatch(setAppFlags(appFlags));
    },

    initializeUserProfile: () => {
      dispatch(initializeUserProfile());
    },

    switchCurrentJob: jobId => {
      dispatch(switchCurrentJob(jobId));
    },

    setJobCreationMode: (source, isLimitedAccount, enableJobFlowV2) => {
      dispatch(setJobCreationMode(source, isLimitedAccount, enableJobFlowV2));
    },
  };
};

const connected = connect(mapStateToProps, mapDispatchToProps)(TuringBootstrap);
export { connected as TuringBootstrapContainer };
