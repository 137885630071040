import produce from 'immer';
import { ActionType } from './auth.types';
import { AuthorizationService } from '../../services/AuthService';

const initialState = {
  inviteDetails: null,
  isFetching: false,
  currentUser: null,
  saveOngoing: false,
  autoLoginFetching: false,
};

export const authReducer = (state = initialState, action) => {
  return produce(state, draftState => {
    if (action.type === ActionType.GET_INVITE_DETAILS) {
      draftState.inviteDetails = action.payload;
    }

    if (action.type === ActionType.AUTH_ACTION_REQUEST) {
      draftState.isFetching = true;
    }

    if (action.type === ActionType.AUTO_LOGIN_REQUEST) {
      draftState.autoLoginFetching = true;
    }

    if (action.type === ActionType.AUTO_LOGIN_REQUEST_COMPLETED) {
      draftState.autoLoginFetching = false;
    }

    if (action.type === ActionType.AUTH_ACTION_COMPLETED) {
      draftState.isFetching = false;
    }

    if (action.type === ActionType.SAVE_USER_ACTION_REQUEST) {
      draftState.saveOngoing = true;
    }

    if (action.type === ActionType.SAVE_USER_ACTION_COMPLETED) {
      draftState.saveOngoing = false;
    }

    if (action.type === ActionType.CURRENT_USER) {
      const { user, jobs, singleUserForAllConnectedJobs } = action.payload;
      draftState.currentUser = user;
      if (user) {
        draftState.currentUser.jobsCount = jobs ? jobs.length : 0;
        draftState.currentUser.singleUserForAllConnectedJobs =
          singleUserForAllConnectedJobs;
      }
    }

    if (action.type === ActionType.AUTHENTICATE_USER) {
      const { token, authType } = action.payload;
      AuthorizationService.setToken({
        token,
        authType,
      });
      draftState.isFetching = false;
    }

    if (action.type === ActionType.SET_CF_SESSION_ID) {
      draftState.currentUser.cfSessionId = action.payload.cfSessionId;
    }

    if (action.type === ActionType.SET_CF_SESSION) {
      draftState.currentUser.cfSession = action.payload.cfSession;
    }
  });
};
