import { RequestService } from '../../services';
import { action } from 'typesafe-actions';
import { ActionType } from './native-book-a-call.types';
import { ActionType as AuthActionTypes } from './../../app/auth/auth.types';
import { logActivity } from '../../app/user-activity-trail/user-activity-trail.actions';
import { ActivityTypes } from '../../app/user-activity-trail/user-activity-trail.types';
import { turingLogger } from '../../shared/TuringLogger';
import { getCookieValues } from '../../shared/utilties';

export const getCFSessionId = (customerFlowLink: string) => {
  return async dispatch => {
    dispatch(action(ActionType.CF_REGISTER_START));
    try {
      const { sessionId, visitorId } = turingLogger.getSessionTokenNonce();
      const visitorTokenCookie = getCookieValues('visitor');
      const queryPayload = {
        canRunAds: false,
        fbPixelCanRun: false,
        isBot: false,
        previousUrl: customerFlowLink,
        screenHeight: window.screen.availHeight,
        screenWidth: window.screen.availWidth,
        sourceUrl: customerFlowLink,
        windowHeight: window.innerHeight,
        windowWidth: window.innerWidth,
        sessionNonce: sessionId,
        visitorNonce: visitorId,
        visitorToken: visitorTokenCookie?.['visitortoken'] ?? '',
      };
      const url = 'self-serve-user/get-cf-session-id';
      const res = await RequestService.post(url, queryPayload);
      dispatch(action(ActionType.CF_REGISTER_COMPLETE));
      dispatch(action(AuthActionTypes.SET_CF_SESSION_ID, res));
    } catch (e) {
      console.error('[getCFSessionId]', e);
      dispatch(action(ActionType.CF_REGISTER_FAIL));
    }
  };
};

export const getCFSession = () => {
  return async dispatch => {
    try {
      const url = 'self-serve-user/cf-session';
      const res = await RequestService.get(url);
      dispatch(action(AuthActionTypes.SET_CF_SESSION, res));
    } catch (e) {
      console.error('[getCFSessionFailed]', e);
    }
  };
};

export const openNativeBookACallModal = ({ source }) => {
  return async dispatch => {
    dispatch(action(ActionType.OPEN_MODAL, { source }));
  };
};

export const logNativeCallBooked = (payload = {}) => {
  return async dispatch => {
    dispatch(
      logActivity(
        ActivityTypes.LIMITED_ACCOUNT.KEY,
        ActivityTypes.LIMITED_ACCOUNT.ACTIONS.NATIVE_CALL_BOOKED,
        payload,
      ),
    );
  };
};

export const setCallBookedStatus = () => {
  return async dispatch => {
    dispatch(action(ActionType.CALL_BOOKED));
  };
};

export const setCalendlyLoadedStatus = () => {
  return async dispatch => {
    dispatch(action(ActionType.SET_CALENDLY_LOADED));
  };
};

export const closeNativeBookACallModal = () => {
  return async dispatch => {
    dispatch(action(ActionType.CLOSE_MODAL));
    dispatch(getCFSession());
  };
};
