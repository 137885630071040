export const getUTCOffsetNumber = num => {
  if (num > 0) {
    return '+';
  }
  if (num < 0) {
    return '-';
  }
  return num.toString();
};

export const getConvertedTime = num => {
  const hours = Math.floor(num / 60),
    minutes = num % 60;
  if (hours < 10 && minutes < 10) {
    return `0${hours}:0${minutes}`;
  }
  if (hours < 10 && minutes > 10) {
    return `0${hours}:${minutes}`;
  }
  if (hours > 10 && minutes < 10) {
    return `${hours}:0${minutes}`;
  }
  return `${hours}:${minutes}`;
};

export const getReOrderedTimezonesByPopularity = timezones => {
  const popularTimezones = [
    'America/Los_Angeles',
    'America/New_York',
    'America/Chicago',
    'America/Denver',
    'Asia/Calcutta',
  ];
  let popularTimezonesLists = [],
    popularTimezonesKeys = [];
  timezones.forEach(timezone => {
    if (
      popularTimezones.filter(
        popularTimezone => timezone.value.indexOf(popularTimezone) > -1,
      ).length
    ) {
      popularTimezonesLists.push(timezone);
      popularTimezonesKeys.push(timezone.key);
    }
  });
  timezones = [
    ...popularTimezonesLists,
    ...timezones.filter(
      timezone => popularTimezonesKeys.indexOf(timezone.key) === -1,
    ),
  ];
  return timezones;
};
