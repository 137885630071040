import { CaretDownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import React from 'react';
import './style.scss';

export const ExperienceSelector = ({
  experienceValue,
  experienceLabel,
  handleExperienceChange,
}) => {
  const minExperienceOptions = [
    {
      id: -1,
      value: 'Any',
    },
    {
      id: 1,
      value: '1 year',
      subValue: 'No fresh grads no matter how talented',
    },
    {
      id: 2,
      value: '2+ years',
      subValue:
        'No exceptions even for highly skilled and talented software developers',
    },
    {
      id: 3,
      value: '3+ years',
      subValue:
        'No exceptions even for highly skilled and talented software developers',
    },
    {
      id: 4,
      value: '4+ years',
      subValue:
        'No exceptions even for highly skilled and talented software developers',
    },
    {
      id: 5,
      value: '5+ years',
      subValue:
        'No exceptions even for highly skilled and talented software developers',
    },
    {
      id: 7,
      value: '7+ years',
      subValue:
        'No exceptions even for highly skilled and talented software developers',
    },
    {
      id: 10,
      value: '10+ years',
      subValue:
        'No exceptions even for highly skilled and talented software developers',
    },
  ];

  const handleMenuClick = ev => {
    handleExperienceChange(ev.key);
  };

  const experienceMenu = (
    <Menu onClick={handleMenuClick}>
      {minExperienceOptions.map(option => (
        <Menu.Item key={option.id}>
          <span>{option.value}</span>
        </Menu.Item>
      ))}
    </Menu>
  );

  const getSelectedOptionLabel = () => {
    if (experienceValue === null || experienceValue === -1) return 'Any';
    const selectedOption = minExperienceOptions.filter(
      option => option.id === +experienceValue,
    );
    return selectedOption.length > 0 ? selectedOption[0].value : '';
  };

  return (
    <div className="experience-selector">
      <div className="label">{experienceLabel}</div>
      <Dropdown overlay={experienceMenu} placement="bottomLeft" arrow>
        <Button data-cy="experience-selector-trigger">
          {getSelectedOptionLabel() || 'Select'} <CaretDownOutlined />{' '}
        </Button>
      </Dropdown>
    </div>
  );
};
