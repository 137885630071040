import { message } from 'antd';
import { action } from 'typesafe-actions';
import { HeapService, RequestService, ToastService } from '../../services';
import { AuthorizationService } from '../../services/AuthService';
import { turingLogger } from '../../shared/TuringLogger';
import { setGBUserFlags } from '../../shared/growth-book';
import { getRedirectUrl, ssParametersToFilter } from '../../shared/utilties';
import { history } from '../../store/history';
import { logEvent } from '../google-tag-manager/gtm-actions';
import { GtmEventTypes, GtmVariables } from '../google-tag-manager/gtm-types';
import { setAllJobs } from '../job-selection/job-selection.actions';
import {
  LSA_DDP_PROFILE_VIEW_CAP,
  ActionType as MainActions,
} from '../main/main.types';
import { logActivity } from '../user-activity-trail/user-activity-trail.actions';
import { ActivityTypes } from '../user-activity-trail/user-activity-trail.types';
import { ActionType } from './auth.types';

export const getInviteHeaders = token => {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('Authorization', 'Bearer ' + token);
  return headers;
};

export const getInviteDetails = (token, urlParams) => {
  return async dispatch => {
    dispatch(action(ActionType.AUTH_ACTION_REQUEST));
    const headers = getInviteHeaders(token);
    try {
      const { invite } = await RequestService.get(
        'self-serve-invite/details',
        headers,
      );
      dispatch(action(ActionType.GET_INVITE_DETAILS, invite));
    } catch (e) {
      history.push('/sign-in');
      dispatch(
        logActivity(
          ActivityTypes.SIGN_UP.KEY,
          ActivityTypes.SIGN_UP.ACTIONS.INVITE_LINK_INVALID,
          { urlParams },
          null,
          headers,
        ),
      );
    }
    dispatch(action(ActionType.AUTH_ACTION_COMPLETED));
  };
};

export const autoLogin = (signupToken, queryString) => {
  return async dispatch => {
    dispatch(action(ActionType.AUTO_LOGIN_REQUEST));
    const authToken = AuthorizationService.getAuthPayload().token;
    const headers = getInviteHeaders(signupToken);
    try {
      const autoLoginResponse = await RequestService.post(
        'self-serve-user/auto-login',
        {
          signupToken,
          authToken,
          queryString,
        },
        headers,
      );
      if (autoLoginResponse?.authToken) {
        AuthorizationService.setToken({
          token: autoLoginResponse?.authToken,
        });
      }
      if (autoLoginResponse?.redirectUri) {
        history.push(autoLoginResponse.redirectUri);
      }

      if (autoLoginResponse?.redirectUriAfterLogin) {
        sessionStorage.setItem(
          'redirectUriAfterLogin',
          autoLoginResponse?.redirectUriAfterLogin,
        );
      }
    } catch (e) {
      history.push('/sign-in');
      dispatch(
        logActivity(
          ActivityTypes.PACKET_AUTO_LOGIN.KEY,
          ActivityTypes.PACKET_AUTO_LOGIN.ACTIONS.AUTO_LOGIN_ERROR,
          { signupToken, authToken },
          null,
          headers,
        ),
      );
    }
    dispatch(action(ActionType.AUTO_LOGIN_REQUEST_COMPLETED));
  };
};

export const registerUser = (
  { fullName, email, password, companyName, token, source = '' },
  urlParams,
) => {
  return async dispatch => {
    dispatch(action(ActionType.AUTH_ACTION_REQUEST));
    const headers = getInviteHeaders(token);
    try {
      const response = await RequestService.post(
        'self-serve-user/register',
        {
          fullName,
          email,
          password,
          companyName,
        },
        headers,
      );
      if (response.existingAccount) {
        message.info('Welcome back! Please sign in to continue.');
        history.push('/');
      } else {
        dispatch(
          action(ActionType.AUTHENTICATE_USER, {
            token: response.token,
          }),
        );
        dispatch(
          logActivity(
            ActivityTypes.SIGN_UP.KEY,
            ActivityTypes.SIGN_UP.ACTIONS.SIGN_UP_SUCCESS,
            { source, urlParams },
            null,
            headers,
          ),
        );
        history.push(getRedirectUrl('/', ssParametersToFilter));
      }
    } catch (e) {
      dispatch(
        logActivity(
          ActivityTypes.SIGN_UP.KEY,
          ActivityTypes.SIGN_UP.ACTIONS.SIGN_UP_ERROR,
          { source, urlParams },
          null,
          headers,
        ),
      );
      message.error(e.message, 3);
    }
    dispatch(action(ActionType.AUTH_ACTION_COMPLETED));
  };
};

export const registerUsing0Auth = (
  { code, authType, token, isAutomated, source = '' },
  urlParams,
) => {
  return async dispatch => {
    const logPayload = {
      authType,
      source,
      code,
      isAutomated,
      urlParams,
    };

    dispatch(action(ActionType.AUTH_ACTION_REQUEST));
    const headers = getInviteHeaders(token);
    try {
      const response = await RequestService.post(
        'self-serve-user/register-oauth',
        {
          code,
          authType,
        },
        headers,
      );

      dispatch(
        action(ActionType.AUTHENTICATE_USER, {
          token: response.token,
          authType: response.authType,
        }),
      );
      dispatch(
        logActivity(
          ActivityTypes.GOOGLE_OAUTH.KEY,
          ActivityTypes.GOOGLE_OAUTH.ACTIONS.SUCCESS_SIGNUP,
          logPayload,
          null,
          headers,
        ),
      );
      history.push(getRedirectUrl('/', ssParametersToFilter));
    } catch (e) {
      logPayload.error = e.message;
      dispatch(
        logActivity(
          ActivityTypes.GOOGLE_OAUTH.KEY,
          ActivityTypes.GOOGLE_OAUTH.ACTIONS.FAILURE_SIGNUP,
          logPayload,
          null,
          headers,
        ),
      );
      ToastService.error(e?.message || 'Server error or server not accessible');
    }
    dispatch(action(ActionType.AUTH_ACTION_COMPLETED));
  };
};

export const getCurrentUser = () => {
  return async dispatch => {
    dispatch(action(ActionType.AUTH_ACTION_REQUEST));
    try {
      const {
        user,
        jobLimit,
        jobs,
        interviewRequests,
        interviewRequestLimit,
        profileViewCount,
        singleUserForAllConnectedJobs,
        opportunityOwner,
        jobsHavingPackets,
      } = await RequestService.get('self-serve-user/me');

      dispatch(setAllJobs(jobs));

      logEvent(GtmEventTypes.USER_PROFILE_INIT, {
        [GtmVariables.EMAIL]: user.email,
      });

      dispatch(setCurrentUser({ user, jobs, singleUserForAllConnectedJobs }));
      dispatch(
        action(MainActions.GET_INTERVIEW_REQUEST_LIMIT, {
          interviewRequestLimit,
        }),
      );
      dispatch(
        action(MainActions.GET_JOB_LIMIT, {
          jobLimit,
        }),
      );
      dispatch(
        action(MainActions.GET_INTERVIEW_REQUESTS, { interviewRequests }),
      );
      dispatch(
        action(MainActions.GET_PROFILE_VIEW_COUNT, { profileViewCount }),
      );
      dispatch(action(MainActions.GET_OPPORTUNITY_OWNER, { opportunityOwner }));
      dispatch(
        action(MainActions.GET_JOBS_HAVING_PACKETS, { jobsHavingPackets }),
      );

      const profileViewCap = user?.limitedAccount
        ? LSA_DDP_PROFILE_VIEW_CAP
        : user?.profileViewCap;

      if (!user.internalUser && profileViewCount >= profileViewCap) {
        dispatch(action(MainActions.SET_DDP_VIEW_LIMIT_REACHED));
      }

      setGBUserFlags(user.flagsState);
      HeapService.identify(user.id);
      turingLogger
        .identify({ userId: user.id, expiry: '1y' })
        .catch(e => console.error('[turing-client.identify]', e));
    } catch (e) {
      if (e.statusCode === 401) {
        AuthorizationService.setToken({
          token: '',
          authType: '',
        });
        history.push('sign-in');
        ToastService.error(
          e?.message || 'Server error or server not accessible',
        );
      }
    }
    dispatch(action(ActionType.AUTH_ACTION_COMPLETED));
  };
};

export const setCurrentUser = ({
  user,
  jobs,
  singleUserForAllConnectedJobs,
}) => {
  return dispatch => {
    dispatch(
      action(ActionType.CURRENT_USER, {
        user,
        jobs,
        singleUserForAllConnectedJobs,
      }),
    );
  };
};

export const signOut = () => {
  return dispatch => {
    history.push('sign-in');
    AuthorizationService.setToken({
      token: '',
      authType: '',
    });
    turingLogger
      .removeIdentity()
      .catch(e => console.error('[turing-client.removeIdentity]', e));
    dispatch(action(ActionType.USER_LOGOUT));
  };
};

export const signInUser = ({ email, password }, urlParams) => {
  return async dispatch => {
    dispatch(action(ActionType.AUTH_ACTION_REQUEST));
    try {
      const response = await RequestService.post('self-serve-user/login', {
        email,
        password,
      });
      dispatch(
        action(ActionType.AUTHENTICATE_USER, {
          token: response.token,
        }),
      );
      dispatch(
        logActivity(
          ActivityTypes.SIGN_IN.KEY,
          ActivityTypes.SIGN_IN.ACTIONS.SIGN_IN_SUCCESS,
          { email, urlParams },
        ),
      );

      const redirectUriAfterLogin = sessionStorage.getItem(
        'redirectUriAfterLogin',
      );

      if (redirectUriAfterLogin) {
        history.push(redirectUriAfterLogin);
        sessionStorage.removeItem('redirectUriAfterLogin');
      } else {
        history.push(getRedirectUrl('/', ssParametersToFilter));
      }
    } catch (e) {
      ToastService.error(e?.message || 'Server error or server not accessible');
      dispatch(
        logActivity(
          ActivityTypes.SIGN_IN.KEY,
          ActivityTypes.SIGN_IN.ACTIONS.SIGN_IN_FAILED,
          { email, urlParams },
        ),
      );
    }
    dispatch(action(ActionType.AUTH_ACTION_COMPLETED));
  };
};

export const signInUserOAuth = ({ code, authType }, urlParams) => {
  return async dispatch => {
    dispatch(action(ActionType.AUTH_ACTION_REQUEST));
    const logPayload = { authType, urlParams };
    try {
      const response = await RequestService.post(
        'self-serve-user/login-oauth',
        {
          code,
          authType,
        },
      );
      dispatch(
        action(ActionType.AUTHENTICATE_USER, {
          token: response.token,
          authType: response.authType,
        }),
      );
      dispatch(
        logActivity(
          ActivityTypes.GOOGLE_OAUTH.KEY,
          ActivityTypes.GOOGLE_OAUTH.ACTIONS.SUCCESS_SIGNIN,
          logPayload,
        ),
      );

      const redirectUriAfterLogin = sessionStorage.getItem(
        'redirectUriAfterLogin',
      );

      if (redirectUriAfterLogin) {
        history.push(redirectUriAfterLogin);
        sessionStorage.removeItem('redirectUriAfterLogin');
      } else {
        history.push(getRedirectUrl('/', ssParametersToFilter));
      }
    } catch (e) {
      ToastService.error(e?.message || 'Server error or server not accessible');
      dispatch(
        logActivity(
          ActivityTypes.GOOGLE_OAUTH.KEY,
          ActivityTypes.GOOGLE_OAUTH.ACTIONS.FAILURE_SIGNIN,
          logPayload,
        ),
      );
    }
    dispatch(action(ActionType.AUTH_ACTION_COMPLETED));
  };
};

export const requestPasswordReset = ({ email }) => {
  return async dispatch => {
    dispatch(action(ActionType.AUTH_ACTION_REQUEST));
    try {
      await RequestService.post('self-serve-user/forgot-password', {
        email,
      });
      message.success('Check your email for password reset');
    } catch (e) {
      message.error(e?.message || 'Server error or server not accessible', 0);
    }
    dispatch(action(ActionType.AUTH_ACTION_COMPLETED));
  };
};

export const saveUser = userPayload => {
  return async dispatch => {
    dispatch(action(ActionType.SAVE_USER_ACTION_REQUEST));

    try {
      await RequestService.patch('self-serve-user', userPayload);
      dispatch(getCurrentUser());
      message.success('Saved successfully');
    } catch (e) {
      message.error(e?.message || 'Server error or server not accessible', 0);
    }
    dispatch(action(ActionType.SAVE_USER_ACTION_COMPLETED));
  };
};

export const changePassword = ({ token, password, passwordConfirm }) => {
  return async dispatch => {
    dispatch(action(ActionType.AUTH_ACTION_REQUEST));
    try {
      const headers = getInviteHeaders(token);
      await RequestService.post(
        'self-serve-user/password-reset',
        {
          password,
          passwordConfirm,
        },
        headers,
      );
      message.success('Your password has been changed');
      history.push('sign-in');
    } catch (e) {
      message.error('Error occurred, please try again', 0);
      console.log(e);
    }
    dispatch(action(ActionType.AUTH_ACTION_COMPLETED));
  };
};

export const activateAccount = activationToken => {
  return async dispatch => {
    dispatch(action(ActionType.AUTH_ACTION_REQUEST));
    try {
      const headers = getInviteHeaders(activationToken);
      await RequestService.post(
        'self-serve-user/activate-account',
        {},
        headers,
      );
      message.success('Your account has been re-activated');
    } catch (e) {
      message.error("We couldn't reactivate your account, please try again", 0);
      console.log(e);
    }
    dispatch(action(ActionType.AUTH_ACTION_COMPLETED));
  };
};
