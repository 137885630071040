import produce from 'immer';
import {
  CompanyDetails,
  CompanyInfo,
  PostmatchInitialState,
} from '../../components/postmatch/postmatch.types';
import { ActionType } from './postmatch.types';
import {
  parseEngagementsSingleId,
  ActivePicker,
  getInitialDate,
} from '@turingenterprises/postmatch-components';

const initialState: PostmatchInitialState = {
  companyDevelopers: [],
  companyDetails: <CompanyDetails>{},
  isFetchingEngagementsPageLoad: false,
  isFetchingEngagementsDateUpdate: false,
  companyInfo: <CompanyInfo>{},
  dateRange: getInitialDate(),
  isFetchingExportCSV: false,
  activePicker: ActivePicker.Month,
};

export const postmatchReducer = (state = initialState, action) => {
  return produce(state, draftState => {
    if (action.type === ActionType.REQUEST_COMPANY_ENGAGEMENTS_STARTED) {
      const { isDateUpdate } = action.payload;
      draftState.companyDevelopers = [];
      if (isDateUpdate) {
        draftState.isFetchingEngagementsDateUpdate = true;
      } else {
        draftState.isFetchingEngagementsPageLoad = true;
      }
    }

    if (action.type === ActionType.REQUEST_COMPANY_ENGAGEMENTS_COMPLETED) {
      const { companyEngagements, isDateUpdate } = action.payload;
      const { developers, companyDetails, companyInfo } =
        parseEngagementsSingleId(companyEngagements);
      draftState.companyDevelopers = developers;
      draftState.companyDetails = companyDetails;
      draftState.companyInfo = companyInfo;
      if (isDateUpdate) {
        draftState.isFetchingEngagementsDateUpdate = false;
      } else {
        draftState.isFetchingEngagementsPageLoad = false;
      }
    }

    if (action.type === ActionType.REQUEST_COMPANY_ENGAGEMENTS_FAILED) {
      const { isDateUpdate } = action.payload;
      if (isDateUpdate) {
        draftState.isFetchingEngagementsDateUpdate = false;
      } else {
        draftState.isFetchingEngagementsPageLoad = false;
      }
    }

    if (action.type === ActionType.UPDATE_DATE_RANGE) {
      const { startDate, endDate } = action.payload;
      const dateRange = { start: startDate, end: endDate };
      draftState.dateRange = dateRange;
    }

    if (action.type === ActionType.REQUEST_EXPORT_CSV_STARTED) {
      draftState.isFetchingExportCSV = true;
    }

    if (
      action.type === ActionType.REQUEST_EXPORT_CSV_COMPLETED ||
      action.type === ActionType.REQUEST_EXPORT_CSV_FAILED
    ) {
      draftState.isFetchingExportCSV = false;
    }

    if (action.type === ActionType.UPDATE_ACTIVE_PICKER) {
      const { activePicker } = action.payload;
      draftState.activePicker = activePicker;
    }
  });
};
