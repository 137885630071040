import { loadable } from 'react-lazily/loadable';

const { SignUp } = loadable(() => import('../components/sign-up'));
const { SignIn } = loadable(() => import('../components/sign-in'));
const { ForgotPassword } = loadable(
  () => import('../components/forgot-password'),
);
const { ResetPassword } = loadable(
  () => import('../components/reset-password'),
);
const { JobInterviewsSubpageRedirect } = loadable(
  () =>
    import(
      '../components/job-interviews-subpage-redirect/job-interviews-subpage-redirect'
    ),
);
const { EditJobSubpage } = loadable(
  () => import('../components/job-edit-subpage'),
);
const { JobsRouter } = loadable(() => import('../components/jobs-router'));
const { JobsMainPage } = loadable(() => import('../components/jobs-main-page'));
const { Dashboard } = loadable(() => import('../components/dashboard'));
const { AccountSettings } = loadable(
  () => import('../components/account-settings/account-settings.container'),
);
const { Postmatch } = loadable(() => import('../components/postmatch'));
const { NewNavigationMainRedirect } = loadable(
  () => import('../components/main-redirect'),
);
const { DeepDeveloperProfilePage } = loadable(
  () => import('../components/developer-profile-page'),
);
const { NotFound } = loadable(() => import('../components/not-found'));
const GoogleRedirect = loadable(
  () => import('../components/google-calendar-redirect'),
);
const { Timesheets } = loadable(() => import('../components/timesheets'));
const { TimeCardPage } = loadable(
  () => import('../components/time-card/time-card'),
);

export {
  GoogleRedirect,
  NotFound,
  DeepDeveloperProfilePage,
  NewNavigationMainRedirect,
  Postmatch,
  AccountSettings,
  Dashboard,
  JobsMainPage,
  JobsRouter,
  EditJobSubpage,
  ResetPassword,
  JobInterviewsSubpageRedirect,
  ForgotPassword,
  SignIn,
  SignUp,
  Timesheets,
  TimeCardPage,
};
