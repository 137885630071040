import { action } from 'typesafe-actions';
import { logActivity } from '../user-activity-trail/user-activity-trail.actions';
import { ActivityTypes } from '../user-activity-trail/user-activity-trail.types';
import { ActionType } from './interview-requested-developers.types';
import { RequestService, ToastService } from '../../services';
import { handleFetchError } from '../main/main.actions';
import { ActionType as MainActions } from '../main/main.types';

export const getInterviewRequestedDevelopers = (withLogging = true) => {
  return async dispatch => {
    dispatch(action(ActionType.GET_INTERVIEW_REQUESTED_DEVELOPERS_STARTED));

    try {
      const interviewRequestedDevelopers = await RequestService.get(
        'self-serve-interview-requested-developers',
      );
      // @Todo: Discuss with BE to update the API of Interview Request
      const { interviewRequests } = await RequestService.get(
        'self-serve-user/me',
      );

      if (withLogging) {
        dispatch(
          logActivity(
            ActivityTypes.INTERVIEW_REQUESTED_DEVELOPERS.KEY,
            ActivityTypes.INTERVIEW_REQUESTED_DEVELOPERS.ACTIONS
              .GET_INTERVIEW_REQUESTED_DEVELOPERS,
            {
              developers: interviewRequestedDevelopers.map(
                developer => developer.userId,
              ),
            },
          ),
        );
      }

      dispatch(
        action(MainActions.GET_INTERVIEW_REQUESTS, { interviewRequests }),
      );

      dispatch(
        action(ActionType.GET_INTERVIEW_REQUESTED_DEVELOPERS, {
          interviewRequestedDevelopers,
        }),
      );
    } catch (e) {
      dispatch(handleFetchError(e));
      if (withLogging) {
        ToastService.error(
          'Error occurred while fetching requested meeting developers data',
          e,
        );
      }
    }

    dispatch(action(ActionType.GET_INTERVIEW_REQUESTED_DEVELOPERS_FINISHED));
  };
};
