import produce from 'immer';
import { keyBy } from 'lodash';
import { ActionType } from './saved-developers.types';

const UNASSIGNED = 'unassigned';

const initialState = {
  savedDevelopersDetailed: [],
  isFetching: false,
  savedDevelopers: [],
  isSaving: false,
  timezones: [],
  savedDevelopersMap: {},
};

export const savedDevelopersReducer = (state = initialState, action) => {
  return produce(state, draftState => {
    if (action.type === ActionType.SAVE_DEVELOPER) {
      let { savedDeveloper, jobId, developerId } = action.payload;
      jobId = jobId || UNASSIGNED;
      if (!draftState.savedDevelopersMap[jobId]) {
        draftState.savedDevelopersMap[jobId] = {};
      }
      draftState.savedDevelopersMap[jobId][developerId] = savedDeveloper;
    }
    if (action.type === ActionType.UPDATE_DEVELOPER_NOTE_STARTED) {
      draftState.isSaving = true;
    }
    if (action.type === ActionType.UPDATE_DEVELOPER_NOTE) {
      const { developerId, note, savedAgainstJob } = action.payload;
      const jobId = savedAgainstJob || UNASSIGNED;
      draftState.savedDevelopersMap[jobId][developerId]['savedDetails'][
        'note'
      ] = note;
    }
    if (action.type === ActionType.UPDATE_DEVELOPER_NOTE_FINISHED) {
      draftState.isSaving = false;
    }
    if (action.type === ActionType.UNSAVE_DEVELOPER) {
      const { savedAgainstJob, developerId } = action.payload;
      const jobId = savedAgainstJob || UNASSIGNED;
      if (draftState.savedDevelopersMap[jobId][developerId]) {
        delete draftState.savedDevelopersMap[jobId][developerId];
      }
    }
    if (action.type === ActionType.GET_SAVED_DEVELOPERS_DETAILED_STARTED) {
      draftState.isFetching = true;
    }
    if (action.type === ActionType.GET_SAVED_DEVELOPERS_DETAILED) {
      const { savedDevelopers } = action.payload;
      draftState.savedDevelopersMap = savedDevelopers.reduce(
        (acc, jobDevs) => ({
          ...acc,
          [jobDevs.jobId || UNASSIGNED]: keyBy(jobDevs.developers, 'userId'),
        }),
        {},
      );
    }
    if (action.type === ActionType.GET_SAVED_DEVELOPERS_DETAILED_FINISHED) {
      draftState.isFetching = false;
    }
    if (action.type === ActionType.SET_TIMEZONES) {
      draftState.timezones = action.payload;
    }
  });
};
