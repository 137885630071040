import { AuthorizationService } from '../services/AuthService';
import { Redirect, Route, useLocation } from 'react-router-dom';
import React, { useEffect, Suspense } from 'react';
import { SelfServeLayout } from '../components/self-serve-layout/self-serve-layout';
import { TuringBootstrap } from '../components/turing-bootstrap';
import { checkQueryForDDPAction } from '../shared/utilties';
import { Spin } from 'antd';

export const PrivateRoute = ({
  component: Component,
  isComponentLazy = true,
  ...rest
}) => {
  const { search } = useLocation();
  useEffect(() => {
    if (search) {
      // @Todo: It sets the remember Dev Hash details in Session Storage which can be utilised later by main page
      checkQueryForDDPAction(search);
    }
  }, [search]);

  return (
    <Route
      {...rest}
      render={props =>
        AuthorizationService.isLoggedIn() ? (
          <TuringBootstrap {...props}>
            <SelfServeLayout>
              {isComponentLazy ? (
                <Suspense
                  fallback={
                    <div>
                      <Spin size="large" />
                    </div>
                  }
                >
                  <Component {...props} {...rest} />
                </Suspense>
              ) : (
                <Component {...props} {...rest} />
              )}
            </SelfServeLayout>
          </TuringBootstrap>
        ) : (
          <Redirect
            to={{
              pathname: '/sign-in',
            }}
          />
        )
      }
    />
  );
};
