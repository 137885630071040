import React, { useMemo } from 'react';
import { CheckCircleFilled } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { jobCreationStatus } from '../../../../app/job-creation/job-creation.constants';
import {
  isValidExperienceAndBudgetConfig,
  isValidJobTitle,
  isValidLocationAndStartDateConfig,
  isValidRolesAndSkillsConfig,
  isValidResponsibilityConfig,
  isValidJobDescription,
  isValidOptionalSkills,
  isValidOtherSkills,
} from '../../../../app/job-creation/job-creation.util';
import { DeveloperResponsibility } from '../developer-responsibility';
import { JobTitle } from '../job-title';
import { OptionalSkills } from '../optional-skills';
import { CustomerBudget } from '../customer-budget';
import { ExperienceSelector } from '../experience-selector/experience-selector';
import { LocationAndHoursOverlap } from '../location-hours-overlap';
import { JobDescription } from '../job-description';
import { JobPositions } from '../job-positions';
import { RequiredSkills } from '../required-skills';
import { JobRole } from '../job-role';
import CloseIcon from '../../../../assets/img/close-icon.svg';
import './style.scss';

export const CreateJobModalV2 = ({
  currentPage,
  lastPage,
  jobStatus,
  handleNavigateNext,
  handleNavigateBack,
  handleJobCreationClick,
  handleSaveChangesClick,
  jobConfig,
  handleSkipClick,
  showValidationError,
  currentUser,
  jobs,
  enableJobCreation,
  handleMinExperienceChange,
  requiredSkillsList,
}) => {
  const { limitedAccount = false } = currentUser || {};

  const getCurrentPageComponents = () => {
    switch (currentPage) {
      case 1:
        return (
          <>
            <JobRole />
            <RequiredSkills skillLabel="What are the required skills?" />
            <OptionalSkills skillLabel="What are the optional skills?" />
          </>
        );
      case 2:
        return (
          <>
            <DeveloperResponsibility />
            <ExperienceSelector
              experienceLabel={
                'What is the minimum experience this developer should have?'
              }
              experienceValue={jobConfig.minExperience}
              handleExperienceChange={handleMinExperienceChange}
            />
            <CustomerBudget />
            <LocationAndHoursOverlap />
          </>
        );
      case 3:
        return (
          <>
            <JobTitle label="Job title" />
            <JobDescription />
            <JobPositions />
          </>
        );
      case -1:
        return null;
      default:
        return '';
    }
  };

  const isCurrentStepValid = () => {
    switch (currentPage) {
      case 1:
        return (
          isValidRolesAndSkillsConfig(jobConfig) &&
          isValidOptionalSkills(jobConfig.additionalPreference.skills) &&
          isValidOtherSkills(requiredSkillsList, jobConfig.requiredSkills) &&
          isValidOtherSkills(
            requiredSkillsList,
            jobConfig.additionalPreference.skills,
          )
        );
      case 2:
        return (
          isValidResponsibilityConfig(jobConfig) &&
          isValidExperienceAndBudgetConfig(jobConfig) &&
          isValidLocationAndStartDateConfig(jobConfig)
        );
      case 3:
        return (
          isValidJobTitle(jobConfig) &&
          isValidJobDescription(jobConfig?.additionalPreference.preference)
        );
      case -1:
        return true;
      default:
        return true;
    }
  };

  const handleNext = jobId => {
    if (!isCurrentStepValid()) {
      showValidationError();
      return;
    }
    if (currentPage === -1) {
      handleSaveChangesClick(jobId);
    } else if (currentPage === lastPage) {
      handleJobCreationClick();
    } else {
      handleNavigateNext(currentPage, jobConfig);
    }
  };

  const getLeftSideFooter = () => {
    if (currentPage === -1) {
      return null;
    }
    if (currentPage === 1) {
      return <span>{''}</span>;
    }
    return (
      <Button type="default" onClick={handleNavigateBack}>
        Back
      </Button>
    );
  };

  const getRightSideFooter = () => {
    if (currentPage === -1) {
      return null;
    }
    if (currentPage === lastPage) {
      return (
        <Button
          data-cy="create-job-button"
          type="primary"
          onClick={() => handleNext(jobConfig.id)}
          disabled={jobStatus === jobCreationStatus.IN_PROGRESS}
          className="create-job-or-next-btn"
        >
          {jobStatus === jobCreationStatus.IN_PROGRESS
            ? 'Creating ...'
            : 'Create job'}
        </Button>
      );
    }
    return (
      <Button
        data-cy="create-job-next-button"
        type="primary"
        onClick={() => handleNext(jobConfig.id)}
        className="create-job-or-next-btn"
      >
        Next
      </Button>
    );
  };

  const { maskStyle, wrapClassName } = useMemo(() => {
    let maskStyle = null;
    let wrapClassName = null;
    if (limitedAccount && !jobs?.length) {
      maskStyle = { display: 'none' };
      wrapClassName = 'job-creation-modal-wrap';
    }

    return { maskStyle, wrapClassName };
  }, [jobs, limitedAccount]);

  return (
    <Modal
      className="job-creation-modal-v2"
      width={600}
      closable={true}
      closeIcon={<img src={CloseIcon} alt="Close Icon" />}
      maskClosable={false}
      mask={true}
      maskStyle={maskStyle}
      wrapClassName={wrapClassName}
      title={
        <>
          {currentPage === -1 ? (
            <h3 className="title" data-cy="create-job-success-message">
              {' '}
              <CheckCircleFilled /> Job posted successfully!
            </h3>
          ) : (
            <h3 className="title">Create job</h3>
          )}
        </>
      }
      open={enableJobCreation}
      onOk={null}
      onCancel={handleSkipClick}
      footer={
        currentPage == -1 ? null : (
          <div
            className={
              currentPage === -1 ? 'footer last-page-footer' : 'footer'
            }
          >
            {getLeftSideFooter()}
            {jobStatus === jobCreationStatus.INITIAL && (
              <div className="pagination">
                {' '}
                {currentPage} of {lastPage}{' '}
              </div>
            )}
            {getRightSideFooter()}
          </div>
        )
      }
    >
      {getCurrentPageComponents()}
    </Modal>
  );
};
