import { action } from 'typesafe-actions';
import { RequestService, ToastService } from '../../services';
import { ActionType } from './continents.types';

export const getContinents = () => {
  return async dispatch => {
    try {
      const continents = await RequestService.get('continents-public');
      dispatch(action(ActionType.GET_CONTINENTS, continents));
    } catch (e) {
      ToastService.error('Error Occurred Fetching Continents', e);
    }
  };
};
