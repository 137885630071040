import { action } from 'typesafe-actions';
import { ActionType } from './job-selection.types';
import { logActivity } from '../user-activity-trail/user-activity-trail.actions';
import { ActivityTypes } from '../user-activity-trail/user-activity-trail.types';
import { RequestService, ToastService } from '../../services';
import { handleFetchError } from '../main/main.actions';
import isFunction from 'lodash/isFunction';

export const switchCurrentJob = jobId => {
  return async dispatch => {
    dispatch(action(ActionType.SET_SELECTED_JOB, { jobId }));
  };
};

export const setNewJob = job => {
  return async dispatch => {
    dispatch(action(ActionType.SET_NEW_JOB, { job }));
  };
};

export const setAllJobs = jobs => {
  return async dispatch => {
    dispatch(
      action(ActionType.SET_ALL_JOBS, {
        jobs,
      }),
    );
  };
};

export const getJobList = onDoneCallback => {
  return async dispatch => {
    dispatch(action(ActionType.SET_JOB_LIST_STARTED));
    try {
      const selfServeJobs = await RequestService.get('self-serve-jobs');
      dispatch(action(ActionType.SET_JOB_LIST, { selfServeJobs }));
      const analyticsPayload = {
        selfServeJobs: selfServeJobs.map(job => job.id),
      };
      dispatch(
        logActivity(
          ActivityTypes.SELF_SERVE_JOBS.KEY,
          ActivityTypes.SELF_SERVE_JOBS.ACTIONS.VIEW_SELF_SERVE_JOBS,
          analyticsPayload,
        ),
      );
    } catch (e) {
      dispatch(handleFetchError(e));
      if (e.statusCode === 429) return;
      ToastService.error(
        'Error occurred while fetching recently self serve jobs',
        e,
      );
    }
    dispatch(action(ActionType.SET_JOB_LIST_COMPLETED));
    if (isFunction(onDoneCallback)) {
      onDoneCallback();
    }
  };
};
