import { CheckCircleFilled } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import React, { useMemo } from 'react';
import { jobCreationStatus } from '../../../../app/job-creation/job-creation.constants';
import {
  isValidAddtionalPreference,
  isValidExperienceAndBudgetConfig,
  isValidJobTitle,
  isValidLocationAndStartDateConfig,
  isValidRolesAndSkillsConfig,
  isValidResponsibilityConfig,
} from '../../../../app/job-creation/job-creation.util';
import { AdditionalPreference } from '../additional-preference';
import { DeveloperResponsibility } from '../developer-responsibility';
import { ExperienceAndBudget } from '../experience-budget';
import { JobTitle } from '../job-title';
import { LocationAndStartDate } from '../location-start-date';
import { RoleAndSkills } from '../role-and-skills';
import './style.scss';

export const JobCreationModal = ({
  currentPage,
  lastPage,
  jobStatus,
  handleNavigateNext,
  handleNavigateBack,
  handleJobCreationClick,
  handleSaveChangesClick,
  jobConfig,
  handleSkipClick,
  showValidationError,
  currentUser,
  jobs,
  enableJobCreation,
}) => {
  const { limitedAccount = false } = currentUser || {};

  const getCUrrentPageComponents = () => {
    switch (currentPage) {
      case 1:
        return <RoleAndSkills skillLabel="What are the required skills?" />;
      case 2:
        return (
          <>
            <DeveloperResponsibility />
            <ExperienceAndBudget />
          </>
        );
      case 3:
        return (
          <>
            <JobTitle label="What is a good title for this job?" />
            <LocationAndStartDate />
          </>
        );
      case -1:
        return <AdditionalPreference />;
      default:
        return '';
    }
  };

  const isCurrentStepValid = () => {
    switch (currentPage) {
      case 1:
        return isValidRolesAndSkillsConfig(jobConfig);
      case 2:
        return (
          isValidResponsibilityConfig(jobConfig) &&
          isValidExperienceAndBudgetConfig(jobConfig)
        );
      case 3:
        return (
          isValidJobTitle(jobConfig) &&
          isValidLocationAndStartDateConfig(jobConfig)
        );
      case -1:
        return isValidAddtionalPreference(jobConfig);
      default:
        return true;
    }
  };

  const handleNext = jobId => {
    if (!isCurrentStepValid()) {
      showValidationError();
      return;
    }
    if (currentPage === -1) {
      handleSaveChangesClick(jobId);
    } else if (currentPage === lastPage) {
      handleJobCreationClick();
    } else {
      handleNavigateNext(currentPage, jobConfig);
    }
  };

  const getLeftSideFooter = () => {
    if (currentPage === -1) {
      return (
        <Button type="default" onClick={handleSkipClick}>
          Skip
        </Button>
      );
    }
    if (currentPage === 1) {
      if (limitedAccount && !jobs?.length) {
        return <span>{''}</span>;
      }

      return (
        <Button type="default" onClick={handleSkipClick}>
          Cancel
        </Button>
      );
    }
    return (
      <Button type="default" onClick={handleNavigateBack}>
        Back
      </Button>
    );
  };

  const getRightSideFooter = () => {
    if (currentPage === -1) {
      return (
        <Button
          data-cy="create-job-save-button"
          type="primary"
          onClick={() => handleNext(jobConfig.id)}
          disabled={jobStatus === jobCreationStatus.IN_PROGRESS}
        >
          {jobStatus === jobCreationStatus.IN_PROGRESS ? 'Saving ...' : 'Save'}
        </Button>
      );
    }
    if (currentPage === lastPage) {
      return (
        <Button
          data-cy="create-job-button"
          type="primary"
          onClick={() => handleNext()}
          disabled={jobStatus === jobCreationStatus.IN_PROGRESS}
          className="create-job-or-next-btn"
        >
          {jobStatus === jobCreationStatus.IN_PROGRESS
            ? 'Creating ...'
            : 'Create job'}
        </Button>
      );
    }
    return (
      <Button
        data-cy="create-job-next-button"
        type="primary"
        onClick={() => handleNext()}
        className="create-job-or-next-btn"
      >
        Next
      </Button>
    );
  };

  if (currentPage === -1 && limitedAccount) {
    return null;
  }

  const { maskStyle, wrapClassName } = useMemo(() => {
    let maskStyle = null;
    let wrapClassName = null;
    if (limitedAccount && !jobs?.length) {
      maskStyle = { display: 'none' };
      wrapClassName = 'job-creation-modal-wrap';
    }

    return { maskStyle, wrapClassName };
  }, [jobs, limitedAccount]);

  return (
    <Modal
      className="job-creation"
      width={600}
      closable={false}
      mask={true}
      maskStyle={maskStyle}
      wrapClassName={wrapClassName}
      autoFocusButton={null}
      title={
        <div className="header">
          {currentPage === -1 ? (
            <>
              <h5 className="title" data-cy="create-job-success-message">
                {' '}
                <CheckCircleFilled /> Job posted successfully!
              </h5>
              <p>Do you have additional details about this job?</p>
            </>
          ) : (
            <h4>
              {limitedAccount
                ? 'Tell us your talent requirements'
                : 'Create job'}
            </h4>
          )}
        </div>
      }
      open={enableJobCreation}
      onOk={null}
      onCancel={null}
      footer={
        <div
          className={currentPage === -1 ? 'footer last-page-footer' : 'footer'}
        >
          {getLeftSideFooter()}
          {jobStatus === jobCreationStatus.INITIAL && (
            <div className="pagination">
              {' '}
              {currentPage} of {lastPage}{' '}
            </div>
          )}
          {getRightSideFooter()}
        </div>
      }
    >
      {getCUrrentPageComponents()}
    </Modal>
  );
};
