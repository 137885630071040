export const roleTypeIdMap = {
  FRONTEND: 1,
  BACKEND: 2,
  FULLSTACK: 14,
  MOBILE: 5,
  DATA_ENGINEERING: 7,
  MACHINE_LEARNING: 8,
  DEVOPS: 12,
  OTHER: 13,
};

export const jobRoleTypes = [
  {
    id: roleTypeIdMap.FRONTEND,
    skillName: 'Frontend',
  },
  {
    id: roleTypeIdMap.BACKEND,
    skillName: 'Backend',
  },
  {
    id: roleTypeIdMap.FULLSTACK,
    skillName: 'Fullstack',
  },
  {
    id: roleTypeIdMap.MOBILE,
    skillName: 'Mobile',
  },
  {
    id: roleTypeIdMap.DATA_ENGINEERING,
    skillName: 'Data Engineering',
  },
  {
    id: roleTypeIdMap.MACHINE_LEARNING,
    skillName: 'Machine Learning',
  },
  {
    id: roleTypeIdMap.DEVOPS,
    skillName: 'DevOps',
  },
  {
    id: roleTypeIdMap.OTHER,
    skillName: 'Other (please specify)',
  },
];

export const otherRequiredSkill = {
  id: -1,
  skillName: 'Other (please specify)',
};

export const jobCreationSkills = [
  'react',
  'redux',
  'angular',
  'vue.js',
  'javascript',
  'typescript',
  'html',
  'html/css',
  'node.js',
  'python',
  'java',
  'php',
  'c#',
  'ruby on rails',
  'sql',
  'swift (for ios)',
  'android',
  'react native',
  'devops',
  'machine learning',
  'data engineering',
];

export const jobCreationStatus = {
  INITIAL: 'initial',
  IN_PROGRESS: 'in progress',
  SUCCESS: 'success',
  FAILURE: 'failure',
};
