import { connect } from 'react-redux';
import {
  syncBudgetValue,
  syncMinExperience,
  syncStrictBudget,
} from '../../../../app/job-creation/job-creation.actions';
import { ExperienceAndBudget } from './experience-budget';

const mapStateToProps = state => {
  return {
    minExperience: state.jobCreation.minExperience,
    isStrictBudget: state.jobCreation.budget.isStrict,
    budgetValue: state.jobCreation.budget.value,
    showError: state.jobCreation.showValidationErrors,
    jobCreationId: state.jobCreation.id,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleMinExperienceChange: minExperience => {
      dispatch(syncMinExperience(minExperience));
    },
    handleStrictBudgetToggle: isStrictBudget => {
      dispatch(syncStrictBudget(isStrictBudget));
    },
    handleBudgetValueChange: value => {
      dispatch(syncBudgetValue(value));
    },
  };
};

const connected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExperienceAndBudget);
export { connected as ExperienceAndBudgetContainer };
