import produce from 'immer';
import {
  APPROVAL_STATUS,
  ActivePicker,
  convertStatusToDisplayableText,
  getInitialDate,
  parseTimesheets,
} from '@turingenterprises/postmatch-components';
import { ActionType } from './timesheets.types';
import { AnyAction } from 'redux';
import { TimesheetsState } from '../../components/timesheets/timesheets.types';

const initialState: TimesheetsState = {
  timesheetsDateRange: getInitialDate(),
  activePicker: ActivePicker.Month,
  timesheets: [],
  isFetchingTimesheets: false,
  isSubmittingTimesheet: false,
  isModalOpen: false,
  isApproval: false,
  activeTimeCardId: null,
  total: 0,
  disableApprovalWorkflow: false,
};

export const timesheetsReducer = (state = initialState, action: AnyAction) => {
  return produce(state, draftState => {
    if (action.type === ActionType.REQUEST_TIMESHEETS_STARTED) {
      draftState.isFetchingTimesheets = true;
    }

    if (action.type === ActionType.REQUEST_TIMESHEETS_COMPLETED) {
      const { timeCardsResponse } = action.payload;
      draftState.timesheets = parseTimesheets(timeCardsResponse.timesheets);
      draftState.total = timeCardsResponse.total;
      draftState.isFetchingTimesheets = false;
      draftState.disableApprovalWorkflow =
        timeCardsResponse.flags?.removeApprovalFlow ?? false;
    }

    if (action.type === ActionType.REQUEST_TIMESHEETS_FAILED) {
      draftState.isFetchingTimesheets = false;
    }

    if (action.type === ActionType.SUBMIT_TIMESHEETS_STARTED) {
      draftState.isSubmittingTimesheet = true;
    }

    if (action.type === ActionType.SUBMIT_TIMESHEETS_COMPLETED) {
      const newTimesheets = draftState.timesheets.map(timesheet => {
        const isTimeCardMatch =
          draftState.activeTimeCardId === timesheet.timeCardId;
        const newApprovalStatus = isTimeCardMatch
          ? draftState.isApproval
            ? APPROVAL_STATUS.APPROVED
            : APPROVAL_STATUS.REJECTED
          : timesheet.approvalStatus;
        const newStatus = isTimeCardMatch
          ? draftState.isApproval
            ? convertStatusToDisplayableText(APPROVAL_STATUS.APPROVED)
            : convertStatusToDisplayableText(APPROVAL_STATUS.REJECTED)
          : timesheet.status;
        return {
          ...timesheet,
          approvalStatus: newApprovalStatus,
          status: newStatus,
        };
      });
      draftState.isSubmittingTimesheet = false;
      draftState.timesheets = newTimesheets;
      draftState.isModalOpen = false;
      draftState.activeTimeCardId = null;
    }

    if (action.type === ActionType.SUBMIT_TIMESHEETS_FAILED) {
      draftState.isSubmittingTimesheet = false;
    }

    if (action.type === ActionType.OPEN_TIMESHEETS_MODAL) {
      const { isApproval, activeTimeCardId } = action.payload;
      draftState.isModalOpen = true;
      draftState.isApproval = isApproval;
      draftState.activeTimeCardId = activeTimeCardId;
    }

    if (action.type === ActionType.CLOSE_TIMESHEETS_MODAL) {
      draftState.isModalOpen = false;
      draftState.isApproval = false;
      draftState.activeTimeCardId = null;
    }

    if (action.type === ActionType.UPDATE_TIMESHEETS_DATE_RANGE) {
      const { startDate, endDate } = action.payload;
      draftState.timesheetsDateRange = { start: startDate, end: endDate };
    }
  });
};
