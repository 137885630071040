import { FilterFieldNames } from './main.types';
import hash from 'object-hash';
import _ from 'lodash';
import qs from 'qs';
import { qsParsingOptions } from '../../shared/qsDecoder.util';

export const getCleanFilters = existingQueryParameters => {
  const additionalParameters = {};
  const filters = {};
  for (const key of Object.keys(existingQueryParameters)) {
    if (FilterFieldNames[key]) {
      filters[key] = existingQueryParameters[key];
      if (key === FilterFieldNames.fixedSkillIds) {
        if (Array.isArray(filters[key])) {
          filters[key] = filters[key].filter(
            skillId => typeof skillId === 'number',
          );
        } else {
          delete filters[key];
        }
      }
      continue;
    }
    additionalParameters[key] = existingQueryParameters[key];
  }
  return { filters, additionalParameters };
};

export const filtersMeetMinimumSearchCriteria = filters => {
  return (
    filters[FilterFieldNames.resumeKeywords]?.length ||
    filters[FilterFieldNames.fixedSkillIds]?.length
  );
};

export const getDefaultSidebarMoreFiltersStatusFromFilters = filters => {
  return (
    (filters[FilterFieldNames.totalYOEFrom] &&
      filters[FilterFieldNames.totalYOEFrom] !== -1) ||
    filters[FilterFieldNames.totalYOETo] ||
    filters[FilterFieldNames.monthlyBudget] ||
    (filters[FilterFieldNames.continentIds] &&
      filters[FilterFieldNames.continentIds].length > 0)
  );
};

export const generateFilterHash = filters => hash(filters);

export const didFiltersChanged = (oldFilters, newFilters) => {
  return !_.isEqual(oldFilters, newFilters);
};

export const getQueryObjFromURL = () =>
  qs.parse(window.location.search?.slice(1), qsParsingOptions);

export const getCustomerName = (customerName, email) => {
  return customerName && customerName !== '' ? customerName : email;
};
