import React from 'react';
import { CaretDownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import './style.scss';

export const JobPositions = ({
  additionalPreference,
  handlePositionsChange,
}) => {
  const handleMenuClick = ev => {
    handlePositionsChange(ev.key);
  };

  const positionsMenu = (
    <Menu onClick={handleMenuClick}>
      {Array(10)
        .fill(0)
        .map((_, i) => i + 1)
        .map(option => (
          <Menu.Item key={option}>
            <span>{option}</span>
          </Menu.Item>
        ))}
    </Menu>
  );

  return (
    <div className="job-positions" data-cy="job-positions">
      <div className="selection-container selection-container-inline">
        <div className="container selection-container-inline">
          <div className="label">No. of positions for this role</div>
          <Dropdown overlay={positionsMenu} placement="bottomLeft" arrow>
            <Button>
              {additionalPreference.numPositions} <CaretDownOutlined />{' '}
            </Button>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};
