import _ from 'lodash';
import produce from 'immer';
import {
  ActionType,
  DefaultFilterSkills,
  LimitedAccountPromptTypes,
  SkillCategories,
  VettedSkills,
} from './main.types';
import { ActionType as JobSelectionType } from '../job-selection/job-selection.types';
import { keyBy, uniqBy } from 'lodash';
import { generateFilterHash } from './main.util';
import { MAX_RESULTS_TO_SHOW_PER_PAGE } from '../../shared/constants';

export const MainInitializationState = {
  PENDING: 0,
  LOADING: 1,
  INITIALIZED_ONCE: 2,
};

const initialState = {
  allSkills: [],
  areAllSkillsFetched: false,
  skillCategories: [],
  publicSeniorities: [],
  developers: [],
  totalMatched: null,
  customerCategory: null,
  interviewRequests: [],
  interviewRequestsDevMap: {},
  interviewRequestLimit: {
    weeklyRemainingCount: 0,
    limitExceeded: true,
  },
  jobLimit: {
    weeklyRemainingCount: 0,
    limitExceeded: true,
  },
  opportunityOwner: '',
  jobsHavingPackets: [],
  isFetching: false,
  disableInfiniteScrollWhileLoadingFirstData: false,
  pageNumber: 1,
  currentDeveloperHash: null,
  filterSkills: {
    ...DefaultFilterSkills,
  },
  filterHash: generateFilterHash(DefaultFilterSkills),
  ddpViewLimitReached: false,
  profileViewCount: 0,
  isSendingInterviewRequest: {},
  rankingUuid: '',
  timezones: [],
  initializationState: MainInitializationState.PENDING,
  searchInitializationState: MainInitializationState.PENDING,
  vettedSkills: [],
  interviewReminderNudgeDetails: {
    developerIdHash: '',
    requestedInterviewCount: 0,
    dontRemindJobs: [],
  },
  customerState: {
    selectedDate: undefined,
    developers: [],
    availabilityAlreadyConfirmed: false,
    minimumSlotCount: 2,
    availability: {
      canEdit: true,
      timezone: '',
      timezoneId: 1,
      startTimes: [],
      interviewerEmails: [],
      duration: 60,
    },
    notFound: false,
    packetId: '',
    fetchedOnce: false,
    requestOngoing: false,
    windowWidth: 1200,
    preferencesModalOpen: false,
    allTimezones: [],
    source: 'self-serve',
    selfServeCalendarModalOpen: false,
    preferencesModalUpdate: false,
    googleCalendarAuthUrl: '',
    customerEmail: '',
  },
  currentPage: 1,
  displayFailedSearchComponent: false,
  showInviteCoworkerModal: false,
  invitingCoworker: false,
  inviteCoworkerModalTriggerSource: '',
  sidebarMoreFiltersExpanded: false,
  busySlotAsPerGC: {},
  googleAuthStatus: false,
  searchDebug: false,
  searchDebugData: {},
  overrideFeatureFlag: {},
  shareDeveloperByEmail: {
    showShareDeveloperModal: false,
    developerToShare: null,
  },
  fewerSkillsFilterNudge: {
    dontShowForJobIds: [],
  },
  limitedAccountPromptType: LimitedAccountPromptTypes.inviteCoworker,
  showLimitedAccountPrompt: false,
};

export const mainReducer = (state = initialState, action) => {
  return produce(state, draftState => {
    if (action.type === ActionType.FETCH_DEVELOPERS_STARTED) {
      const { disableInfiniteScrollWhileLoadingFirstData } = action.payload;
      draftState.isFetching = true;
      draftState.disableInfiniteScrollWhileLoadingFirstData =
        disableInfiniteScrollWhileLoadingFirstData;
    }

    if (action.type === ActionType.SET_LIMITED_ACCOUNT_PROMPT_TYPE) {
      draftState.limitedAccountPromptType = action.payload;
      draftState.showLimitedAccountPrompt = true;
    }

    if (action.type === ActionType.CLOSE_LIMITED_ACCOUNT_PROMPT) {
      draftState.showLimitedAccountPrompt = false;
    }

    if (action.type === ActionType.STOP_SEARCH) {
      draftState.isFetching = false;
    }
    if (action.type === ActionType.FETCH_DEVELOPERS_COMPLETED) {
      draftState.isFetching = false;
      draftState.disableInfiniteScrollWhileLoadingFirstData = false;
    }

    if (action.type === ActionType.INITIATE_CALENDAR_COMPONENT) {
      draftState.customerState = action.payload.customerState;
    }

    if (action.type === ActionType.SELECT_DATE) {
      draftState.customerState.selectedDate = action.payload.m;
    }

    if (action.type === ActionType.CALENDAR_REQUEST_STARTED) {
      draftState.customerState.requestOngoing = true;
      for (let index = 0; index < action.payload.developerIds.length; index++) {
        const developerId = action.payload.developerIds[index];
        draftState.isSendingInterviewRequest[developerId] = true;
      }
    }

    if (action.type === ActionType.CALENDAR_REQUEST_COMPLETED) {
      draftState.customerState.requestOngoing = false;
      for (let index = 0; index < action.payload.developerIds.length; index++) {
        const developerId = action.payload.developerIds[index];
        draftState.isSendingInterviewRequest[developerId] = false;
      }
    }

    if (action.type === ActionType.SET_CUSTOMER_TIMEZONE) {
      const timezoneObject = draftState.timezones.find(
        element => element.name === action.payload.name,
      );
      if (timezoneObject) {
        draftState.customerState.availability.timezone = timezoneObject.name;
        draftState.customerState.availability.timezoneId = timezoneObject.key;
      }
    }

    if (action.type === ActionType.SET_DURATION) {
      draftState.customerState.availability.duration =
        action.payload.selectedDuration;
    }

    if (action.type === ActionType.REMOVE_INTERVIEWER_EMAIL) {
      const interviewerEmails =
        state.customerState.availability.interviewerEmails;
      draftState.customerState.availability.interviewerEmails =
        interviewerEmails.filter(email => email !== action.payload.email);
    }

    if (action.type === ActionType.ADD_INTERVIEWER_EMAIL) {
      const interviewerEmails =
        state.customerState.availability.interviewerEmails;
      if (!interviewerEmails.includes(action.payload.email)) {
        draftState.customerState.availability.interviewerEmails = [
          ...interviewerEmails,
          action.payload.email,
        ];
      }
    }

    if (action.type === ActionType.ADD_START_TIME) {
      const startTimes = state.customerState.availability.startTimes;
      draftState.customerState.availability.startTimes = [
        ...startTimes,
        { isAvailable: true, startTime: action.payload.m },
      ];
    }

    if (action.type === ActionType.CANCEL_PREFERENCES) {
      draftState.customerState.preferencesModalOpen = false;
    }

    if (action.type === ActionType.CANCEL_CALENDAR) {
      draftState.customerState.exitInterviewNudgeOpen = true;
    }

    if (action.type === ActionType.EXIT_INTERVIEW_REQUEST) {
      draftState.customerState.preferencesModalOpen = false;
      draftState.customerState.selfServeCalendarModalOpen = false;
      draftState.customerState.exitInterviewNudgeOpen = false;
    }

    if (action.type === ActionType.CONTINUE_INTERVIEW_REQUEST) {
      draftState.customerState.preferencesModalOpen = false;
      draftState.customerState.selfServeCalendarModalOpen = true;
      draftState.customerState.exitInterviewNudgeOpen = false;
    }

    if (action.type === ActionType.UPDATE_CALENDAR_PREFERENCES) {
      draftState.customerState.preferencesModalOpen = true;
      draftState.customerState.selfServeCalendarModalOpen = false;
    }

    if (action.type === ActionType.CONFIRM_PREFERENCES) {
      draftState.customerState.preferencesModalOpen = false;
      draftState.customerState.selfServeCalendarModalOpen = true;
    }

    if (action.type === ActionType.TIME_SLOT_DESELECT) {
      const startTimes = state.customerState.availability.startTimes;
      const index = action.payload.index;
      draftState.customerState.availability.startTimes = [
        ...startTimes.slice(0, index),
        ...startTimes.slice(index + 1),
      ];
    }

    if (action.type === ActionType.SET_ARE_ALL_SKILLS_FETCHED) {
      draftState.areAllSkillsFetched = action.payload;
    }

    if (action.type === ActionType.GET_SKILLS) {
      draftState.allSkills = action.payload;
      draftState.skillCategories = SkillCategories.map(category => ({
        ...category,
        skills: draftState.allSkills
          .filter(skill =>
            category.skills.includes(skill.skillName.toLowerCase()),
          )
          .sort(
            (a, b) =>
              category.skills.indexOf(a.skillName.toLowerCase()) -
              category.skills.indexOf(b.skillName.toLowerCase()),
          ),
      }));
      draftState.vettedSkills = draftState.allSkills
        .filter(skill => VettedSkills.includes(skill.skillName.toLowerCase()))
        .sort((a, b) =>
          a.skillName.toLowerCase().localeCompare(b.skillName.toLowerCase()),
        );
    }

    if (action.type === ActionType.GET_SENIORITY) {
      draftState.publicSeniorities = action.payload;
    }

    if (action.type === ActionType.SET_TIMEZONES) {
      draftState.timezones = action.payload;
    }

    if (action.type === ActionType.CLEAR_RANKING_UUID) {
      draftState.rankingUuid = '';
    }

    if (action.type === ActionType.SET_SEARCH_DEBUG) {
      draftState.searchDebug = action.payload;
    }
    if (action.type === ActionType.SET_SEARCH_DEBUG_DATA) {
      draftState.searchDebugData = action.payload;
    }
    if (action.type === ActionType.SET_OVERRIDE_FEATURE_FLAG) {
      draftState.overrideFeatureFlag = action.payload;
    }
    if (action.type === ActionType.GET_DEVELOPERS) {
      const {
        developers,
        totalMatched,
        customerCategory,
        rankingUuid,
        debug,
        pageNumber,
      } = action.payload;
      if (pageNumber === 1) {
        draftState.developers = developers;
        draftState.totalMatched = totalMatched;
      } else {
        const startIdx = MAX_RESULTS_TO_SHOW_PER_PAGE * (pageNumber - 1);
        for (let i = 0; i < developers.length; i++) {
          draftState.developers[startIdx + i] = developers[i];
        }
      }
      draftState.customerCategory = customerCategory;
      draftState.rankingUuid = rankingUuid;
      if (debug) {
        if (_.isEmpty(draftState.searchDebugData)) {
          draftState.searchDebugData = debug;
        } else {
          draftState.searchDebugData.feature_df = {
            ...draftState.searchDebugData.feature_df,
            ...debug.feature_df,
          };
        }
      }
    }

    if (action.type === ActionType.GET_PROFILE_VIEW_COUNT) {
      const { profileViewCount } = action.payload;
      draftState.profileViewCount = profileViewCount;
    }

    if (action.type === ActionType.SET_CURRENT_DEVELOPER_HASH) {
      draftState.currentDeveloperHash = action.payload;
    }

    if (action.type === ActionType.SET_FILTER_SKILLS) {
      draftState.filterSkills = action.payload;
      draftState.isFetching = true;
      draftState.filterHash = generateFilterHash(draftState.filterSkills);
    }

    if (action.type === ActionType.GET_INTERVIEW_REQUESTS) {
      const { interviewRequests } = action.payload;
      draftState.interviewRequests = interviewRequests || [];
      draftState.interviewRequestsDevMap = interviewRequests
        ? keyBy(interviewRequests, 'developerId')
        : {};
    }

    if (action.type === ActionType.GET_INTERVIEW_REQUEST_LIMIT) {
      const { interviewRequestLimit } = action.payload;
      draftState.interviewRequestLimit = interviewRequestLimit;
    }

    if (action.type === ActionType.GET_JOB_LIMIT) {
      const { jobLimit } = action.payload;
      draftState.jobLimit = jobLimit;
    }

    if (action.type === ActionType.GET_OPPORTUNITY_OWNER) {
      const { opportunityOwner } = action.payload;
      draftState.opportunityOwner = opportunityOwner;
    }

    if (action.type === ActionType.GET_JOBS_HAVING_PACKETS) {
      const { jobsHavingPackets } = action.payload;
      draftState.jobsHavingPackets = jobsHavingPackets;
    }

    if (action.type === ActionType.REQUEST_INTERVIEW) {
      const { developerId } = action.payload;
      draftState.interviewRequests.push({ developerId });
    }

    if (action.type === ActionType.SET_PAGE_NUMBER) {
      draftState.pageNumber = action.payload;
    }

    if (action.type === ActionType.REMOVE_HIDDEN_DEVELOPER) {
      const { developerIdHash } = action.payload;
      const startIdx = MAX_RESULTS_TO_SHOW_PER_PAGE * (state.currentPage - 1);
      const endIdx = startIdx + MAX_RESULTS_TO_SHOW_PER_PAGE;
      const filteredDevelopers = state.developers
        .slice(startIdx, endIdx)
        .filter(developer => developerIdHash !== developer.userHash);
      draftState.developers.splice(
        startIdx,
        MAX_RESULTS_TO_SHOW_PER_PAGE,
        ...filteredDevelopers,
      );
    }

    if (action.type === ActionType.SET_DDP_VIEW_LIMIT_REACHED) {
      draftState.developers = [];
      draftState.currentDeveloperHash = null;
      draftState.isFetching = false;
      draftState.ddpViewLimitReached = true;
    }

    if (action.type === ActionType.USER_PROFILE_INITIALIZATION_STARTED) {
      draftState.initializationState = MainInitializationState.LOADING;
    }

    if (action.type === ActionType.USER_PROFILE_INITIALIZATION_COMPLETED) {
      draftState.initializationState = MainInitializationState.INITIALIZED_ONCE;
    }

    if (action.type === ActionType.SEARCH_INITIALIZATION_STARTED) {
      draftState.searchInitializationState = MainInitializationState.LOADING;
    }
    if (action.type === ActionType.SEARCH_INITIALIZATION_COMPLETED) {
      draftState.searchInitializationState =
        MainInitializationState.INITIALIZED_ONCE;
    }

    if (action.type === ActionType.RESET_TOTAL_MATCHED) {
      draftState.totalMatched = null;
      draftState.developers = [];
    }

    if (action.type === JobSelectionType.SET_SELECTED_JOB) {
      draftState.developers = [];
    }

    if (action.type === ActionType.INTERVIEW_REQUEST_SUBMISSION_BANNER) {
      draftState.interviewReminderNudgeDetails = {
        ...state.interviewReminderNudgeDetails,
        ...action.payload,
      };
    }

    if (action.type === ActionType.NAVIGATE_IN_SEARCH_PAGE) {
      draftState.currentPage = action.payload;
    }

    if (
      action.type === ActionType.TOGGLE_SHOW_DISPLAY_FAILED_SEARCH_COMPONENT
    ) {
      draftState.displayFailedSearchComponent = action.payload;
    }

    if (action.type === ActionType.TOGGLE_INVITE_COWORKER_MODAL) {
      draftState.showInviteCoworkerModal = action.payload;
    }

    if (action.type === ActionType.INVITE_COWORKER_STARTED) {
      draftState.invitingCoworker = true;
    }

    if (action.type === ActionType.INVITE_COWORKER_COMPLETED) {
      draftState.invitingCoworker = false;
    }

    if (action.type === ActionType.SET_INVITE_COWORKER_TOGGLE_TRIGGER_SOURCE) {
      draftState.inviteCoworkerModalTriggerSource = action.payload;
    }

    if (action.type === ActionType.SET_SIDEBAR_MORE_FILTERS_STATUS) {
      draftState.sidebarMoreFiltersExpanded = action.payload.newStatus;
    }

    if (action.type === ActionType.CONNECT_GOOGLE_CALENDAR) {
      draftState.customerState.googleCalendarAuthUrl = action.payload;
    }

    if (action.type === ActionType.SET_CUSTOMER_STARTTIMES) {
      draftState.customerState.availability.startTimes =
        action.payload.availability;
    }

    if (action.type === ActionType.GET_CALENDAR_BUSY_SLOTS) {
      draftState.busySlotAsPerGC = action.payload.busySlots;
      draftState.googleAuthStatus = action.payload.completedGoogleAuth;
    }

    if (action.type === ActionType.DISCONNECT_GOOGLE_CALENDAR) {
      if (action.payload) {
        draftState.googleAuthStatus = action.payload.authStatus;
      }
    }

    if (action.type === ActionType.TOGGLE_SHARE_DEVELOPER_BY_EMAIL) {
      draftState.shareDeveloperByEmail = action.payload;
    }

    if (action.type === ActionType.SET_FEWER_SKILLS_FILTER_NUDGE_HIDE_FOR_JOB) {
      if (
        !draftState.fewerSkillsFilterNudge.dontShowForJobIds.includes(
          action.payload.jobId,
        )
      )
        draftState.fewerSkillsFilterNudge.dontShowForJobIds.push(
          action.payload.jobId,
        );
    }
  });
};
