import { createSelector } from 'reselect';
import { ARCHIVED_JOBS_STATUSES } from '../../shared/constants';

export const selectCurrentJobId = state => state.jobSelection.currentJobId;
export const selectAllJobs = state => state.jobSelection.jobs;
export const selectCurrentJob = createSelector(
  selectCurrentJobId,
  selectAllJobs,
  (currentJobId, jobs) => jobs.find(job => job.id === currentJobId),
);
export const selectActiveJobIds = createSelector(selectAllJobs, jobs =>
  jobs
    .filter(job => !ARCHIVED_JOBS_STATUSES.includes(job.status.toLowerCase()))
    .map(job => job.id),
);
export const selectArchivedJobIds = createSelector(selectAllJobs, jobs =>
  jobs
    .filter(job => ARCHIVED_JOBS_STATUSES.includes(job.status.toLowerCase()))
    .map(job => job.id),
);
