import { PlusOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React from 'react';
import './style.scss';

export const PostJobButton = ({
  setJobCreationMode,
  jobLimit,
  buttonType,
  source,
  showIcon = true,
  currentUser,
  flagMap,
}) => {
  const { limitedAccount = false } = currentUser || {};
  const { enableJobFlowV2 } = flagMap || {};

  return (
    <Tooltip
      placement="top"
      title={
        jobLimit?.limitExceeded
          ? `You've reached the maximum limit for job creation this week`
          : null
      }
    >
      <button
        data-cy="post-job-button"
        disabled={jobLimit?.limitExceeded}
        type={buttonType}
        icon={showIcon ? <PlusOutlined /> : null}
        onClick={() =>
          setJobCreationMode(source, limitedAccount, enableJobFlowV2)
        }
        className="post-job-btn"
      >
        {showIcon ? <PlusOutlined className="post-job-btn-icon" /> : null}
        <span className="post-job-btn-text">Create job</span>
      </button>
    </Tooltip>
  );
};
