import { connect } from 'react-redux';
import {
  getAllJobRoleTypes,
  syncOtherRoleType,
  syncRequiredSkills,
  syncRoleType,
} from '../../../../app/job-creation/job-creation.actions';
import { jobRoleTypes } from '../../../../app/job-creation/job-creation.constants';
import { getAllSkills } from '../../../../app/main/main.actions';
import { RoleAndSkills } from './role-and-skills';

const mapStateToProps = state => {
  return {
    skillCategories: state.main.allSkills,
    roleTypesList: jobRoleTypes,
    roleType: state.jobCreation.roleType,
    requiredSkills: state.jobCreation.requiredSkills,
    requiredSkillsList: state.jobCreation.requiredSkillsList,
    showError: state.jobCreation.showValidationErrors,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleRoleTypeChange: roleType => {
      dispatch(syncRoleType(roleType));
    },
    handleOtherRoleTypeChange: value => {
      dispatch(syncOtherRoleType(value));
    },
    handleRequiredSkillsChange: requiredSkills => {
      dispatch(syncRequiredSkills(requiredSkills));
    },
    getRoleTypes: () => {
      dispatch(getAllJobRoleTypes());
    },
    getAllSkills: () => {
      dispatch(getAllSkills());
    },
  };
};

const connected = connect(mapStateToProps, mapDispatchToProps)(RoleAndSkills);
export { connected as RoleAndSkillsContainer };
